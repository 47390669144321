import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import BottomSheetModal from "../../components/BottomSheet";
import DropDownSelection from "../../components/DropDownSelection/DropDownSelection";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import { CoinContext } from "../../configs/context/CoinContextProvider";

function BusinessContainer(props) {
  const {
    businessName,
    setBusinessName,
    selectedIndustry,
    setSelectedIndustry,
    openIndustryBottomSheet,
    setOpenIndustryBottomSheet,
  } = props;
  const { selectedLanguage } = useContext(CoinContext);
  const [industryList, setIndustryList] = useState([]);
  const { t } = useTranslation();
  const closeIndustryModal = () => setOpenIndustryBottomSheet(false);
  const openIndustryModal = () => {
    setOpenIndustryBottomSheet(true);
  };

  const radioOnClick = (item) => {
    setSelectedIndustry(item);
  };
  useEffect(() => {
    if (selectedIndustry) {
      closeIndustryModal();
    }
  }, [selectedIndustry]);

  useEffect(() => {
    getIndustryList();
  }, []);

  const getIndustryList = async () => {
    try {
      await Axios.get(API.GET_INDUSTRY_LIST).then((result) => {
        if (result.status === 200 && result.data) {
          setIndustryList(result.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeBusinessName = (value) => {
    setBusinessName(value);
  };

  return (
    <>
      {openIndustryBottomSheet ? (
        <BottomSheetModal
          openModal={openIndustryBottomSheet}
          onDismiss={closeIndustryModal}
          className="industry-bottom-sheet"
        >
          <div className="dropdown-selection">
            {industryList && industryList?.businessSector.length > 0 ? (
              <div>
                {industryList.businessSector.map((item) => {
                  return (
                    <DropDownSelection
                      data={item}
                      name={selectedLanguage === "en" ? item.business_sector_name_en : item.business_sector_name_bm}
                      radioOnClick={radioOnClick}
                      checked={selectedIndustry.business_sector_id === item.business_sector_id}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </BottomSheetModal>
      ) : null}
      <div className="row justify-content-center" style={{ marginTop: 16 }}>
        <div className="col-12 col-lg-6">
          <div className="mb-3">
            <label htmlFor="businessName" className="form-label business-subtitle">
              {t("BUSINESS_NAME")} <span className="optional">{`(${t("OPTIONAL")})`}</span>
            </label>
            <input
              type="text"
              className="form-control py-2"
              id="businessName"
              placeholder={t("BUSINESS_NAME_PLACEHOLDER")}
              value={businessName}
              onChange={(e) => onChangeBusinessName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="businessIndustry" className="form-label business-subtitle">
              {t("BUSINESS_INDUSTRY")} <span className="optional">{`(${t("OPTIONAL")})`}</span>
            </label>
            <div
              role="button"
              tabIndex={0}
              onClick={openIndustryModal}
              onKeyDown={openIndustryModal}
              className="d-flex align-items-center justify-content-between form-control py-2"
            >
              <input
                type="text"
                className="border-0 bg-white w-100 industry-dropdown"
                id="dropdownMenuButton1"
                placeholder={t("BUSINESS_INDUSTRY_PLACEHOLDER")}
                disabled
                value={
                  selectedLanguage === "en"
                    ? selectedIndustry.business_sector_name_en
                    : selectedIndustry.business_sector_name_bm
                }
              />
              <i className="fa-solid fa-chevron-down" style={{ color: "#909090" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessContainer;
