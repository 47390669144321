import React from "react";
import { useTranslation } from "react-i18next";

function TncContentContainer() {
  const { t } = useTranslation();
  return (
    <div>
      <p className="tnc-title">1. {t("CURRENCY")}</p>
      <div className="content-container">
        <p className="content">{t("CURRENCY_CONTENT")}</p>
      </div>
      <p className="tnc-title">2. {t("MINIMUM_AMOUNT")}</p>
      <div className="content-container">
        <p className="content">{t("MINIMUM_AMOUNT_CONTENT")}</p>
      </div>
      <p className="tnc-title">3. {t("TNC_SERVICE_FEE")}</p>
      <div className="content-container">
        <p className="content">{t("TNC_SERVICE_FEE_CONTENT")}</p>
      </div>
      <p className="tnc-title">4. {t("ORDER_VERIFICATION")}</p>
      <div className="content-container">{t("ORDER_VERIFICATION_CONTENT")()}</div>
      <p className="tnc-title">5. {t("COLLECTION_OF_ORDER")}</p>
      <div className="content-container">
        <p className="content">{t("COLLECTION_OF_ORDER_CONTENT")}</p>
      </div>
      <p className="tnc-title">6. {t("EXEMPTION")}</p>
      <div className="content-container">
        <p className="content">{t("EXEMPTION_CONTENT")}</p>
      </div>
      <p className="tnc-title">7. {t("INSPECTION")}</p>
      <div className="content-container">
        <p className="content">{t("INSPECTION_CONTENT")}</p>
      </div>
      <p className="tnc-title">8. {t("CONFIDENTIALITY")}</p>
      <div className="content-container">
        <p className="content">{t("CONFIDENTIALITY_CONTENT")}</p>
      </div>
      <p className="tnc-title">9. {t("FORCE_MAJUERE")}</p>
      <div className="content-container">
        <p className="content">{t("FORCE_MAJUERE_CONTENT")}</p>
      </div>
      <p className="tnc-title">10. {t("UPDATE_AND_MODIFICATION")}</p>
      <div className="content-container">
        <p className="content">{t("UPDATE_AND_MODIFICATION_CONTENT")}</p>
      </div>
    </div>
  );
}

export default TncContentContainer;
