export const formatCurrency = (currency) => {
  if (!currency) return "0";
  return currency;
};

// Should only contain characters, numbers
const regIDNumber = /^[a-zA-Z0-9]+$/;

export const isValidIDNumber = (idNumber) => regIDNumber.test(idNumber);

const regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => regEmail.test(email);

// Mobile only contain numbers
const regMobileNumber = /^[0-9]+$/;

export const isValidMobileNumber = (moibleNumber) => regMobileNumber.test(moibleNumber);

// At least 1 letter, 1 number, allow only ASCII characters, length from 8
const regPassword = /^(?=.*[A-Za-z])(?=.*\d)[ -~]{8,}$/;

export const isValidPassword = (password) => regPassword.test(password);
