import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Axios from "../../../configs/serviceProvider/Axios";
import { CoinContext } from "../../../configs/context/CoinContextProvider";
import MainLayout from "../../../layouts/MainLayout";
import { logoOTP } from "../../../images";
import API from "../../../configs/API";
// import "./style.css";

function Otp() {
  const { setuserDetails, userToken } = useContext(CoinContext);
  const { t } = useTranslation();
  const [otpValues, setOTPValues] = useState(["", "", "", "", "", ""]);
  const [isOTPSent, setOTPSent] = useState(false);
  const inputRefs = useRef([]);
  const [seconds, setSeconds] = useState(60);
  const [displayText, setDisplayText] = useState(t("RESEND_VERIFICATION_CODE")(60));
  const navigate = useNavigate();

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOTPValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });

      if (index < otpValues.length - 1) {
        inputRefs.current[index + 1].focus();
      } else if (index === otpValues.length - 1) {
        inputRefs.current[index].blur();
      }
    }
  };

  const handleInputPaste = (index, event) => {
    const { value } = event.target;
    const otpArray = Array.from(value.slice(0, 6)); // Extract the first 6 characters
    const newValues = [...otpValues];

    otpArray.forEach((digit, i) => {
      if (/^\d*$/.test(digit)) {
        newValues[index + i] = digit;
      }
    });

    setOTPValues(newValues);
  };

  const handleInputKeyUp = (index, event) => {
    if (event.key === "Backspace" && !otpValues[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const verifyOtp = async () => {
    try {
      const url = `${API.VERIFY_OTP}`;
      const headers = {
        "Content-Type": "application/json",
        client: userToken,
      };
      const body = {
        otp: otpValues.join(""),
      };

      await Axios.post(url, body, { headers }).then((response) => {
        if (response.data) {
          setuserDetails(response.data);
          navigate("/business-details");
        } else {
          alert("Wrong otp input");
        }
      });
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  const resendOtp = async () => {
    try {
      const url = `${API.RESEND_OTP}`;
      const headers = {
        "Content-Type": "application/json",
        client: userToken,
      };

      await Axios.post(url, {}, { headers }).then((response) => {
        if (response) {
          setOTPSent(true);
          setSeconds(60);
          toast.success("New code sent");
        } else {
          alert("Wrong otp input");
        }
      });
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  useEffect(() => {
    if (otpValues.every((value) => value !== "")) {
      verifyOtp();
    }
  }, [otpValues]);

  useEffect(() => {
    let interval = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setDisplayText(t("NO_VERIFICATION_CODE"));
    } else {
      setDisplayText(t("RESEND_VERIFICATION_CODE")(seconds));
    }
  }, [seconds]);

  return (
    <MainLayout hasFooter={false} backButton>
      <div className="main container">
        <div className="iconContainer mt-4 mb-4">
          <img src={logoOTP} className="App-logo" alt="logo" width="25%" height="auto" />
        </div>
        <div className="text-center">
          <h5>{t("CHECK_EMAIL")}</h5>
          <div className="title2 mt-3 text-muted">{t("VERIFICATION_TITLE")}</div>
          <div className="inputs d-flex flex-row justify-content-center mt-2">
            {otpValues.map((value, index) => (
              <input
                className="m-2 text-center form-control rounded"
                key={index}
                inputMode="numeric"
                value={value}
                maxLength={1}
                onChange={(e) => handleInputChange(index, e)}
                onPaste={(e) => handleInputPaste(index, e)}
                onKeyUp={(e) => handleInputKeyUp(index, e)}
                ref={(ref) => (inputRefs.current[index] = ref)}
              />
            ))}
          </div>
          {seconds === 0 ? (
            <div className="text-12 text-muted mt-4">
              {displayText}
              <span className="resendOTP" onClick={resendOtp}>
                Resend to email
              </span>
            </div>
          ) : (
            <div className="text-12 text-muted mt-4">{displayText} </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Otp;
