/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import Checkbox from "../../components/Checkbox/Checkbox";
import "./style.css";

function TransactionSummary() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedLocation, kocekInTransaction, userToken } = useContext(CoinContext);
  const [checked, setChecked] = useState(false);
  // voucher amount is kocekInTransaction.finalAmount added to nearest 5
  // eslint-disable-next-line no-unsafe-optional-chaining
  const incentiveAmount = kocekInTransaction?.voucher_amount - kocekInTransaction?.amount;

  const handleNavigation = () => {
    navigate("confirmation");
  };

  // check if no token back to login
  if (!userToken) {
    navigate("/kocek-in/login");
  }

  return (
    <>
      <MainLayout
        footerText={t("PRODUCT_LISTING_TITLE")}
        title={selectedLocation.details.store_name}
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
        backButton
      >
        <div className="container mb-2 h-100 transaction-summary font 14">
          <div className="color-909090 m-auto text-center py-4">{t("SUMMARY_TITLE")}</div>
          <div className="color-606060">{t("SUMMARY_REVIEW")}</div>
          <div className="row py-3">
            <div className="col-12">
              <div className="border-top-1 pt-3">
                <div className="d-flex justify-content-between">
                  <div className="color-303030 font-14">{t("KOCEK_IN_AMOUNT")}</div>
                  <div className="color-303030">RM{kocekInTransaction?.ori_coin_amount.toFixed(2)}</div>
                </div>
              </div>
              <div className="color-606060 font-12 ">{t("SUBTOTAL")}</div>
              <div className="d-flex justify-content-between pt-3">
                <div className="color-303030">{t("SERVICE_FEE_PERCENT")}</div>
                <div className="color-303030">- RM{kocekInTransaction?.fee_amount.toFixed(2)}</div>
              </div>
            </div>
            {kocekInTransaction?.type === "voucher" ? (
              <div className="border-bottom-1 border-top-1 mt-3">
                <div className="d-flex justify-content-between pt-3">
                  <div className="color-303030 font-weight-bold">{t("TOTAL_KOCEK_IN_AMOUNT")}</div>
                  <div className="color-303030">RM{kocekInTransaction?.ori_coin_amount.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between pt-3">
                  <div className="color-303030 font-weight-bold">{t("VOUCHER_INCENTIVE_AMOUNT")}</div>
                  <div className="color-14A44D">+ RM{incentiveAmount.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between pt-3">
                  <div className="color-303030 font-16">{t("TOTAL_VOUCHER_AMOUNT")}</div>
                  <div className="color-303030 font-16">RM{kocekInTransaction?.voucher_amount.toFixed(2)}</div>
                </div>
                <div className="color-606060 font-12 ">{t("VOUCHER_TO_BE_RECEIVED")}</div>
              </div>
            ) : (
              <div className="border-bottom-1">
                <div className="d-flex justify-content-between pt-3">
                  <div className="color-303030 font-16 ">{t("TOTAL_AMOUNT")}</div>
                  <div className="color-303030 font-16">RM{kocekInTransaction?.amount.toFixed(2)}</div>
                </div>
                <div className="color-606060 font-12 ">{t("AMOUNT_TO_BE_TRANSFERED")}</div>
              </div>
            )}
          </div>

          <div className="row py-3">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <Checkbox
                  containerStyle={{
                    borderColor: "#DDDDDD",
                    borderWidth: 1,
                    borderStyle: "solid",
                    width: 20,
                    height: 20,
                  }}
                  borderRadius={4}
                  checkedStyle={{
                    backgroundColor: "#3FBAFF",
                    borderColor: "#3FBAFF",
                  }}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <div className="color-303030 font-14 padding-l-20">{t("TERMS_AND_CONDITION_KOCEK_IN")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <button
            type="button"
            className={`cstmbtn mb-4 ${checked ? "enabled" : "disabled"}`}
            onClick={() => handleNavigation()}
          >
            {t("CONTINUE")}
          </button>
        </div>
      </MainLayout>
    </>
  );
}

export default TransactionSummary;
