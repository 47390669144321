export const getLocalStorageItem = (key, initialValue) => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch {
    return initialValue;
  }
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export default {
  ORDER_DETAILS_KEY: "order_details",
  SELECTED_LOCATION_KEY: "selected_location",
  SELECTED_PAYMENT_KEY: "selected_payment",
  SELECTED_ONLINE_BANKING_KEY: "selected_online_banking",
  BUSINESS_DETAILS_KEY: "business_details",
  USER_DETAILS_KEY: "user_details",
  LANGUAGE_KEY: "language",
};
