/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import HorizontalSeparator from "../../../components/HorizontalSeparator";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

function CoinContainer(props) {
  const { totalAmount, add, subtract, openModal } = props;
  const { t } = useTranslation();
  const { confirmedOrderDetails, selectedLanguage } = useContext(CoinContext);
  // eslint-disable-next-line no-unused-vars
  const [key, setKey] = useState(0);
  const timeoutRef = useRef({});

  const onMouseHover = (index) => {
    clearTimeout(timeoutRef.current[index]);
    delete timeoutRef.current[index];
  };

  const onMouseLeave = (item, index) => {
    timeoutRef.current[index] = setTimeout(() => hideEditQuantity(item, index), 2000);
  };

  const showEditQuantity = async (item, index) => {
    item.showEditQuantity = !item.showEditQuantity;
    setKey((prevKey) => prevKey + 1);
    timeoutRef.current[index] = setTimeout(() => {
      hideEditQuantity(item, index);
    }, 5000);
  };

  const hideEditQuantity = (item, index) => {
    item.showEditQuantity = false;
    setKey((prevKey) => prevKey + 1);
    clearTimeout(timeoutRef.current[index]);
  };

  return (
    <div className="order-summary mb-2">
      <div className="coin-container ">
        <p className="title">{t("COIN_ORDER_SUMMARY")}</p>
        <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      </div>
      <div className="order-summary-container">
        <div className="d-flex justify-content-between coin-container">
          <p className="title2">
            {selectedLanguage === "en"
              ? t("ITEM").charAt(0).toUpperCase() + t("ITEM").slice(1).replace("s", "")
              : t("ITEM").charAt(0).toUpperCase() + t("ITEM").slice(1)}
          </p>
          <p className="title2">{t("AMOUNT")}</p>
        </div>
        <div>
          {confirmedOrderDetails.map((item, index) => {
            return (
              <div key={index} className={`row order-row-container ${index % 2 === 0 ? "bg-light" : "bg-white"}`}>
                <CSSTransition in={item.showEditQuantity} timeout={300} classNames="fade" unmountOnExit>
                  <div
                    key={index}
                    onMouseEnter={() => onMouseHover(index)}
                    onMouseLeave={() => onMouseLeave(item, index)}
                    className="add-minus-quantity-container border rounded text-center py-1 px-1 d-flex align-items-center"
                  >
                    <i
                      role="button"
                      onClick={() => subtract(item)}
                      onKeyDown={() => subtract(item)}
                      className="add-minus fa-solid fa-minus fa-2xs border-end"
                      style={{ color: "#282828" }}
                    />
                    <i
                      role="button"
                      onClick={() => add(item)}
                      onKeyDown={() => add(item)}
                      className="add-minus fa-solid fa-add fa-2xs"
                      style={{ color: "#282828" }}
                    />
                  </div>
                </CSSTransition>
                <div className="col-9 ps-0">
                  <div className="d-flex text-center">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => showEditQuantity(item, index)}
                      className="quantity-container border rounded text-center py-1 px-1 d-flex align-items-center"
                    >
                      <p className={`m-0 edit-quantity ${index % 2 === 0 ? "bg-light" : "bg-white"}`}>
                        {item.quantity}
                      </p>
                      <i className="fa-solid fa-chevron-down fa-2xs" style={{ color: "#282828" }} />
                    </div>
                    <div className="ms-3 text-start">
                      <div className="d-flex align-items-center">
                        <p className="coin-name">{item.product_name}</p>
                        {item.product_remark ? (
                          <p
                            className={`${
                              item.product_remark === "Old" ? "order-coin-status-old" : "order-coin-status-new"
                            } rounded justify-content-center d-flex align-items-center`}
                          >
                            {item.product_remark === "Old" ? t("OLD_COIN") : t("NEW_COIN")}
                          </p>
                        ) : null}
                      </div>
                      <p className="coin-min">{`RM${item.product_price}.00/${t("PACK")}`}</p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-end pe-0">
                  <p className="coin-amount">{`RM${item.totalAmount.toFixed(2)}`}</p>
                </div>
              </div>
            );
          })}
          <div role="button" tabIndex={0} onClick={openModal}>
            <p className="coin-name coin-container mt-2" style={{ color: "#3FBAFF" }}>
              {t("ADD_MORE_ITEMS")}
            </p>
          </div>

          <div className="coin-container amount-container d-flex justify-content-between">
            <p className="coin-name mb-0">{t("TOTAL_WITHDRAWAL_AMOUNT")}</p>
            <p className="coin-amount mb-0">{`RM ${totalAmount.toFixed(2)}`}</p>
          </div>
          <p className="content coin-container">{t("SUBTOTAL")}</p>
        </div>
      </div>
    </div>
  );
}

export default CoinContainer;
