import React from "react";

function SearchBar({ onChangeText, searchedData, placeholder }) {
  return (
    <div className="form-control d-flex align-items-center search-bar">
      <i className="fa-solid fa-magnifying-glass search-icon fa-xs" />
      <input
        className="border-0 search-input w-100"
        placeholder={placeholder}
        onChange={(e) => onChangeText(e.target.value)}
        value={searchedData}
      />
    </div>
  );
}

export default SearchBar;
