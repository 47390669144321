import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/Checkbox/Checkbox";

const TNCContainer = forwardRef((props, ref) => {
  const { checked, setChecked, emphasisTNC } = props;
  const { t } = useTranslation();
  return (
    <div ref={ref} className={`checkout-container ${emphasisTNC ? "emphasis" : null} mb-2`}>
      <div className="d-flex align-items-start">
        <Checkbox
          containerStyle={{
            borderColor: "#DDDDDD",
            borderWidth: 1,
            borderStyle: "solid",
            width: 20,
            height: 20,
          }}
          borderRadius={4}
          checkedStyle={{
            backgroundColor: "#3FBAFF",
            borderColor: "#3FBAFF",
          }}
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <p className="subtitle mb-0 ms-2">{t("AGREE_DETAILS")}</p>
      </div>
      {/* <div style={{ marginTop: 24 }}>{t("AGREE_TNC")({ openModal })}</div> */}
    </div>
  );
});

export default TNCContainer;
