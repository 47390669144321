import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../../../layouts/MainLayout";
import "./style.css";
import { cimb } from "../../../../images";

function CimbCDM() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const totalAmount = state?.totalAmount || 0;
  const scrollPosition = state?.scrollPosition || 0;
  const navigate = useNavigate();

  const bottomButtonOnClick = () => {
    navigate("/checkout/payment-method", { state: { scrollPosition } });
  };
  return (
    <MainLayout
      title="Payment"
      backButton
      backButtonOnClick={() => navigate("/checkout", { state: { scrollPosition } })}
      backgroundColor="#F5F5F5"
      twoButton
      hasFooter
      topButtonText="VIEW ORDER"
      bottomButtonText="Change payment method"
      bottomButtonOnClick={bottomButtonOnClick}
    >
      <div className="mb-2 h-100 p-0">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="mb-2 payment-instructions-container">
              <img src={cimb} alt="cimb_bank_logo" />
              <p className="cimb-title">{t("CIMB_KTM_TITLE")}</p>
              <p className="cimb-subtitle">{t("CIMB_KTM_SUBTITLE")}</p>
              <div className="instructions">{t("CIMB_INSTRUCTIONS")(totalAmount.toFixed(2))}</div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default CimbCDM;
