import React from "react";

function PaymentStatusContainer({ icon, title, description, buttonText, buttonOnClick }) {
  return (
    <div className="payment-status-container">
      {icon}
      <p className="payment-status-title">{title}</p>
      <p className="payment-status-description">{description}</p>
      <div
        onClick={buttonOnClick}
        className="payment-status-button"
        role="button"
        onKeyDown={buttonOnClick}
        tabIndex={0}
      >
        {buttonText}
      </div>
    </div>
  );
}

export default PaymentStatusContainer;
