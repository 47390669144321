import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources, { DEFAULT_LANGUAGE } from "./locales";

const STORE_LANGUAGE_KEY = "language";

const languageDetectorPlugin = {
  type: "languageDetector",
  async: true,
  init: () => {},
  async detect(callback) {
    try {
      // get stored language from Local storage
      const response = await localStorage.getItem(STORE_LANGUAGE_KEY);
      if (response === null) {
        return callback(DEFAULT_LANGUAGE);
      }
      return callback(response);
    } catch (error) {}
  },
  async cacheUserLanguage(language) {
    try {
      // save a user's language choice in Local storage
      await localStorage.setItem(STORE_LANGUAGE_KEY, language);
    } catch (error) {}
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // prevent XSS
    },
  });

export default i18n;
