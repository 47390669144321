import React from "react";
import Circle from "../../../components/Circle/Circle";

function CircleRow({ numOfCircles }) {
  return (
    <div className="d-flex justify-content-around">
      {[...Array(numOfCircles)].map(() => {
        return <Circle />;
      })}
    </div>
  );
}

export default CircleRow;
