import React from "react";
import { Modal } from "react-bootstrap";
import CenteredModal from "./Modal";

function ImageModal(props) {
  const { openModal, closeModal, image } = props;
  return (
    <CenteredModal openModal={openModal} closeModal={closeModal}>
      <Modal.Body style={{ textAlign: "center" }}>
        <div role="button" tabIndex={0} onKeyDown={closeModal} onClick={closeModal}>
          <i className="fa-solid fa-xmark close-bottom-sheet" style={{ color: "#909090" }} />
        </div>

        <img src={image} alt="coin-img" />
      </Modal.Body>
    </CenteredModal>
  );
}

export default ImageModal;
