import { MYPAY_API_URL, PAYMENT_API_URL, REACT_API_URL } from "./ENV";

const URI = "coin-conversion/merchant";
const URIClient = "coin-conversion/client";

export default {
  GENERATE_TOKEN: `${REACT_API_URL}auth/generate-auth-token`,
  GET_ALL_MERCHANT: `${REACT_API_URL}merchant/get-merchant-list`,
  GET_PRODUCT_LIST: `${REACT_API_URL}product/get-product-list`,
  GET_BANK_LIST: `${PAYMENT_API_URL}fpxbank/list_bank`,
  LOGIN: `${REACT_API_URL}client/get-client`,
  VERIFY_OTP: `${REACT_API_URL}client/verify-otp`,
  RESEND_OTP: `${REACT_API_URL}client/resend-otp`,
  REGISTER_USER: `${REACT_API_URL}client/add-client`,
  GET_INDUSTRY_LIST: `${REACT_API_URL}business-sector/get-business-sector-list`,
  ADD_PAYMENT_DETAILS: `${REACT_API_URL}payment/add-payment-details`,
  GET_PAYMENT_DETAILS: `${REACT_API_URL}payment/get-payment-details`,
  KOCEK_IN_LOGIN: `${MYPAY_API_URL}/login`,
  KOCEK_IN_GET_CLIENT: `${MYPAY_API_URL}/client/get-user`,
  KOCEK_IN_GET_OCCUPATION_TYPE: `${MYPAY_API_URL}/get-occupation-type`,
  KOCEK_IN_CHECK_AVAILABILITY: `${MYPAY_API_URL}/check-id-email-phone-availability`,
  KOCEK_IN_REGISTER: `${MYPAY_API_URL}/register`,
  KOCEK_IN_RESEND_CODE: `${MYPAY_API_URL}/client/profile/gen-verify-code-email`,
  KOCEK_IN_VERIFY_OTP: `${MYPAY_API_URL}/verify-email-code`,
  KOCEK_IN_TRANSACTION_CODE: `${MYPAY_API_URL}/${URI}/transaction-code`,
  KOCEK_IN_BANKS: `${MYPAY_API_URL}/coin-conversion/banks`,
  KOCEK_IN_CREATE_TRANSACTION: `${MYPAY_API_URL}/${URIClient}/create-transaction`,
  KOCEK_IN_CREATE_TRANSACTION_DETAIL_FOR_BANK: `${MYPAY_API_URL}/${URIClient}/transaction-detail-send-to-bank`,
  KOCEK_IN_SAVE_BANK: `${MYPAY_API_URL}/client/add-bank-infos`,
  KOCEK_IN_DELETE_BANK: `${MYPAY_API_URL}/client/delete-bank-infos`,
};
