import React, { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import i18n from "./i18n";
import CheckoutPage from "./pages/checkout/checkout";
import PaymentMethod from "./pages/payment/paymentMethod";
import CimbCDM from "./pages/payment/kocekTellerMachine/CIMB/cimbCDM";
import JomPay from "./pages/payment/kocekTellerMachine/JomPay/JomPay";
import BusinessDetails from "./pages/business/businessDetails";
import Login from "./pages/login/kocekOut/login";
import KocekInLogin from "./pages/login/kocekIn/login";
import KocekInSignUp from "./pages/login/kocekIn/signup";
import KocekInOtp from "./pages/login/kocekIn/otp";
import Otp from "./pages/login/kocekOut/otp";
import Signup from "./pages/login/kocekOut/signup";
import Merchant from "./pages/merchant/merchantList";
import Stock from "./pages/stock/stockList";
import "./App.css";
import { CoinContext } from "./configs/context/CoinContextProvider";
import Receipt from "./pages/receipt/receipt";
import TermsAndCondition from "./pages/tnc/TermsAndCondition";
import ServiceFeeCalculator from "./pages/serviceFeeCalculator/serviceFeeCalculator";
import { GOOGLE_ANALYTIC_ID, NODE_ENV } from "./configs/ENV";
import CoinSorting from "./pages/login/coinSorting";
import AccountVerifiedScreen from "./pages/login/kocekIn/accountVerifiedScreen";
import MerchantDetail from "./pages/merchantDetail/merchantDetail";
import InsertCode from "./pages/transactionSummary/insertCode";
import TransactionSummary from "./pages/transactionSummary/transactionSummary";
import TransactionConfirmation from "./pages/transactionConfirmation/transactionConfirmation";
import TransactionBankDetails from "./pages/transactionBankDetails/transactionBankDetails";
import ReceiptKocekIn from "./pages/transactionConfirmation/kocekInReceipt/receipt";
import TransactionBankSaved from "./pages/transactionBankSaved/transactionBankSaved";

function App() {
  const { selectedLanguage } = useContext(CoinContext);
  // Initialize Google Analytics with your tracking ID
  useEffect(() => {
    if (NODE_ENV === "production") {
      ReactGA.initialize(GOOGLE_ANALYTIC_ID);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }
  }, []);
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Merchant />,
    },
    {
      path: "stock",
      element: <Stock />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "kocek-in/login",
      element: <KocekInLogin />,
    },
    {
      path: "kocek-in/signup",
      element: <KocekInSignUp />,
    },
    {
      path: "kocek-in/otp",
      element: <KocekInOtp />,
    },
    {
      path: "kocek-in/account-verified",
      element: <AccountVerifiedScreen />,
    },
    {
      path: "kocek-in/coin-sorting",
      element: <CoinSorting />,
    },
    {
      path: "checkout",
      element: <CheckoutPage />,
    },
    {
      path: "checkout/payment-method",
      element: <PaymentMethod />,
    },
    {
      path: "checkout/cimb-cdm",
      element: <CimbCDM />,
    },
    {
      path: "checkout/jompay",
      element: <JomPay />,
    },
    {
      path: "otp",
      element: <Otp />,
    },
    {
      path: "business-details",
      element: <BusinessDetails />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "payment-receipt",
      element: <Receipt />,
    },
    {
      path: "payment-receipt/:id",
      element: <Receipt />,
    },
    {
      path: "terms-and-condition",
      element: <TermsAndCondition />,
    },
    {
      path: "service-fee-calculator",
      element: <ServiceFeeCalculator />,
    },
    {
      path: "merchant-details",
      element: <MerchantDetail />,
    },
    {
      path: "insert-code",
      element: <InsertCode />,
    },
    {
      path: "transaction-summary",
      element: <TransactionSummary />,
    },
    {
      path: "transaction-summary/confirmation",
      element: <TransactionConfirmation />,
    },
    {
      path: "transaction-summary/confirmation/bank-details",
      element: <TransactionBankDetails />,
    },
    {
      path: "transaction-summary/confirmation/receipt",
      element: <ReceiptKocekIn />,
    },
    {
      path: "transaction-summary/confirmation/bank-list",
      element: <TransactionBankSaved />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
