import React from "react";
import ProductContainer from "../../stock/productContainer";
import TwoButtonFooter from "../../../components/twoButtonFooter";

function EditProducts({
  productList,
  addProductToCart,
  subtractProductFromCart,
  onChangeQuantity,
  leftButton,
  saveOrderDetails,
  rightButton,
}) {
  return (
    <>
      <div className="container">
        <ProductContainer
          productList={productList}
          add={addProductToCart}
          subtract={subtractProductFromCart}
          onChangeQuantity={onChangeQuantity}
        />
      </div>
      <TwoButtonFooter
        renderLeftButton={leftButton}
        onClickRightButton={saveOrderDetails}
        renderRightButton={rightButton}
        rightButtonCustomStyle={{ backgroundColor: "#282828" }}
        leftButtonCustomStyle={{ backgroundColor: "white" }}
        rightDisabledStyle={{ backgroundColor: "#F5F5F5" }}
      />
    </>
  );
}

export default EditProducts;
