import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../../layouts/MainLayout";
import FormSignUp from "./formSignUp";
import { CoinContext } from "../../../configs/context/CoinContextProvider";
import { isValidEmail, isValidIDNumber, isValidMobileNumber, isValidPassword } from "../../../utils";
import Axios from "../../../configs/serviceProvider/Axios";
import API from "../../../configs/API";
import BottomSheetModal from "../../../components/BottomSheet";
import DropDownSelection from "../../../components/DropDownSelection/DropDownSelection";
import LoadingSpinner from "../../../components/LoadingSpinner";
import TermsAndCondition from "../../../components/TermsAndCondition";

function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [ic, setIC] = useState("");
  const [icError, setIcError] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [checkPasswordError, setCheckPasswordError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [occupationList, setOccupationList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckLoading, setIsCheckLoading] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [openTNCBottomSheet, setOpenTNCBottomSheet] = useState(false);
  const { setUserToken, selectedLanguage, setuserDetails } = useContext(CoinContext);

  useEffect(() => {
    fetchOccupation();
  }, []);

  const fetchOccupation = async () => {
    setIsLoading(true);
    try {
      const response = await Axios.get(API.KOCEK_IN_GET_OCCUPATION_TYPE);

      if (response.status === 200 && response.data) {
        setOccupationList(response.data);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleIcChange = useCallback((event) => {
    const { value } = event.target;
    setIC(value);

    // Check for special characters and length
    if (value) {
      if (!isValidIDNumber(value)) {
        setIcError(t("kocekIn.NO_SPECIAL_CHARACTERS"));
      } else if (value.length < 12 || value.length > 12) {
        setIcError(t("kocekIn.IC_LENGTH")); // Assuming you have this translation key
      } else {
        setIcError(null);
      }
    } else {
      setIcError(null);
    }
  }, []);

  const handleNameChange = useCallback((event) => {
    const { value } = event.target;
    setName(value);
  }, []);

  const handlePhoneNumberChange = useCallback((event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    if (value && !isValidMobileNumber(value)) setPhoneError(t("kocekIn.MOBILE_NUMBER_UNSUITABLE"));
    else setPhoneError(null);
  }, []);

  const handleEmailChange = useCallback((event) => {
    const { value } = event.target;
    setEmail(value);
    if (value && !isValidEmail(value)) setEmailError(t("kocekIn.EMAIL_ADDRESS_UNSUITABLE"));
    else setEmailError(null);
  }, []);

  const handleAddressChange = useCallback((event) => {
    const { value } = event.target;
    setAddress(value);
  }, []);

  const closeBottomSheet = useCallback(() => setOpenBottomSheet(false), []);
  const openBottomSheetModal = useCallback(() => setOpenBottomSheet(true), []);

  const radioOnClick = useCallback((item) => {
    setOccupation(item);
    closeBottomSheet();
  }, []);

  const goBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleCheckAvailability = useCallback(async () => {
    setIsCheckLoading(true);
    try {
      const response = await Axios.post(API.KOCEK_IN_CHECK_AVAILABILITY, {
        idNumber: ic,
        email,
        phone: phoneNumber,
        phoneCountryCode: "+60",
      });

      if (response.status === 200) {
        setStep(step + 1);
      }
      setIsCheckLoading(false);
    } catch (e) {
      toast.error(t(`kocekIn.${e.response.data.message}`));
      setIsCheckLoading(false);
    }
  }, [ic, email, phoneNumber, step]);

  const handlePasswordChange = useCallback((event) => {
    const { value } = event.target;
    setPassword(value);
    if (value && !isValidPassword(value)) setPasswordError(t("kocekIn.PASSWORD_UNSUITABLE"));
    else setPasswordError(null);
  }, []);

  const handleConfirmedPasswordChange = useCallback((event) => {
    const { value } = event.target;
    setConfirmPassword(value);
  }, []);

  const handleVisible = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const handleConfirmedVisible = useCallback(() => {
    setIsConfirmVisible(!isConfirmVisible);
  }, [isConfirmVisible]);

  // Set password errors for check password
  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setCheckPasswordError(t("kocekIn.PASSWORD_DO_NOT_MATCH"));
      return;
    }

    setCheckPasswordError(null);
  }, [password, confirmPassword]);

  const closeModal = () => setOpenTNCBottomSheet(false);
  const openTncModal = () => setOpenTNCBottomSheet(true);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsRegisterLoading(true);
      try {
        const response = await Axios.post(API.KOCEK_IN_REGISTER, {
          isPortOut: true,
          icType: "MyKad",
          email,
          password,
          ic,
          phone: phoneNumber,
          phoneCountryCode: "+60",
          occupationType: occupation.occupation_type_name_en,
          name,
          address,
        });

        if (response.status === 200) {
          setUserToken(response.data.token);
          setuserDetails({ email });
          navigate("/kocek-in/otp");
        }
        setIsRegisterLoading(false);
      } catch (e) {
        toast.error(t(`kocekIn.${e.response.data.message}`));
        setIsRegisterLoading(false);
      }
    },
    [email, password, phoneNumber, occupation, ic, name, address]
  );

  return (
    <>
      <BottomSheetModal openModal={openBottomSheet} onDismiss={closeBottomSheet} className="industry-bottom-sheet">
        <div className="dropdown-selection">
          {isLoading ? (
            <div className="text-center">
              <LoadingSpinner color="#C4C4C4" borderWidth={2} />
            </div>
          ) : (
            <div>
              {occupationList && occupationList?.length > 0 ? (
                <div>
                  {occupationList.map((item) => {
                    return (
                      <DropDownSelection
                        data={item}
                        name={selectedLanguage === "en" ? item.occupation_type_name_en : item.occupation_type_name_bm}
                        radioOnClick={radioOnClick}
                        checked={occupation.occupation_type_id === item.occupation_type_id}
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
          )}
        </div>
      </BottomSheetModal>
      <BottomSheetModal className="stock-bottom-sheet" openModal={openTNCBottomSheet} onDismiss={closeModal}>
        <TermsAndCondition onClick={closeModal} />
      </BottomSheetModal>
      <MainLayout
        hasFooter={false}
        backButton={step !== 1}
        backButtonOnClick={goBack}
        title={t("kocekIn.CREATE_AN_ACCOUNT")}
        navbarRightButton={
          <Link
            to=".."
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <i className="fa-solid fa-times" style={{ color: "#000000" }} />
          </Link>
        }
      >
        <FormSignUp
          step={step}
          ic={ic}
          name={name}
          icError={icError}
          password={password}
          handleIcChange={handleIcChange}
          phoneNumber={phoneNumber}
          handleNameChange={handleNameChange}
          handlePhoneNumberChange={handlePhoneNumberChange}
          email={email}
          handleEmailChange={handleEmailChange}
          address={address}
          handleAddressChange={handleAddressChange}
          occupation={occupation}
          occupationList={occupationList}
          isLoading={isLoading}
          openBottomSheetModal={openBottomSheetModal}
          selectedLanguage={selectedLanguage}
          emailError={emailError}
          phoneError={phoneError}
          isCheckLoading={isCheckLoading}
          handleCheckAvailability={handleCheckAvailability}
          handlePasswordChange={handlePasswordChange}
          handleConfirmedPasswordChange={handleConfirmedPasswordChange}
          isVisible={isVisible}
          isConfirmVisible={isConfirmVisible}
          handleVisible={handleVisible}
          handleConfirmedVisible={handleConfirmedVisible}
          confirmPassword={confirmPassword}
          passwordError={passwordError}
          checkPasswordError={checkPasswordError}
          openTncModal={openTncModal}
          handleSubmit={handleSubmit}
          isRegisterLoading={isRegisterLoading}
        />
      </MainLayout>
    </>
  );
}

export default SignUp;
