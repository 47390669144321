/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import Checkbox from "../../components/Checkbox/Checkbox";

function TransactionBankDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useContext(CoinContext);

  // get banks when load
  const [banks, setBanks] = useState([]);

  const { data } = location.state || { data: {} };

  const [inputValue, setInputValue] = useState({
    bankId: "",
    bankName: "",
    accountNo: "",
    accountHolder: "",
  });

  useEffect(() => {
    if (data.bank) setInputValue(data.bank);
  }, [data]);

  const [isAddNew] = useState(data.addNew);
  const [isEdit] = useState(data.isEdit);
  const [checked, setChecked] = useState(false);
  // check if no token back to login
  if (!userToken) {
    navigate("/kocek-in/login");
  }

  let textButton = t("CONTINUE");

  if (isAddNew) {
    textButton = t("SAVE");
  } else if (isEdit) {
    textButton = t("UPDATE");
  }

  useEffect(() => {
    if (userToken && banks.length === 0) {
      const getBanks = async () => {
        try {
          const response = await Axios.get(API.KOCEK_IN_BANKS, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response.data && response.data.banks) {
            setBanks(response.data.banks);
          }
        } catch (error) {
          toast.error("Failed to get banks data");
        }
      };
      getBanks();
    }
  }, []);

  const handleNavigation = () => {
    // check if all input is filled

    inputValue.bankName = getBankNameById(inputValue.bankId);
    if (!inputValue.bankName || !inputValue.accountNo || !inputValue.accountHolder) {
      toast.error(`Account number must be ${inputValue.accountNumberMinLength} characters`);
    }

    // check if account number length is within min and max length
    if (
      inputValue.accountNo.length < inputValue.accountNumberMinLength ||
      inputValue.accountNo.length > inputValue.accountNumberMaxLength
    ) {
      if (inputValue.accountNumberMinLength === inputValue.accountNumberMaxLength) {
        toast.error(`Account number must be ${inputValue.accountNumberMinLength} characters`);
        return;
      }
      toast.error(
        `Account number must be between ${inputValue.accountNumberMinLength} and ${inputValue.accountNumberMaxLength} characters`
      );
      return;
    }

    // save info using api
    if (checked || isAddNew) {
      Axios.post(
        API.KOCEK_IN_SAVE_BANK,
        {
          bankId: inputValue.bankId,
          bankNumber: inputValue.accountNo,
          holderName: inputValue.accountHolder,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      ).catch((error) => {
        toast.error(error);
      });
    }
    if (isEdit) {
      // need to delete and add new bank'
      Axios.delete(API.KOCEK_IN_DELETE_BANK, {
        data: {
          bankId: inputValue.bankId,
          bankNumber: inputValue.accountNo,
          holderName: inputValue.accountHolder,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }).catch((error) => {
        toast.error(error);
      });

      Axios.post(
        API.KOCEK_IN_SAVE_BANK,
        {
          bankId: inputValue.bankId,
          bankNumber: inputValue.accountNo,
          holderName: inputValue.accountHolder,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      ).catch((error) => {
        toast.error(error);
      });
    }

    // go back to confirmation with input value as state
    navigate("/transaction-summary/confirmation", { state: inputValue });
  };

  function handleInputChange(event) {
    const { name, value } = event.target;

    if (name === "bankName") {
      const selectedBank = banks.find((banker) => banker.id == value);
      setInputValue((prevState) => ({
        ...prevState,
        bankId: value,
        accountNumberMaxLength: selectedBank.accountNumberMaxLength,
        accountNumberMinLength: selectedBank.accountNumberMinLength,
      }));
    } else {
      setInputValue((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  function getBankNameById(bankId) {
    const bank = banks.find((bankProvider) => bankProvider.id == bankId);
    return bank.name;
  }

  return (
    <>
      <MainLayout
        footerText={textButton}
        title={isAddNew ? t("ADD_BANK_ACCOUNT") : t("BANK_DETAILS")}
        backgroundColor="#ffffff"
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
        containerStyle={{ padding: "0px" }}
        hasFooter
        footerButtonOnClick={handleNavigation}
        twoButton={isEdit}
        topButtonText={t("UPDATE")}
        bottomButtonText={t("REMOVE_ACCOUNT")}
        topButtonOnClick={handleNavigation}
        bottomButtonOnClick={() => {
          Axios.delete(API.KOCEK_IN_DELETE_BANK, {
            data: {
              bankId: inputValue.bankId,
              bankNumber: inputValue.accountNo,
              holderName: inputValue.accountHolder,
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
            .then(() => {
              navigate("/transaction-summary/confirmation/bank-list");
            })
            .catch((error) => {
              toast.error(error);
            });
        }}
        bottomButtonStyle={{
          color: "#BB2124",
        }}
        navbarRightButton={
          <Link
            to=".."
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            <i className="fa-solid fa-times" style={{ color: "#000000" }} />
          </Link>
        }
      >
        <div className="container-flex w-100-wh mb-2 h-100 font-16">
          <form className="mb-4">
            <div className="form-outline mt-4 mb-4">
              <div className="mb-3 px-3">
                <div className="font-12  warning-bank p-3">
                  <i className="fa fa-info-circle" aria-hidden="true" /> {t("BANK_DETAILS_WARNING")}
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="bankName" className="form-label text-start">
                    {t("BANK_NAME")}
                  </label>
                  <select
                    className="form-select"
                    id="bankName"
                    name="bankName"
                    placeholder="Select bank"
                    value={inputValue.bankId}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      {t("SELECT_BANK")}
                    </option>
                    {banks.map((bank) => (
                      <option key={bank.id} value={bank.id} selected={bank.id === inputValue.bankId}>
                        {bank.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="accountNo" className="form-label text-start">
                    {t("ACCOUNT_NUMBER")}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="accountNo"
                    name="accountNo"
                    value={inputValue.accountNo}
                    onChange={handleInputChange}
                    placeholder={t("ENTER_YOUR_ACCOUNT_NUMBER")}
                    inputMode="numeric"
                    maxLength={inputValue.accountNumberMaxLength}
                    minLength={inputValue.accountNumberMinLength}
                    style={{
                      appearance: "textfield",
                      MozAppearance: "textfield",
                      WebkitAppearance: "none",
                      margin: 0,
                    }}
                  />
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="accountHolder" className="form-label text-start">
                    {t("ACCOUNT_HOLDER")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="accountHolder"
                    name="accountHolder"
                    value={inputValue.accountHolder}
                    onChange={handleInputChange}
                    placeholder={t("ENTER_YOUR_ACCOUNT_HOLDER")}
                  />
                </div>
              </div>

              {!isAddNew && !isEdit && (
                <div className=" bg-white mt-2 py-3 px-3">
                  <div className="d-flex justify-content-start">
                    <Checkbox
                      containerStyle={{
                        borderColor: "#DDDDDD",
                        borderWidth: 1,
                        borderStyle: "solid",
                        width: 20,
                        height: 20,
                      }}
                      borderRadius={4}
                      checkedStyle={{
                        backgroundColor: "#3FBAFF",
                        borderColor: "#3FBAFF",
                      }}
                      checked={checked}
                      onChange={() => setChecked((prev) => !prev)}
                    />
                    <div className="color-303030 font-14 padding-l-20"> {t("SAVE_BANK_ACCOUNT")}</div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </MainLayout>
    </>
  );
}

export default TransactionBankDetails;
