import React from "react";

// A helper function to format the time in a more readable format.
const formatTime = (dateString) => {
  const date = new Date(dateString);
  // Format time as AM/PM
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
};

function OpeningHours({ openDayDetails }) {
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // Renders the shifts for a given day.
  const renderShiftsForDay = (day) => {
    const dayKey = day.substring(0, 3).toLowerCase(); // Convert "Monday" to "mon", etc.
    const shifts = openDayDetails[dayKey]?.shifts;
    if (shifts && shifts.length > 0) {
      const { startTime, endTime } = shifts[0];
      // Check if the start and end times are at the beginning and end of the day
      const opensAllDay = formatTime(startTime) === "12:00 AM" && formatTime(endTime) === "11:59 PM";
      const timeString = opensAllDay ? "12:00 AM - 11:59 PM" : `${formatTime(startTime)} - ${formatTime(endTime)}`;

      return <span>Opens at {timeString}</span>;
    }
    return <span>Closed</span>; // Default to Closed if no shifts are found
  };

  return (
    <ul className="list-unstyled">
      {daysOfWeek.map((day, index) => (
        <li key={index} className="d-flex justify-content-between py-2">
          <span className="text-left">{day}:</span>
          <span className="text-right">{renderShiftsForDay(day)}</span>
        </li>
      ))}
    </ul>
  );
}

export default OpeningHours;
