import React from "react";
import { useTranslation } from "react-i18next";

function UserDetailsContainer({ client }) {
  const { t } = useTranslation();
  return (
    <div className="user-details-container">
      <p className="kocek-amount-title">
        {t("PERSONAL_DETAILS").charAt(0) + t("PERSONAL_DETAILS").substring(1).toLowerCase()}
      </p>
      <div className="personal-details">
        <div className="mb-2">
          <p className="details-title">{t("NAME_AND_PHONE")}</p>
          <p className="details-1">{client?.client_name || "-"}</p>
          <p className="details-2">
            {client?.client_phone_prefix && client?.client_phone
              ? `(${client?.client_phone_prefix}) ${client?.client_phone}`
              : "-"}
          </p>
        </div>
        <div>
          <p className="details-title">{t("EMAIL")}</p>
          <p className="details-1">{client?.client_email || "-"}</p>
        </div>
      </div>
      <p className="kocek-amount-title mt-3">
        {t("BUSINESS_DETAILS").charAt(0) + t("BUSINESS_DETAILS").substring(1).toLowerCase()}
      </p>
      <div className="business-details">
        <div className="mb-2">
          <p className="details-title">{t("BUSINESS_NAME")}</p>
          <p className="details-1">{client?.client_company_name || "-"}</p>
        </div>
        <div>
          <p className="details-title">{t("BUSINESS_INDUSTRY")}</p>
          <p className="details-1">{client?.client_company_industry || "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsContainer;
