import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../../configs/API";
import Axios from "../../../configs/serviceProvider/Axios";
import { CoinContext } from "../../../configs/context/CoinContextProvider";
import MainLayout from "../../../layouts/MainLayout";
import EMAIL from "../../../images/Email.png";
import "./style.css";
import { useTranslation } from "react-i18next";

function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUserToken } = useContext(CoinContext);
  const [inputValue, setInputValue] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    setIsButtonEnabled(value.trim() !== "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = `${API.LOGIN}`;
      const response = await Axios.post(url, {
        email: inputValue,
      });

      if (response.data.token) {
        setUserToken(response.data.token);
        navigate("/otp");
      } else {
        // setError('Invalid username or password');
        alert("Invalid email");
      }
    } catch (error) {
      let err = error.response.data;
      toast.error(err.error);
    }
  };

  return (
    <MainLayout hasFooter={false} backButton>
      <div className="main container">
        <div className="iconContainer mt-4 mb-4">
          <img src={EMAIL} className="App-logo" alt="logo" width="30%" height="auto" />
        </div>
        <div className="text-center">
          <h5>{t("ENTER_EMAIL")}</h5>
          <div className="title2 mt-4 text-muted">{t("LOGIN_TITLE")}</div>
          <form onSubmit={handleSubmit}>
            <div className="form-outline mt-4 mb-4">
              <input
                value={inputValue}
                onChange={handleInputChange}
                type="email"
                id="email"
                className="form-control"
                placeholder={t("EMAIL_PLACEHOLDER")}
              />
            </div>
            <button
              className={`cstmbtn mb-4 ${isButtonEnabled ? "enabled" : "disabled"}`}
              type="submit"
              disabled={!isButtonEnabled}
            >
              {t("CONTINUE")}
            </button>
          </form>
          <div>
            <span>
              <a className="guestText cursor-pointer" href="/signup">
                {t("SIGN_UP")}
              </a>
            </span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Login;
