import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import logo1 from "../../../images/kocek_black_logo.png";
import Chip from "../../../components/Chip/Chip";
import { error, success } from "../../../images";
import CircleRow from "./CircleRow";

const STATUS_TEXT = {
  RECEIVED: {
    icon: <img src={success} alt="success-logo" style={{ width: 14, marginRight: 5 }} />,
    textStatus: "RECEIVED",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
  PENDING: {
    textStatus: "PENDING_PAYMENT",
    textColor: "#FFBF00",
    backgroundColor: "#F9D42C1A",
  },
  FAILED: {
    icon: <img src={error} alt="failed-logo" style={{ width: 14, marginRight: 5 }} />,
    textStatus: "FAILED",
    textColor: "#E15241",
    backgroundColor: "#F8DBDB",
  },
};

export const getPaymentMethodText = (paymentMethod) => {
  let paymentMethodText = "";

  switch (paymentMethod) {
    case "fpx":
      paymentMethodText = "Online Banking";
      break;
    case "mypay":
      paymentMethodText = "MyPay";
      break;
    case "card":
      paymentMethodText = "Credit/ Debit Card";
      break;
    case "jompay":
      paymentMethodText = "Kocek Teller Machine - ATM JomPAY";
      break;
    default:
      break;
  }

  return paymentMethodText;
};

export const getStatusText = (status) => {
  const data = STATUS_TEXT[status];
  return !data ? {} : data;
};

function PaymentReceiptContainer({
  total,
  totalAmount,
  serviceTax,
  status,
  transactionId,
  taxInvoice,
  paymentDetails,
  paymentAuth,
  orderDetails,
  merchant,
  paymentMethod,
}) {
  const statusText = getStatusText(status);
  const paymentMethodText = getPaymentMethodText(paymentMethod.payment_method_type);
  const { t } = useTranslation();
  return (
    <div className="payment-receipt-container">
      <div className="mb-3">
        <img src={logo1} alt="kocek-logo" style={{ height: 15 }} />
        <div className="d-flex justify-content-between">
          <p className="receipt-payment-amount">RM{paymentAuth?.payment_total_amount?.toFixed(2) || total}</p>
          <Chip
            icon={statusText.icon}
            text={statusText.textStatus}
            color={statusText.backgroundColor}
            borderColor={statusText.textColor}
          />
        </div>
        {status === "FAILED" || status === "RECEIVED" ? (
          <p className="subtotal ms-0">{moment(paymentDetails.payment_created_on).format("D MMM YYYY, h:mma")}</p>
        ) : null}
      </div>

      {status !== "FAILED" ? (
        <>
          {orderDetails.map((item, index) => {
            return (
              <div
                className="order-container"
                style={index === orderDetails.length - 1 ? { borderBottom: "none" } : null}
              >
                <div className="d-flex justify-content-between">
                  <p className="order-number">#{index + 1}</p>
                  <p className="order-number">
                    {item.product_quantity} {t("PACK")}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <p className="order-name">{item.product_name}</p>
                    {item.product_remark ? (
                      <p className="order-remark">• {item.product_remark === "Old" ? t("OLD_COIN") : t("NEW_COIN")}</p>
                    ) : null}
                  </div>

                  <p className="order-name">RM{parseFloat(item.product_total_amount).toFixed(2)}</p>
                </div>
              </div>
            );
          })}
          <div
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#CCCCCC",
              borderBottomStyle: "solid",
              marginBottom: 15,
            }}
          />
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <p className="kocek-amount-title">{t("TOTAL_WITHDRAWAL_AMOUNT")}</p>
              <p className="subtotal">{`(${t("SUBTOTAL")})`}</p>
            </div>
            <p className="kocek-amount">
              RM
              {paymentDetails?.payment_amount?.toFixed(2) || totalAmount.toFixed(2)}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="kocek-amount-title">{t("SERVICE_FEE_PERCENT")}</p>
            <p className="kocek-amount">
              RM
              {paymentDetails?.payment_sst_charge_amount?.toFixed(2) || serviceTax.toFixed(2)}
            </p>
          </div>
        </>
      ) : null}
      <div className="d-flex justify-content-between align-items-center">
        <p className="amount-due">{status === "RECEIVED" ? t("TOTAL_PAYMENT_PAID") : t("TOTAL_PAYMENT")}</p>
        <p className="amount-due">RM{paymentAuth?.payment_total_amount?.toFixed(2) || total}</p>
      </div>
      {status !== "PENDING" ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <p className="amount-due">{t("TAX_INVOICE_NO")}</p>
            <p className="amount-due">{taxInvoice || "N/A"}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="amount-due">{t("TRANSACTION_ID")}</p>
            <p className="amount-due">{transactionId || "N/A"}</p>
          </div>
        </>
      ) : null}

      {status !== "FAILED" ? (
        <p className="amount-due">
          {t("ORDER_COLLECTION_LOCATION")}: {merchant.store_name}, {merchant.address}
        </p>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <p className="amount-due">{t("PAYMENT_METHOD").charAt(0) + t("PAYMENT_METHOD").substring(1).toLowerCase()}</p>
          <p className="amount-due">{paymentMethodText}</p>
        </div>
      )}
      <div className="circles">
        <CircleRow numOfCircles={10} />
      </div>
    </div>
  );
}

export default PaymentReceiptContainer;
