import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../components/HorizontalSeparator";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

function PersonalDetailsContainer() {
  const { userDetails } = useContext(CoinContext);
  const { t } = useTranslation();
  return (
    <div className="checkout-container mb-2">
      <div className="d-flex justify-content-between align-items-center">
        <p className="title mx-0">{t("PERSONAL_DETAILS")}</p>
        {/* For future use */}
        {/* <p
          className="title mx-0"
          style={{ color: "#3FBAFF", fontWeight: "600" }}
        >
          {t("EDIT")}
        </p> */}
      </div>

      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="details-container">
        <div>
          <p className="title2">{t("NAME_AND_PHONE")}</p>
          <p className="subtitle">
            {userDetails?.client?.client_name || "-"} |{" "}
            {userDetails?.client?.client_phone_prefix && userDetails?.client?.client_phone
              ? `(${userDetails?.client?.client_phone_prefix}) ${userDetails?.client?.client_phone}`
              : "-"}
          </p>
        </div>
        <div>
          <p className="title2">{t("EMAIL")}</p>
          <p className="subtitle mb-0">{userDetails?.client?.client_email || "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetailsContainer;
