import React from "react";

function PaymentGatewayContainer() {
  return (
    <form id="paygatewayform" method="post" action="">
      <input name="payment_method" id="payment_method" type="hidden" />
      <input name="bank_id" id="bank_id" type="hidden" />
    </form>
  );
}

export default PaymentGatewayContainer;
