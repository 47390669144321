import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import logo1 from "../../../../images/kocek_black_logo.png";
import Chip from "../../../../components/Chip/Chip";
import { error, success } from "../../../../images";
import CircleRow from "./CircleRow";
import HorizontalSeparator from "../../../../components/HorizontalSeparator";

const STATUS_TEXT = {
  RECEIVED: {
    icon: <img src={success} alt="success-logo" style={{ width: 14, marginRight: 5 }} />,
    textStatus: "RECEIVED",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
  PENDING: {
    textStatus: "COIN RECEIVED",
    textColor: "#FFBF00",
    backgroundColor: "#F9D42C1A",
  },
  FAILED: {
    icon: <img src={error} alt="failed-logo" style={{ width: 14, marginRight: 5 }} />,
    textStatus: "FAILED",
    textColor: "#E15241",
    backgroundColor: "#F8DBDB",
  },
};

export const getStatusText = (status) => {
  const data = STATUS_TEXT[status];
  return !data ? {} : data;
};

function PaymentReceiptContainer({ receipt }) {
  const statusText = getStatusText("PENDING");
  const { t } = useTranslation();
  return (
    <div className="payment-receipt-container">
      <div className="mb-3">
        <img src={logo1} alt="kocek-logo" style={{ height: 15 }} />
        <div className="d-flex justify-content-between">
          <p className="receipt-payment-amount">RM{receipt.receipt.finalAmount.toFixed(2)}</p>
          <Chip
            icon={statusText.icon}
            text={statusText.textStatus}
            color={statusText.backgroundColor}
            borderColor={statusText.textColor}
          />
        </div>
        <p className="subtotal ms-0">{moment(receipt.receipt.transaction_dt).format("D MMM YYYY, h:mma")}</p>
      </div>

      <div className="details-container">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="subtitle mb-0">
            {t("KOCEK_IN_AMOUNT")} <span className="details-title">({t("SUBTOTAL")})</span>
          </p>
          <p className="subtitle mb-0">{`RM${receipt.receipt.oriCoinAmount.toFixed(2)}`}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: 16 }}>
          <p className="details-2 mb-0">{t("SERVICE_FEE_PERCENT")}</p>
          <p className="subtitle mb-0">{`RM${receipt.receipt.totalFee.toFixed(2)}`}</p>
        </div>
      </div>

      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />

      <div className="d-flex justify-content-between align-items-center mt-3">
        <p className="amount-due">
          {t("TOTAL_AMOUNT")}
          <p className="details-title">{t("AMOUNT_TO_BE_TRANSFERED")}</p>
        </p>
        <p className="amount-due">RM {receipt.receipt.finalAmount.toFixed(2) || "N/A"}</p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="amount-due">{t("TAX_INVOICE_NO")}</p>
        <p className="amount-due">{receipt.receipt.receipt_no || "N/A"}</p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="amount-due">{t("TRANSACTION_ID")}</p>
        <p className="amount-due">{receipt.receipt.transaction_id || "N/A"}</p>
      </div>
      <p className="details-2">
        {t("ORDER_COLLECTION_LOCATION")}: {receipt.merchant.details.store_name}, {receipt.merchant.details.address}
      </p>
      <div className="circles">
        <CircleRow numOfCircles={10} />
      </div>
    </div>
  );
}

export default PaymentReceiptContainer;
