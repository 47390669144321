import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CoinContext } from "../configs/context/CoinContextProvider";
// import Style from './Style.css'

function Navbar(props) {
  const location = useLocation();
  const {
    title,
    hamburgerButton,
    backButton,
    backButtonOnClick,
    rightButton,
    backgroundColor,
    backButtonColor,
    backtoMain,
  } = props;

  const { t } = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useContext(CoinContext);
  const navigate = useNavigate();
  return (
    <nav className={`navbar navbar-light ${backgroundColor ? null : "bg-light"} py-3`} style={{ backgroundColor }}>
      <div className="container-fluid">
        {hamburgerButton ? (
          <div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="offcanvas offcanvas-start"
              tabIndex="5"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 mt-4">
                  <li className="nav-item">
                    <div data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${location.pathname === "/" ? "actived" : null}`}
                        aria-current="page"
                        to="/"
                      >
                        {t("KOCEK_OUT_ONLINE_ORDER")}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${location.pathname === "/service-fee-calculator" ? "actived" : null}`}
                        to="/service-fee-calculator"
                      >
                        {t("SERVICE_FEE_CALCULATOR")}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div data-bs-dismiss="offcanvas">
                      <Link
                        className={`nav-link ${location.pathname === "/terms-and-condition" ? "actived" : null}`}
                        to="/terms-and-condition"
                      >
                        {t("TERMS_AND_CONDITIONS")}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="offcanvas-footer">
                <p className="language-title">{t("LANGUAGE")}</p>
                <div className="d-flex align-items-center justify-content-center pe-3 language-button">
                  <div role="button" onClick={() => setSelectedLanguage("en")} className="w-50">
                    <p
                      className={`text-center mb-0 ${
                        selectedLanguage === "en" ? "selected-language" : "default-language-button"
                      }`}
                    >
                      ENGLISH
                    </p>
                  </div>
                  <div role="button" onClick={() => setSelectedLanguage("my")} className="w-50">
                    <p
                      className={`text-center mb-0 ${
                        selectedLanguage === "my" ? "selected-language" : "default-language-button"
                      }`}
                    >
                      BAHASA MELAYU
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {backtoMain ? (
          <div>
            <Link
              to=".."
              onClick={(e) => {
                e.preventDefault();
                navigate("/");
              }}
              style={{ visibility: backtoMain ? "visible" : "hidden" }}
            >
              <i className="fa-solid fa-x" style={{ color: backButtonColor || "#000000" }} />
            </Link>
          </div>
        ) : null}

        <Link
          to=".."
          onClick={(e) => {
            e.preventDefault();
            if (backButtonOnClick) backButtonOnClick();
            else navigate(-1);
          }}
          style={{ visibility: backButton ? "visible" : "hidden" }}
        >
          <i className="fa-solid fa-chevron-left" style={{ color: backButtonColor || "#000000" }} />
        </Link>
        <div className="mx-auto justify-content-center" style={{ marginRight: "60px" }}>
          <h4 style={{ marginRight: "20px" }}>{title}</h4>
        </div>
        <div style={{ visibility: rightButton ? "visible" : "hidden" }}>{rightButton}</div>
      </div>
    </nav>
  );
}

export default Navbar;
