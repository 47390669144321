export const countries = [
  "Johor",
  "Kedah",
  "Kelantan",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Penang",
  "Perak",
  "Perlis",
  "Sabah",
  "Selangor",
  "Terengganu",
  "WP Kuala Lumpur",
  "WP Labuan",
  "WP Putrajaya",
];

// export const countries = [
//   "Johor (JHR)",
//   "Kedah (KDH)",
//   "Kelantan (KEL)",
//   "Melaka (MLK)",
//   "Negeri Sembilan (NSN)",
//   "Pahang (PHG)",
//   "Penang (PNG)",
//   "Perak (PRK)",
//   "Perlis (PLS)",
//   "Sabah (SBH)",
//   "Selangor (SGR)",
//   "Terengganu (TRG)",
//   "WP Kuala Lumpur (KUL)",
//   "WP Labuan (LBN)",
//   "WP Putrajaya (PJY)",
// ];
