import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function MainLayout(props) {
  const {
    children,
    footerText,
    title,
    hasFooter,
    backButton,
    backButtonOnClick,
    hamburgerButton,
    footerButtonOnClick,
    backgroundColor,
    navbarBackgroundColor,
    containerStyle,
    twoButton,
    topButtonStyle,
    bottomButtonStyle,
    topButtonText,
    bottomButtonText,
    bottomButtonOnClick,
    topButtonOnClick,
    topButtonIcon,
    bottomButtonIcon,
    disabled,
    topButtonDisabled,
    bottomButtonDisabled,
    disabledStyle,
    disabledTopStyle,
    disabledBottomStyle,
    navbarRightButton,
    backButtonColor,
    leftTopButtonIcon,
    backtoMain,
  } = props;

  return (
    <>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname !== "/checkout";
        }}
      />
      <div className="d-flex flex-column min-vh-100" style={{ backgroundColor }}>
        <header className="sticky-top fixed-navbar">
          <Navbar
            title={title}
            backButton={backButton}
            hamburgerButton={hamburgerButton}
            backButtonOnClick={backButtonOnClick}
            rightButton={navbarRightButton}
            backgroundColor={navbarBackgroundColor}
            backButtonColor={backButtonColor}
            backtoMain={backtoMain}
          />
        </header>
        <main className="d-flex flex-grow-1">
          <div className="container flex-grow-1 mt-1" style={containerStyle}>
            {children}
          </div>
        </main>
        {hasFooter ? (
          <footer className="bg-white text-center text-lg-start mt-auto fixed-footer fixed-bottom row justify-content-center">
            <div className="col-12 col-lg-6">
              <Footer
                footerText={footerText}
                buttonOnClick={footerButtonOnClick}
                twoButton={twoButton}
                topButtonStyle={topButtonStyle}
                bottomButtonStyle={bottomButtonStyle}
                topButtonText={topButtonText}
                bottomButtonText={bottomButtonText}
                bottomButtonOnClick={bottomButtonOnClick}
                topButtonOnClick={topButtonOnClick}
                topButtonIcon={topButtonIcon}
                leftTopButtonIcon={leftTopButtonIcon}
                bottomButtonIcon={bottomButtonIcon}
                topButtonDisabled={topButtonDisabled}
                bottomButtonDisabled={bottomButtonDisabled}
                disabled={disabled}
                disabledStyle={disabledStyle}
                disabledBottomStyle={disabledBottomStyle}
                disabledTopStyle={disabledTopStyle}
              />
            </div>
          </footer>
        ) : null}
      </div>
    </>
  );
}

export default MainLayout;
