import en from "./en";
import my from "./my";

export default {
  en,
  my,
};

export const DEFAULT_LANGUAGE = "en";

// Language options
export const LANGUAGES = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "my",
    label: "Bahasa Malaysia",
  },
];
