import React from "react";
import { useTranslation } from "react-i18next";

const styles = {
  chip: {
    borderRadius: 20,
    padding: 8,
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    maxWidth: 120,
  },
  chipText: {
    textAlign: "center",
    fontSize: 10,
    fontWeight: "500",
    color: "#ffffff",
    marginBottom: 0,
  },
};

function Chip({ text, color, borderColor, icon }) {
  const { t } = useTranslation();
  return (
    <div
      style={
        borderColor
          ? {
              ...styles.chip,
              backgroundColor: color,
              borderColor,
              borderWidth: 1,
            }
          : {
              ...styles.chip,
              backgroundColor: color,
            }
      }
    >
      {icon}
      <p style={{ ...styles.chipText, color: borderColor || "#ffffff" }}>{t(text)}</p>
    </div>
  );
}

export default Chip;
