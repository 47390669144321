import React from "react";
import HorizontalSeparator from "../HorizontalSeparator";
import Radio from "../Radio/Radio";

function DropDownSelection(props) {
  const { data, name, radioOnClick, checked, disabled } = props;

  return (
    <>
      <div
        style={disabled ? { backgroundColor: "#FAFAFA", opacity: 0.5 } : {}}
        className="d-flex align-items-center justify-content-between industry"
        onClick={() => radioOnClick(data)}
        role="button"
      >
        <p className="industry-name">{name}</p>
        <Radio checked={checked} borderColor="#282828" backgroundColor="#282828" disabled={disabled} />
      </div>
      <HorizontalSeparator borderWidth={1} borderColor="#EEEEEE" borderStyle="solid" />
    </>
  );
}

export default DropDownSelection;
