import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import { formatCurrency } from "../../utils";

function ServiceFeeCalculator() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateBackToHome = () => {
    navigate("/");
  };
  const [amount, setAmount] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(0);

  const onInputChange = (value) => {
    setAmount(formatCurrency(value));
  };

  const calculateFee = () => {
    setServiceFee(parseFloat(amount) * 0.02);
    setPaymentAmount(parseFloat(amount) + parseFloat(amount) * 0.02);
  };

  useEffect(() => {
    if (amount > 0) {
      calculateFee();
    } else {
      setServiceFee(0);
      setPaymentAmount(0);
    }
  }, [amount]);

  return (
    <MainLayout
      title={t("SERVICE_FEE_CALCULATOR")}
      hamburgerButton
      hasFooter
      footerText={t("BACK_TO_HOME_PAGE")}
      topButtonStyle={{
        color: "#3FBAFF",
        backgroundColor: "transparent",
      }}
      leftTopButtonIcon={<i className="fa-solid fa-arrow-left-long fa-sm me-3" />}
      footerButtonOnClick={navigateBackToHome}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <p className="service-fee-title">{t("SERVICE_FEE_CALCULATOR_TITLE")}</p>
            <div className="input-amount-container">
              <p>{t("INSERT_KOCEK_OUT_AMOUNT")}</p>
              <div className="input-amount">
                <p className="mb-0 amount me-1">RM</p>
                <input
                  className="amount"
                  type="number"
                  value={amount}
                  onChange={(e) => onInputChange(e.target.value)}
                  style={{ border: "none", outline: "none", width: "100%" }}
                  min={0}
                  pattern="^\d*(\.\d{0,2})?$"
                />
              </div>

              <p className="mt-2 stock-availability">{t("SUBJECT_TO_STOCK_AVAILABILITY")}</p>
            </div>
            <div className="calculated-amount-container">
              <div className="d-flex justify-content-between">
                <p className="service-row">{t("SERVICE_FEE_PERCENT")} (2%)</p>
                <p className="service-row">RM {serviceFee.toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0 payment-row">{t("PAYMENT_AMOUNT")}</p>
                <p className="mb-0 payment-row">RM {paymentAmount.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default ServiceFeeCalculator;
