/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useEffect } from "react";
import storage, { getLocalStorageItem, setLocalStorageItem } from "./helper";
import { DEFAULT_LANGUAGE } from "../../i18n/locales";

export const CoinContext = createContext();

function CoinContextProvider({ children }) {
  const [confirmedOrderDetails, setConfirmedOrderDetails] = useState(
    getLocalStorageItem(storage.ORDER_DETAILS_KEY, [])
  );
  const [selectedLanguage, setSelectedLanguage] = useState(getLocalStorageItem(storage.LANGUAGE_KEY, DEFAULT_LANGUAGE));
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(getLocalStorageItem(storage.SELECTED_LOCATION_KEY, null));
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    getLocalStorageItem(storage.SELECTED_PAYMENT_KEY, null)
  );

  const [selectedOnlineBankingMethod, setSelectedOnlineBankingMethod] = useState(
    getLocalStorageItem(storage.SELECTED_ONLINE_BANKING_KEY, null)
  );

  const [businessDetails, setBusinessDetails] = useState(getLocalStorageItem(storage.BUSINESS_DETAILS_KEY, null));

  const [userDetails, setuserDetails] = useState(getLocalStorageItem(storage.USER_DETAILS_KEY, null));

  const [userToken, setUserToken] = useState(getLocalStorageItem(storage.USER_TOKEN_KEY, null));

  const [kocekInTransaction, setKocekInTransaction] = useState(null);

  useEffect(() => {
    setLocalStorageItem(storage.ORDER_DETAILS_KEY, confirmedOrderDetails);
  }, [confirmedOrderDetails]);

  useEffect(() => {
    setLocalStorageItem(storage.SELECTED_LOCATION_KEY, selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    setLocalStorageItem(storage.SELECTED_PAYMENT_KEY, selectedPaymentMethod);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setLocalStorageItem(storage.SELECTED_ONLINE_BANKING_KEY, selectedOnlineBankingMethod);
  }, [selectedOnlineBankingMethod]);

  useEffect(() => {
    setLocalStorageItem(storage.BUSINESS_DETAILS_KEY, businessDetails);
  }, [businessDetails]);

  useEffect(() => {
    setLocalStorageItem(storage.USER_DETAILS_KEY, userDetails);
  }, [userDetails]);

  useEffect(() => {
    setLocalStorageItem(storage.LANGUAGE_KEY, selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    setLocalStorageItem(storage.USER_TOKEN_KEY, userToken);
  }, [userToken]);

  useEffect(() => {
    setLocalStorageItem(storage.KOCEK_IN_TRANSACTION, kocekInTransaction);
  }, [kocekInTransaction]);

  return (
    <CoinContext.Provider
      value={{
        confirmedOrderDetails,
        setConfirmedOrderDetails,
        locations,
        setLocations,
        selectedLocation,
        setSelectedLocation,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        selectedOnlineBankingMethod,
        setSelectedOnlineBankingMethod,
        businessDetails,
        setBusinessDetails,
        userDetails,
        setuserDetails,
        selectedLanguage,
        setSelectedLanguage,
        userToken,
        setUserToken,
        setKocekInTransaction,
        kocekInTransaction,
      }}
    >
      {children}
    </CoinContext.Provider>
  );
}

export default CoinContextProvider;
