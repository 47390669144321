import React from "react";
import "./Style.css";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const { t } = useTranslation();
  const {
    footerText,
    buttonOnClick,
    twoButton,
    topButtonStyle,
    bottomButtonStyle,
    topButtonText,
    bottomButtonText,
    topButtonOnClick,
    bottomButtonOnClick,
    topButtonIcon,
    leftTopButtonIcon,
    bottomButtonIcon,
    topButtonDisabled,
    bottomButtonDisabled,
    disabled,
    disabledStyle,
    disabledTopStyle,
    disabledBottomStyle,
  } = props;
  const topDefaultStyle = {
    backgroundColor: "#282828",
    color: "white",
    width: "100%",
  };
  const bottomDefaultStyle = {
    backgroundColor: "transparent",
    color: "#3FBAFF",
    fontSize: 14,
    textDecoration: "underline",
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const location = useLocation();

  return (
    <div className="text-center p-3 h-100">
      <div className="d-grid gap-2">
        <button
          onClick={twoButton ? topButtonOnClick : buttonOnClick}
          className="btn"
          type="button"
          style={
            topButtonDisabled || disabled
              ? { ...disabledStyle, ...disabledTopStyle }
              : {
                  ...topDefaultStyle,
                  ...topButtonStyle,
                }
          }
          disabled={twoButton ? topButtonDisabled : disabled}
        >
          <div className="d-flex align-items-center justify-content-center">
            {leftTopButtonIcon}
            <p className="mb-0">{twoButton ? topButtonText : footerText}</p>
            {topButtonIcon}
          </div>
        </button>
        {twoButton ? (
          <button
            onClick={bottomButtonOnClick}
            className="btn bottom-btn"
            type="submit"
            style={
              bottomButtonDisabled
                ? disabledBottomStyle
                : {
                    ...bottomDefaultStyle,
                    ...bottomButtonStyle,
                  }
            }
            disabled={bottomButtonDisabled}
          >
            <div className="d-flex align-items-center justify-content-center">
              <p className="mb-0">{bottomButtonText}</p>
              {bottomButtonIcon}
            </div>
          </button>
        ) : null}
      </div>
      {location.pathname === "/" ? (
        <p className="mb-0 note">
          <span style={{ fontWeight: "500", color: "#303030" }}>{t("NOTE")}: </span>
          {t("DISTANCE_NOTE")}
        </p>
      ) : null}
    </div>
  );
}

export default Footer;
