/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import Checkbox from "../../components/Checkbox/Checkbox";

function TransactionConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedLocation, kocekInTransaction, userToken } = useContext(CoinContext);

  const [userDetails, setUserDetails] = useState({});
  const [checked, setChecked] = useState(false);

  const incentiveAmount = useMemo(() => {
    return kocekInTransaction?.voucher_amount - kocekInTransaction?.amount;
  }, [kocekInTransaction]);

  const [linkToInsertBank, setLinkToInsertBank] = useState("/transaction-summary/confirmation/bank-details");

  useEffect(() => {
    if (!userToken) {
      navigate("/kocek-in/login");
      return;
    }

    const getUserDetails = async () => {
      try {
        const response = await Axios.get(API.KOCEK_IN_GET_CLIENT, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        if (response.data && response.data.user) {
          setUserDetails(response.data.user);
          if (response.data.user && response.data.user.client_bank_infos.length > 0) {
            setLinkToInsertBank("/transaction-summary/confirmation/bank-list");
          }
        }
      } catch (error) {
        toast.error(error);
      }
    };

    getUserDetails();
  }, [userToken, navigate, location.state]);
  const createTransaction = async () => {
    if (!checked || !location.state) return;

    try {
      const url = `${API.KOCEK_IN_CREATE_TRANSACTION}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      };
      const body = {
        storeId: kocekInTransaction.store_id,
        oriCoinAmount: kocekInTransaction.ori_coin_amount,
        secretKey: kocekInTransaction.secret_key,
        socketId: kocekInTransaction.socket_id,
        totalFee: kocekInTransaction.fee_amount,
        finalAmount: kocekInTransaction.amount,
        type: kocekInTransaction.type,
        voucher_amount: kocekInTransaction.voucher_amount,
      };

      const response = await Axios.post(url, body, { headers });
      if (response.data) {
        const receiptDetails = {
          ...body,
          receipt_no: response.data.data.taxInvoiceNumber,
          ...userDetails,
          transaction_dt: response.data.data.createdAt,
          transaction_id: response.data.data.id,
        };
        await updateDetails(response.data.data.id, receiptDetails);
      }
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  const updateDetails = async (transactionId, receiptDetails) => {
    try {
      const url = `${API.KOCEK_IN_CREATE_TRANSACTION_DETAIL_FOR_BANK}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      };
      const body = {
        transactionId,
        transactionDetail: {
          bankId: parseInt(location.state.bankId),
          bankAccountNumber: location.state.accountNo,
          bankAccountName: location.state.accountHolder,
        },
      };

      const response = await Axios.put(url, body, { headers });
      if (response.status === 200) {
        console.log("Details updated successfully");
        navigate("/transaction-summary/confirmation/receipt", {
          state: {
            receipt: receiptDetails,
            merchant: selectedLocation,
            bank: { ...body, bankName: location.state.bankName },
          },
        });
      }
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  return (
    <>
      <MainLayout
        title="Confirmation"
        backgroundColor="#f6f6f6"
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
        containerStyle={{ padding: "0px" }}
        backButton
        navbarRightButton
      >
        <div className="container-flex w-100-wh mb-2 h-100 transaction-confirmation font-12 mr-n5">
          <div className=" bg-white py-2 px-3">
            <div className="color-606060 pt-3 font-weight-bold">{t("KIOSK_INFORMATION")}</div>
            <div className="row pt-3">
              <div className="col-12">
                <div className="border-top-1 pt-3">
                  <div className="row pb-1">
                    <div className="col-1">
                      <i className="fa-solid fa-location-dot icon-location color-606060" />
                    </div>
                    <div className="col-11 gap-3">
                      <div className="color-909090 pb-1">{t("BOOTH_LOCATION")}</div>
                      <div className="color-303030 font-14 pb-1">{selectedLocation.details.store_name}</div>
                      <div className="color-606060 font-12 pb-1">{selectedLocation.details.address}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" bg-white mt-2 y-2 px-3">
            <div className="color-606060 pt-3 font-weight-bold">{t("PERSONAL_DETAILS")}</div>
            <div className="row pt-3">
              <div className="col-12">
                <div className="border-top-1 pt-3">
                  <div className="row pb-1">
                    <div className="col-12">
                      <div className="color-909090 pb-1">{t("NAME_AND_PHONE")}</div>
                      <div className="color-303030 font-14 pb-3">
                        {`${userDetails.firstName} | (${userDetails.countryCode}) ${userDetails.phone}`}
                      </div>
                      <div className="color-909090 pb-1">{t("Email")}</div>
                      <div className="color-303030 font-14 pb-3">{userDetails.email}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {kocekInTransaction?.type !== "voucher" && (
            <div className=" bg-white mt-2 y-2 px-3">
              <div className="color-606060 pt-3 font-weight-bold">BANK DETAILS</div>
              <div className="row pt-3">
                <Link to={linkToInsertBank} className="no-underline">
                  <div className="col-12">
                    <div className="border-top-1 pt-3">
                      <div className="row pb-1">
                        <div className="col-12">
                          <div className="pb-3">
                            {location.state ? (
                              <div className="col-12">
                                <div className="color-909090 pb-1">Bank Name & Account Number</div>
                                <div className="color-303030 font-14 pb-3">
                                  {`${location.state.bankName} | ${location.state.accountNo}`}
                                </div>
                                <div className="color-909090 pb-1">Account Holder&apos;s name</div>
                                <div className="color-303030 font-14 pb-3">{location.state.accountHolder}</div>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-between">
                                <div className="color-303030 font-16 color-3FBAFF no-underline">
                                  Please insert bank details
                                </div>
                                <div className="color-282828">
                                  <i className="fa-solid fa-chevron-right color-282828" />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <div className=" bg-white mt-2 y-2 px-3">
            <div className="color-606060 pt-3 font-weight-bold">{t("PAYOUT_DETAILS")}</div>
            <div className="row pt-3">
              <div className="col-12">
                <div className="">
                  <div className="row pb-1">
                    <div className="col-12">
                      <div className="border-top-1 pt-3">
                        <div className="d-flex justify-content-between">
                          <div className="color-303030 font-14">{t("KOCEK_IN_AMOUNT")}</div>
                          <div className="color-303030">RM{kocekInTransaction?.ori_coin_amount.toFixed(2)}</div>
                        </div>
                      </div>
                      <div className="color-606060 font-12 ">{t("SUBTOTAL")}</div>
                      <div className="d-flex justify-content-between pt-3">
                        <div className="color-303030">{t("SERVICE_FEE_PERCENT")}</div>
                        <div className="color-303030">- RM{kocekInTransaction?.fee_amount.toFixed(2)}</div>
                      </div>
                    </div>
                    {kocekInTransaction?.type === "voucher" ? (
                      <div className="border-bottom-1 border-top-1 mt-3">
                        <div className="d-flex justify-content-between pt-3">
                          <div className="color-303030 font-weight-bold">{t("TOTAL_KOCEK_IN_AMOUNT")}</div>
                          <div className="color-303030">RM{kocekInTransaction?.ori_coin_amount.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          <div className="color-303030 font-weight-bold">{t("VOUCHER_INCENTIVE_AMOUNT")}</div>
                          <div className="color-14A44D">+ RM{incentiveAmount.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          <div className="color-303030 font-16">{t("TOTAL_VOUCHER_AMOUNT")}</div>
                          <div className="color-303030 font-16">RM{kocekInTransaction?.voucher_amount.toFixed(2)}</div>
                        </div>
                        <div className="color-606060 font-12 ">{t("VOUCHER_TO_BE_RECEIVED")}</div>
                      </div>
                    ) : (
                      <div className="pb-3">
                        <div className="d-flex justify-content-between pt-3">
                          <div className="color-303030 font-16 ">{t("TOTAL_AMOUNT")}</div>
                          <div className="color-303030 font-16">RM{kocekInTransaction?.amount.toFixed(2)}</div>
                        </div>
                        <div className="color-606060 font-12 ">{t("AMOUNT_TO_BE_TRANSFERED")}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" bg-white mt-2 py-3 px-3">
            <div className="d-flex justify-content-start">
              <Checkbox
                containerStyle={{
                  borderColor: "#DDDDDD",
                  borderWidth: 1,
                  borderStyle: "solid",
                  width: 20,
                  height: 20,
                }}
                borderRadius={4}
                checkedStyle={{
                  backgroundColor: "#3FBAFF",
                  borderColor: "#3FBAFF",
                }}
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <div className="color-303030 font-14 padding-l-20"> {t("INFO_CONFIRM")}</div>
            </div>
          </div>
        </div>
        <div className="bg-white text-center text-lg-start mt-auto fixed-footer fixed-bottom row justify-content-center">
          <div className="d-flex justify-content-start">
            <div className="w-50 p-2 px-3">
              <div className=" d-flex justify-content-end font-12 color-909090">{t("AMOUNT_TO_BE_TRANSFERED")}</div>
              <div className="  d-flex justify-content-end p-2color-303030 font-16 font-303030">
                RM{kocekInTransaction?.amount.toFixed(2)}
              </div>
            </div>
            <div className="w-50">
              <button type="button" className="cstmbtn mb-4 enabled h-100" onClick={() => createTransaction()}>
                {t("CONFIRM")}
              </button>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default TransactionConfirmation;
