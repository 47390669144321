import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MainLayout from "../../../../layouts/MainLayout";
import "./style.css";
import { download, jompay } from "../../../../images";
import JomPayDoc from "./JomPayDoc";

function JomPay() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const totalAmount = state?.totalAmount || 0;
  const navigate = useNavigate();

  return (
    <MainLayout
      title={t("PAYMENT_TITLE")}
      backButton
      backButtonOnClick={() => navigate(-1)}
      backgroundColor="#F5F5F5"
      hasFooter
      footerText={t("VIEW_ORDER")}
      footerButtonOnClick={() => navigate("/payment-receipt")}
      navbarRightButton={
        <PDFDownloadLink
          document={<JomPayDoc amount={totalAmount.toFixed(2)} />}
          fileName="jompay_payment_instruction.pdf"
        >
          <img src={download} alt="download-icon" />
        </PDFDownloadLink>
      }
    >
      <div className="mb-2 h-100 p-0">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 p-0">
            <div className="mb-2 payment-instructions-container">
              <img src={jompay} alt="jompay_logo" />
              <p className="jompay-title">{t("JOMPAY_KTM_TITLE")}</p>
              <p className="jompay-subtitle">{t("JOMPAY_KTM_SUBTITLE")}</p>
              <div className="instructions">{t("JOMPAY_INSTRUCTIONS")(totalAmount.toFixed(2))}</div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default JomPay;
