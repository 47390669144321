import React from "react";
import { useTranslation } from "react-i18next";

function UserDetailsContainer({ client }) {
  const { t } = useTranslation();
  return (
    <div className="user-details-container">
      <p className="kocek-amount-title">
        {t("PERSONAL_DETAILS").charAt(0) + t("PERSONAL_DETAILS").substring(1).toLowerCase()}
      </p>
      <div className="personal-details">
        <div className="mb-2">
          <p className="details-title">{t("NAME_AND_PHONE")}</p>
          <p className="details-1">
            {client.receipt?.firstName || "-"} {client.receipt?.lastName || "-"}
          </p>
          <p className="details-2">
            {client.receipt?.countryCode && client.receipt?.phone
              ? `(${client.receipt?.countryCode}) ${client.receipt?.phone}`
              : "-"}
          </p>
        </div>
        <div>
          <p className="details-title">{t("EMAIL")}</p>
          <p className="details-1">{client.receipt?.email || "-"}</p>
        </div>
      </div>
      <p className="kocek-amount-title mt-3">{t("BANK_DETAILS")}</p>
      <div className="business-details">
        <div className="mb-2">
          <p className="details-title">
            {t("BANK_NAME").charAt(0) +
              t("BANK_NAME").substring(1).toLowerCase() +
              " & " +
              t("ACCOUNT_NUMBER").charAt(0) +
              t("ACCOUNT_NUMBER").substring(1).toLowerCase()}
          </p>
          <p className="details-1">{client.bank.bankName || "-"}</p>
          <p className="details-2">{client.bank.transactionDetail.bankAccountNumber || "-"}</p>
        </div>
        <div>
          <p className="details-title">{t("ACCOUNT_HOLDER")}</p>
          <p className="details-1">{client.bank.transactionDetail.bankAccountName || "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsContainer;
