import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { malaysiaFlag } from "../../../images";
import LoadingSpinner from "../../../components/LoadingSpinner";

function FormSignUp(props) {
  const {
    step,
    ic,
    password,
    icError,
    handleIcChange,
    handlePhoneNumberChange,
    handleNameChange,
    name,
    phoneNumber,
    email,
    handleEmailChange,
    address,
    handleAddressChange,
    occupation,
    openBottomSheetModal,
    selectedLanguage,
    emailError,
    phoneError,
    isCheckLoading,
    handleCheckAvailability,
    isVisible,
    isConfirmVisible,
    handlePasswordChange,
    handleConfirmedPasswordChange,
    handleVisible,
    handleConfirmedVisible,
    confirmPassword,
    passwordError,
    checkPasswordError,
    openTncModal,
    handleSubmit,
    isRegisterLoading,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderPersonalDetailsForm = () => (
    <>
      <div className="form-outline mt-4 mb-4 text-start">
        <label htmlFor="full_name">{t("kocekIn.FULL_NAME")}</label>
        <input
          value={name}
          onChange={handleNameChange}
          id="full_name"
          className="form-control mt-2"
          placeholder={t("kocekIn.ENTER_FULL_NAME")}
        />
      </div>
      <div className="form-outline mt-4 mb-4 text-start">
        <label htmlFor="nric">{t("kocekIn.NRIC")}</label>
        <input
          value={ic}
          onChange={handleIcChange}
          id="nric"
          className={`form-control mt-2 ${icError ? "border-danger error" : null}`}
        />
        {icError ? <div className="signupTitle1 text-danger mt-2">{icError}</div> : null}
      </div>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="phoneNumber">{t("kocekIn.PHONE_NUMBER")}</label>
        <div className="input-group mt-2">
          <span className="input-group-append">
            <div className="form-control border-end-0 rounded-0 rounded-start">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={malaysiaFlag} alt="flag" />
                <span className="ms-2">(+60)</span>
              </div>
            </div>
          </span>
          <input
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            id="phoneNumber"
            className={`border-start-0 form-control ${phoneError ? "border-danger error" : null}`}
            style={{ flex: 1 }}
          />
        </div>
        {phoneError ? <div className="signupTitle1 text-danger mt-2">{phoneError}</div> : null}
        <span className="signupTitle1">{t("PHONE_NUMBER_VALIDATION")}</span>
      </div>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="email">{t("kocekIn.EMAIL")}</label>
        <input
          value={email}
          onChange={handleEmailChange}
          id="email"
          className={`form-control mt-2 ${emailError ? "border-danger error" : null}`}
          placeholder="example@email.com"
        />
        {emailError ? <div className="signupTitle1 text-danger mt-2">{emailError}</div> : null}
        <span className="signupTitle1">{t("EMAIL_VALIDATION")}</span>
      </div>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="address">{t("kocekIn.HOME_ADDRESS")}</label>
        <textarea
          value={address}
          onChange={handleAddressChange}
          id="address"
          className="form-control mt-2"
          style={{ minHeight: 100 }}
        />
      </div>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="occupation_type">{t("kocekIn.OCCUPATION_TYPE")}</label>
        <div
          role="button"
          onClick={openBottomSheetModal}
          onKeyDown={openBottomSheetModal}
          className="d-flex align-items-center justify-content-between form-control py-2 mt-2"
        >
          <div
            className="border-0 bg-white w-100 industry-dropdown"
            id="dropdownMenuButton1"
            style={{ color: occupation ? "black" : "grey" }}
          >
            {occupation
              ? selectedLanguage === "en"
                ? occupation.occupation_type_name_en
                : occupation.occupation_type_name_bm
              : t("kocekIn.OCCUPATION_TYPE_PLACEHOLDER")}
          </div>
          <i className="fa-solid fa-chevron-down" style={{ color: "#909090" }} />
        </div>
      </div>

      <button
        className={`cstmbtn mt-5 mb-4 ${
          ic && name && address && phoneNumber && email && occupation && !icError && !emailError && !phoneError
            ? "enabled"
            : "disabled"
        }`}
        type="button"
        onClick={handleCheckAvailability}
        disabled={
          !ic ||
          !name ||
          !address ||
          !phoneNumber ||
          !email ||
          !occupation ||
          icError ||
          emailError ||
          phoneError ||
          isCheckLoading
        }
      >
        {isCheckLoading ? <LoadingSpinner color="#ffffff" borderWidth={2} /> : t("kocekIn.SET_PASSWORD")}
      </button>
    </>
  );

  const renderPasswordForm = () => (
    <>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="password">{t("kocekIn.PASSWORD")}</label>
        <div className="input-group mt-2">
          <input
            value={password}
            onChange={handlePasswordChange}
            type={isVisible ? null : "password"}
            id="password"
            className={`border-end-0 form-control ${passwordError ? "border-danger error" : null}`}
            style={{ flex: 1 }}
          />
          <span className="input-group-append">
            <div className="form-control border-start-0 rounded-0 rounded-end" type="button" onClick={handleVisible}>
              <i className={`fa-solid ${isVisible ? "fa-eye" : "fa-eye-slash"} fa-sm`} style={{ color: "#788494" }} />
            </div>
          </span>
        </div>
        {passwordError ? <div className="signupTitle1 text-danger mt-2">{passwordError}</div> : null}
        <span className="signupTitle1">{t("kocekIn.PASSWORD_RULE")}</span>
      </div>
      <div className="form-outline mb-4 text-start">
        <label htmlFor="confirmedPassword">{t("kocekIn.CONFIRM_PASSWORD")}</label>
        <div className="input-group mt-2">
          <input
            value={confirmPassword}
            onChange={handleConfirmedPasswordChange}
            type={isConfirmVisible ? null : "password"}
            id="confirmedPassword"
            className={`border-end-0 form-control ${checkPasswordError ? "border-danger error" : null}`}
            style={{ flex: 1 }}
          />
          <span className="input-group-append">
            <div
              className="form-control border-start-0 rounded-0 rounded-end"
              type="button"
              onClick={handleConfirmedVisible}
            >
              <i
                className={`fa-solid ${isConfirmVisible ? "fa-eye" : "fa-eye-slash"} fa-sm`}
                style={{ color: "#788494" }}
              />
            </div>
          </span>
        </div>
        {checkPasswordError ? <div className="signupTitle1 text-danger mt-2">{checkPasswordError}</div> : null}
      </div>
      <div className="mt-4 text-center">
        <span className="titletnc">{t("SIGN_UP_TERM_AND_CONDITION")}</span>
        <span onClick={openTncModal} className="titletncColor">
          {" "}
          {t("SIGN_UP_TERM_AND_CONDITION_1")}
        </span>
      </div>
      <button
        className={`cstmbtn mt-5 mb-4 ${
          password && confirmPassword && !passwordError && !checkPasswordError ? "enabled" : "disabled"
        }`}
        type="submit"
        disabled={!password || !confirmPassword || passwordError || checkPasswordError || isRegisterLoading}
      >
        {isRegisterLoading ? <LoadingSpinner color="#ffffff" borderWidth={2} /> : t("kocekIn.VERIFY_ACCOUNT")}
      </button>
    </>
  );
  return (
    <div>
      <p className="steps-title text-center mt-3">
        {step}: {step === 1 ? t("kocekIn.PERSONAL_DETAILS") : t("kocekIn.PASSWORD_SETTING")}
      </p>
      <form onSubmit={handleSubmit}>
        {step === 1 ? renderPersonalDetailsForm() : null}
        {step === 2 ? renderPasswordForm() : null}
      </form>
      <div className="kocek-sign-up text-center">
        {t("kocekIn.ALREADY_HAVE_AN_ACCOUNT")}?{" "}
        <span className="create-account" onClick={() => navigate("/kocek-in/login")}>
          {t("kocekIn.SIGN_IN")[0] + t("kocekIn.SIGN_IN").substring(1).toLowerCase()}
        </span>
      </div>
    </div>
  );
}

export default FormSignUp;
