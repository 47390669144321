import React from "react";
import Footer from "./Footer";

function ErrorMessage(props) {
  const { titleStyle, messageStyle, messageTitle, message, buttonOnClick, footerText } = props;
  return (
    <>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center">
          <p style={titleStyle}>{messageTitle}</p>
          <p style={messageStyle}>{message}</p>
        </div>
      </div>
      <footer className="text-center text-lg-start mt-auto fixed-bottom">
        <Footer footerText={footerText} buttonOnClick={buttonOnClick} />
      </footer>
    </>
  );
}

export default ErrorMessage;
