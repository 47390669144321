import React from "react";
import "./style.css";

function Radio(props) {
  const { onClick, checked, borderColor, backgroundColor, disabled } = props;
  return (
    <div
      role="button"
      onKeyDown={disabled ? null : onClick}
      className="radio"
      onClick={disabled ? null : onClick}
      style={checked ? { borderWidth: 1, borderColor } : null}
    >
      {checked ? <div className="radio-checked" style={{ backgroundColor }} /> : null}
    </div>
  );
}

export default Radio;
