import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MainLayout from "../../layouts/MainLayout";
import { downloadWhite, error, pending } from "../../images";
import PaymentStatusContainer from "./components/paymentStatusContainer";
import "./style.css";
import PaymentReceiptContainer from "./components/paymentReceiptContainer";
import UserDetailsContainer from "./components/userDetailsContainer";
import OrderInstruction from "./components/orderInstruction";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import ReceiptDoc from "./components/receiptPDF";

function Receipt() {
  const { id } = useParams();
  const [dataFetched, setDataFetched] = useState(false);
  const [status, setStatus] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const navigate = useNavigate();
  const navigateBack = () => navigate(-1);
  const navigateBackToCheckout = () => navigate("/checkout", { state: { from: "receipt" } });
  const { t } = useTranslation();

  useEffect(() => {
    const getPaymentStatus = async () => {
      try {
        const result = await Axios.get(`${API.GET_PAYMENT_DETAILS}/${id}`);
        if (result.status === 200 && result.data) {
          setStatus(result.data.payment.payment_status === "paid" ? "RECEIVED" : "FAILED");
        }
        if (result.data.paymentAuth && result.data.paymentAuth.payment_auth_trans_id) {
          setTransactionDetails({
            transactionId: result.data.paymentAuth.payment_auth_trans_id,
            paymentMethod: result.data.paymentMethod,
            order: result.data.order,
            orderDetails: result.data.orderDetails,
            client: result.data.client,
            taxInvoice: result.data.payment.payment_number,
            payment: result.data.payment,
            paymentAuth: result.data.paymentAuth,
            merchant: result.data.merchant,
          });
        } else {
          // If paymentAuth is not available, set it to null
          setTransactionDetails({
            transactionId: null,
            paymentMethod: result.data.paymentMethod,
            order: result.data.order,
            orderDetails: result.data.orderDetails,
            client: result.data.client,
            taxInvoice: result.data.payment.payment_number,
            payment: result.data.payment,
            paymentAuth: null, // Set paymentAuth to null
            merchant: result.data.merchant,
          });
        }
        setDataFetched(true);
      } catch (e) {
        console.log(e);
      }
    };

    getPaymentStatus();
  }, []);

  if (!dataFetched) {
    return null;
  }

  const { payment, client, transactionId, taxInvoice, paymentMethod, orderDetails, merchant, paymentAuth } =
    transactionDetails || {};

  const renderDownloadPDFLink = () => {
    return (
      <PDFDownloadLink
        document={
          <ReceiptDoc
            totalAmount={payment.payment_amount}
            serviceTax={payment.payment_sst_charge_amount}
            orderDetails={orderDetails}
            userDetails={client}
            status={status}
            total={payment.payment_total_amount.toFixed(2)}
            transactionId={transactionId}
            taxInvoice={taxInvoice}
            paymentDetails={payment}
            paymentAuth={paymentAuth}
            merchant={merchant}
            paymentMethod={paymentMethod}
          />
        }
        fileName={`${transactionDetails?.taxInvoice || "KOCEK-payment-pending"}.pdf`}
      >
        <img src={downloadWhite} alt="download-icon" />
      </PDFDownloadLink>
    );
  };

  const renderPaymentStatusContainer = () => {
    return (
      <PaymentStatusContainer
        icon={status === "PENDING" ? <img src={pending} alt="pending-icon" /> : <img src={error} alt="error-icon" />}
        title={status === "PENDING" ? null : t("TRANSACTION_FAILED")}
        description={status === "PENDING" ? t("TRANSACTION_PENDING_DESCRIPTION") : t("TRANSACTION_FAILED_DESCRIPTION")}
        buttonText={status === "PENDING" ? t("PENDING_BUTTON") : t("RETRY")}
        buttonOnClick={status === "PENDING" ? navigateBack : navigateBackToCheckout}
      />
    );
  };

  const renderPaymentReceiptContainer = () => {
    return (
      <PaymentReceiptContainer
        total={payment.payment_total_amount.toFixed(2)}
        totalAmount={payment.payment_amount}
        serviceTax={payment.payment_sst_charge_amount}
        status={status}
        transactionId={transactionId}
        taxInvoice={taxInvoice}
        paymentDetails={payment}
        paymentAuth={paymentAuth}
        orderDetails={orderDetails}
        merchant={merchant}
        paymentMethod={paymentMethod}
      />
    );
  };

  const renderUserDetailsContainer = () => {
    return <UserDetailsContainer client={client} />;
  };

  const renderOrderInstruction = () => {
    return <OrderInstruction />;
  };

  return (
    <MainLayout
      backButton
      navbarRightButton={renderDownloadPDFLink()}
      backgroundColor="#282828"
      navbarBackgroundColor="#282828"
      backButtonColor="#FFFFFF"
      containerStyle={{ marginBottom: 0 }}
    >
      <div className="container h-100 mb-0">
        <div className="row h-100 justify-content-center">
          <div className="col-12 col-lg-6 d-flex flex-column">
            <div className="receipt-container">
              {transactionDetails
                ? status === "PENDING" || status === "FAILED"
                  ? renderPaymentStatusContainer()
                  : null
                : null}
              {transactionDetails ? renderPaymentReceiptContainer() : null}
              {transactionDetails && (status === "PENDING" || status === "RECEIVED")
                ? renderUserDetailsContainer()
                : null}
              {transactionDetails && payment ? (
                status === "RECEIVED" ? (
                  <>
                    {renderOrderInstruction()}
                    <PDFDownloadLink
                      document={
                        <ReceiptDoc
                          totalAmount={payment.payment_amount}
                          serviceTax={payment.payment_sst_charge_amount}
                          orderDetails={orderDetails}
                          userDetails={client}
                          status={status}
                          total={payment.payment_total_amount.toFixed(2)}
                          transactionId={transactionId}
                          taxInvoice={taxInvoice}
                          paymentDetails={payment}
                          paymentAuth={paymentAuth}
                          merchant={merchant}
                          paymentMethod={paymentMethod}
                        />
                      }
                      fileName={`${transactionDetails?.taxInvoice}.pdf`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="download-receipt-button">{t("DOWNLOAD_PDF_RECEIPT")}</div>
                    </PDFDownloadLink>
                  </>
                ) : null
              ) : null}
            </div>
            <div>{t("COPYRIGHT")()}</div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Receipt;
