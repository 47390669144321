import React from "react";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../components/HorizontalSeparator";

function PaymentDetailsContainer(props) {
  const { totalAmount, serviceTax, totalInclusiveTax } = props;
  const { t } = useTranslation();
  return (
    <div className="checkout-container mb-2">
      <p className="title">{t("PAYMENT_DETAILS")}</p>
      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="details-container">
        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: 3.5 }}>
          <p className="subtitle mb-0">{t("TOTAL_WITHDRAWAL_AMOUNT")}</p>
          <p className="subtitle mb-0">{`RM${totalAmount.toFixed(2)}`}</p>
        </div>
        <p className="title2" style={{ marginBottom: 16 }}>
          {t("SUBTOTAL")}
        </p>
        <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: 16 }}>
          <p className="subtitle mb-0">{t("SERVICE_FEE_PERCENT")}</p>
          <p className="subtitle mb-0">{`RM${serviceTax.toFixed(2)}`}</p>
        </div>
      </div>
      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="details-container">
        <div className="d-flex justify-content-between align-items-center">
          <p className="total-payment mb-0">{t("TOTAL_PAYMENT")}</p>
          <p className="total-payment mb-0">{`RM${totalInclusiveTax.toFixed(2)}`}</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailsContainer;
