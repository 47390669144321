/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OpeningHours from "./components/OpeningHours";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import { CoinContext } from "../../configs/context/CoinContextProvider";

function MerchantDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedLocation } = useContext(CoinContext);
  const [openOpeningHours, setOpenOpeningHours] = useState(false);
  const [openServiceFeeDetails, setOpenServiceFeeDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleNavigation = () => {
    if (serviceChosen === "kocekin") {
      navigate("/kocek-in/login");
    } else if (serviceChosen === "kocekout") {
      navigate("/stock");
    }
  };

  const handleChangeService = (service) => {
    setServiceChosen(service);
    setIsButtonEnabled(true);
  };

  return (
    <>
      <MainLayout
        footerText={t("PRODUCT_LISTING_TITLE")}
        title={t("KOCEK_OUT_ONLINE_ORDER")}
        hasFooter
        footerButtonOnClick={toggleModal}
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
        backButton
      >
        <div className="container mb-2 h-100 merchant-detail">
          <div className="row mx-1 pb-4 pt-3 address-details">
            <div className="col-9">
              <div className="d-flex ">
                <i className="fa-solid fa-location-dot py-2 icon-location" />
                <div>{selectedLocation.details.address}</div>
              </div>
            </div>
            <div className="col-3">
              <div className="d-flex justify-content-end">{selectedLocation.details.distance.toFixed(1)} KM</div>
            </div>
          </div>
          <div
            className="row row mx-1 pb-3 pt-3 address-details"
            type="button"
            onClick={() => setOpenOpeningHours(!openOpeningHours)}
            aria-controls="kioskDetails"
            aria-expanded={openOpeningHours}
          >
            <div className="col-12">
              <div className="d-flex w-100 ">
                <span className="d-flex justify-content-start align-items-center w-100">
                  <i className="fa-regular fa-clock py-2 icon-location" />
                  <span className="opening-hours">{t("KIOSK_OPEN")}</span>
                </span>
                <i
                  className={`py-2 fa-solid text-black ${
                    openOpeningHours ? "fa-chevron-up" : "fa-chevron-down"
                  } d-flex justify-content-end align-items-start`}
                />
              </div>
              <div className={openOpeningHours ? "collapse show" : "collapse"} id="kioskDetails">
                <div className="px-4 color-606060">
                  <OpeningHours openDayDetails={selectedLocation.details.open_day} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row row mx-1 pb-3 pt-3 address-details"
            type="button"
            onClick={() => setOpenServiceFeeDetails(!openServiceFeeDetails)}
            aria-controls="kioskDetails"
            aria-expanded={openServiceFeeDetails}
          >
            <div className="col-12">
              <div className="d-flex w-100 ">
                <span className="d-flex justify-content-start align-items-center w-100">
                  <i className="fa-solid fa-money-bill icon-location" />
                  <span>{t("SERVICE_FEE_DETAILS")}</span>
                </span>
                <i
                  className={`py-2 fa-solid text-black ${
                    openServiceFeeDetails ? "fa-chevron-up" : "fa-chevron-down"
                  } d-flex justify-content-end align-items-start`}
                />
              </div>
              <div className={openServiceFeeDetails ? "collapse show" : "collapse"} id="kioskDetails">
                <div className="px-4 pt-2">
                  <div className="font-weight-bold">{t("CONVERT_COINS")}</div>
                  <div className="d-flex justify-content-between color-606060">
                    {selectedLocation.details.activeFee.feeTiers[0].feeValue > 0 ? (
                      <>
                        <span className="text-left">
                          RM {(selectedLocation.details.activeFee.feeTiers[0].minTierAmount ?? 0.01) * 100} - above
                        </span>
                        <span className="text-right">{selectedLocation.details.activeFee.feeTiers[0].feeValue}%</span>
                      </>
                    ) : (
                      <span className="text-left"> {t("FREE_OF_CHARGE")}</span>
                    )}
                  </div>
                  <div className="font-weight-bold pt-3">{t("BUY_COINS")}</div>
                  <div className="d-flex justify-content-between color-606060">
                    <span className="text-left">{t("FREE_OF_CHARGE")}</span>
                  </div>
                  <div className="pt-3 color-606060">
                    {t("WHAT_FEE")} <a href="/service-fee-calculator">{t("CALCULATE_FEE")}</a>
                  </div>
                  <div className="pt-3 color-606060" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>

      {/* Modal */}
      <div
        className={`merchant-detail modal ${showModal ? "show" : ""}`}
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className={`modal-dialog modal-bottom ${showModal ? "show" : ""}`}>
          <div className="modal-content">
            <div className="modal-header d-flex modal-title text-center flex-row">
              <span />
              <span className="modal-title text-center  color-606060">{t("STEP_TWO_TITLE")}</span>
              <span
                type="button"
                className="close text-right justify-content-"
                aria-label="Close"
                onClick={toggleModal}
              >
                <i className="fa-solid fa-x px-2" />
              </span>
            </div>
            <div className="modal-body p-3 pt-2">
              <div
                className={`rounded-2 bg-white p-3 my-1 ${
                  serviceChosen === "kocekin" ? "border-color-282828" : "border-color-909090"
                }`}
                onClick={() => handleChangeService("kocekin")}
              >
                <div className="font-weight-bold">{t("CONVERT_COINS")}</div>
                <div className="color-606060">{t("YOUR_SIMPLE_SOLUTION")}</div>
              </div>
              <div
                className={`rounded-2 bg-white p-3 my-3 ${
                  serviceChosen === "kocekout" ? "border-color-282828" : "border-color-909090"
                }`}
                onClick={() => handleChangeService("kocekout")}
              >
                <div className="font-weight-bold">{t("BUY_COINS")}</div>
                <div className="color-606060">{t("A_CONVENIENT_SOLUTION")}</div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className={`cstmbtn mb-4 ${isButtonEnabled ? "enabled" : "disabled"}`}
                  onClick={() => handleNavigation()}
                >
                  {t("CONTINUE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Backdrop */}
      {showModal && <div className="modal-backdrop fade show" />}
    </>
  );
}

export default MerchantDetail;
