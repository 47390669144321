import React from "react";
import "./style.css";

function Checkbox(props) {
  const { containerStyle, checked, checkedStyle, borderRadius, onChange } = props;
  return (
    <div className="checkboxContainer">
      <div
        role="button"
        tabIndex={0}
        onClick={onChange}
        onKeyDown={onChange}
        type="checkbox"
        className="d-flex justify-content-center align-self-center"
        style={checked ? { ...containerStyle, ...checkedStyle, borderRadius } : { ...containerStyle, borderRadius }}
      >
        {checked ? <i className="fa-solid fa-check fa-md m-auto" style={{ color: "#ffffff" }} /> : null}
      </div>
    </div>
  );
}

export default Checkbox;
