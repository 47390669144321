import React from "react";

function Circle({ backgroundColor }) {
  const defaultStyle = {
    borderRadius: 50,
    backgroundColor: "black",
    padding: 10,
  };
  return <div style={backgroundColor ? { ...defaultStyle, backgroundColor } : { ...defaultStyle }} />;
}

export default Circle;
