/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import { CoinContext } from "../../configs/context/CoinContextProvider";

function TransactionBankSaved() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userToken } = useContext(CoinContext);

  // get banks when load
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const getBanks = async () => {
    try {
      const response = await Axios.get(API.KOCEK_IN_BANKS, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.data && response.data.banks) {
        return response.data.banks;
      }
      throw new Error("No banks data found");
    } catch (error) {
      toast.error("Failed to get banks data");
      return null;
    }
  };

  const getUserDetails = async () => {
    try {
      const banksProviders = await getBanks();
      if (banksProviders.length > 0) {
        const response = await Axios.get(API.KOCEK_IN_GET_CLIENT, {
          headers: { Authorization: `Bearer ${userToken}` },
        });
        if (response.data && response.data.user) {
          const enhancedBankInfos = response.data.user.client_bank_infos.map((bankInfo) => ({
            ...bankInfo,
            bankName: getBankNameById(bankInfo.bankId, banksProviders),
          }));
          setBanks(enhancedBankInfos);
        }
      }
    } catch (error) {
      toast.error("Failed to get user details");
    }
  };

  useEffect(() => {
    if (!userToken) {
      navigate("/kocek-in/login");
      return;
    }
    getUserDetails();
  }, []);

  const getBankNameById = (bankId, banksProviders) => {
    bankId = parseInt(bankId);
    const bank = banksProviders.find((bank) => bank.id === bankId);
    return bank ? bank.name : "Bank not found";
  };

  const handleEditClick = (bank) => {
    navigate("/transaction-summary/confirmation/bank-details", {
      state: {
        data: {
          addNew: false,
          isEdit: true,
          bank: {
            bankId: bank.bankId,
            bankName: bank.bankName,
            accountNo: bank.bankNumber,
            accountHolder: bank.holderName,
          },
        },
      },
    });
  };

  return (
    <>
      <MainLayout
        title="Add Bank Account"
        backgroundColor="#ffffff"
        backButton
        disabledStyle={{
          backgroundColor: "#F5F5F5",
          color: "#30303080",
          borderColor: "#F5F5F5",
          width: "100%",
        }}
        containerStyle={{ padding: "0px" }}
        footerText={t("CONTINUE")}
        hasFooter
        disabled={selectedBank === null}
        footerButtonOnClick={() => {
          if (selectedBank) {
            navigate("/transaction-summary/confirmation", {
              state: {
                bankName: selectedBank.bankName,
                bankId: selectedBank.bankId,
                accountNo: selectedBank.bankNumber,
                accountHolder: selectedBank.holderName,
              },
            });
          }
        }}
      >
        <div className="container-flex w-100-wh mb-2 h-100 font-16">
          <form className="mb-2">
            <div className="form-outline mt-4 mb-2 px-2">
              <div className="font-14 color-606060 pb-2">Please select your bank account for transferring process</div>
              {banks.length > 0 &&
                banks.map((bank) => (
                  <div key={bank.id}>
                    <div className="d-flex justify-content-start p-2 pt-3">
                      <input
                        type="radio"
                        checked={selectedBank === bank}
                        onChange={() => setSelectedBank(bank)}
                        style={{
                          borderColor: "#3FBAFF",
                          backgroundColor: selectedBank === bank ? "#3FBAFF" : "transparent",
                        }}
                      />
                      <div className="w-100">
                        <div className="font-12 color-909090 px-2">Bank name & Account number</div>
                        <div className="font-14 border-bottom-gray px-2">
                          {bank.bankName} | {bank.bankNumber}
                        </div>
                      </div>
                      <div
                        className="font-14 color-3FBAFF px-2"
                        onClick={() => handleEditClick(bank)}
                        style={{ cursor: "pointer" }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                ))}
              {banks.length < 1 && <div className="font-14 color-606060 p-3">You have no bank account saved.</div>}
            </div>
          </form>
          {banks.length < 3 && (
            <Link
              to={{
                pathname: "/transaction-summary/confirmation/bank-details",
                state: { addNew: true },
              }}
            >
              <div className="font-14 backHomePage px-4">Add Bank Account</div>
            </Link>
          )}
          {banks.length > 3 && (
            <div className="font-12  warning-bank m-3 p-3">
              <i className="fa fa-info-circle px-1" aria-hidden="true" />
              Your saved bank account have exceed limit allowed. If you wish to add more please remove one of it.
            </div>
          )}
        </div>
      </MainLayout>
    </>
  );
}

export default TransactionBankSaved;
