import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import logo1 from "../../../images/kocek_black_logo.png";
import { errorpng, successpng } from "../../../images";

const STATUS_TEXT = {
  RECEIVED: {
    icon: <Image src={successpng} style={{ width: 14, marginRight: 5 }} />,
    textStatus: "RECEIVED",
    textColor: "#39B55D",
    backgroundColor: "#E9FFF9",
  },
  PENDING: {
    textStatus: "PENDING_PAYMENT",
    textColor: "#FFBF00",
    backgroundColor: "#F9D42C1A",
  },
  FAILED: {
    icon: <Image src={errorpng} style={{ width: 14, marginRight: 5 }} />,
    textStatus: "FAILED",
    textColor: "#E15241",
    backgroundColor: "#F8DBDB",
  },
};

const getStatusText = (status) => {
  const data = STATUS_TEXT[status];
  return !data ? {} : data;
};

export const getPaymentMethodText = (paymentMethod) => {
  let paymentMethodText = "";

  switch (paymentMethod) {
    case "fpx":
      paymentMethodText = "Online Banking";
      break;
    case "mypay":
      paymentMethodText = "MyPay";
      break;
    case "card":
      paymentMethodText = "Credit/ Debit Card";
      break;
    case "jompay":
      paymentMethodText = "Kocek Teller Machine - ATM JomPAY";
      break;
    default:
      break;
  }

  return paymentMethodText;
};

function ReceiptDoc({
  totalAmount,
  serviceTax,
  orderDetails,
  userDetails,
  status,
  total,
  transactionId,
  taxInvoice,
  paymentDetails,
  paymentAuth,
  merchant,
  paymentMethod,
}) {
  const { t } = useTranslation();
  const statusText = getStatusText(status);
  return (
    <Document>
      <Page size={[595.28]} style={styles.page}>
        <View style={{ position: "relative" }}>
          <View style={{ padding: 20, backgroundColor: "white" }}>
            <View style={{ marginBottom: 15 }}>
              <Image src={logo1} style={styles.image} />
              <View style={styles.receiptAmountContainer}>
                <Text style={styles.receiptAmount}>RM{total}</Text>
                <View
                  style={
                    statusText.textColor
                      ? {
                          ...styles.chip,
                          backgroundColor: statusText.backgroundColor,
                          borderColor: statusText.textColor,
                          borderWidth: 1,
                        }
                      : {
                          ...styles.chip,
                          backgroundColor: statusText.backgroundColor,
                        }
                  }
                >
                  {statusText.icon}
                  <Text
                    style={{
                      ...styles.chipText,
                      color: statusText.textColor || "#ffffff",
                    }}
                  >
                    {t(statusText.textStatus)}
                  </Text>
                </View>
              </View>

              {status === "FAILED" || status === "RECEIVED" ? (
                <Text style={{ ...styles.subtotal, marginLeft: 0 }}>
                  {moment(paymentDetails.payment_created_on).format("D MMM YYYY, h:mma")}
                </Text>
              ) : null}
            </View>
            {status !== "FAILED" ? (
              <>
                {orderDetails.map((item, index) => {
                  return (
                    <View
                      style={
                        index === orderDetails.length - 1
                          ? { ...styles.orderContainer, borderBottom: "none" }
                          : styles.orderContainer
                      }
                    >
                      <View style={styles.flexContainer}>
                        <Text style={styles.orderDetailsNumber}>#{index + 1}</Text>
                        <Text style={styles.orderDetailsNumber}>
                          {item.product_quantity} {t("PACK")}
                        </Text>
                      </View>
                      <View style={[styles.flexContainer, { marginTop: 10 }]}>
                        <View
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={styles.orderName}>{item.product_name}</Text>
                          {item.product_remark ? (
                            <Text style={styles.remark}>
                              • {item.product_remark === "Old" ? t("OLD_COIN") : t("NEW_COIN")}
                            </Text>
                          ) : null}
                        </View>

                        <Text style={styles.orderName}>RM{parseFloat(item.product_total_amount).toFixed(2)}</Text>
                      </View>
                    </View>
                  );
                })}
                <View
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: "#CCCCCC",
                    borderBottomStyle: "solid",
                    marginBottom: 15,
                  }}
                />
                <View
                  style={{
                    ...styles.flexContainer,
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.kocekAmountTitle}>{t("TOTAL_WITHDRAWAL_AMOUNT")}</Text>
                    <Text style={styles.subtotal}>{`(${t("SUBTOTAL")})`}</Text>
                  </View>
                  <Text style={styles.kocekAmount}>
                    RM
                    {paymentDetails?.payment_amount?.toFixed(2) || totalAmount.toFixed(2)}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.flexContainer,
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Text style={styles.kocekAmountTitle}>{t("SERVICE_FEE_PERCENT")}</Text>
                  <Text style={styles.kocekAmount}>
                    RM
                    {paymentDetails?.payment_sst_charge_amount?.toFixed(2) || serviceTax.toFixed(2)}
                  </Text>
                </View>
              </>
            ) : null}
            <View
              style={{
                ...styles.flexContainer,
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Text style={styles.amountPaid}>{t("TOTAL_PAYMENT_PAID")}</Text>
              <Text style={styles.amountPaid}>RM{paymentAuth?.payment_total_amount?.toFixed(2) || total}</Text>
            </View>
            {status !== "PENDING" ? (
              <>
                <View
                  style={{
                    ...styles.flexContainer,
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Text style={styles.amountPaid}>{t("TAX_INVOICE_NO")}</Text>
                  <Text style={styles.amountPaid}>{taxInvoice || "101607"}</Text>
                </View>
                <View
                  style={{
                    ...styles.flexContainer,
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Text style={styles.amountPaid}>{t("TRANSACTION_ID")}</Text>
                  <Text style={styles.amountPaid}>{transactionId || "N/A"}</Text>
                </View>
              </>
            ) : null}
            {status !== "FAILED" ? (
              <View style={{ marginTop: 20, marginBottom: 20 }}>
                <Text style={styles.amountPaid}>
                  {t("ORDER_COLLECTION_LOCATION")}: {merchant.store_name}, {merchant.address}
                </Text>
              </View>
            ) : (
              <View style={[styles.flexContainer, { alignItems: "center", marginTop: 10, marginBottom: 20 }]}>
                <Text style={styles.amountPaid}>
                  {t("PAYMENT_METHOD").charAt(0) + t("PAYMENT_METHOD").substring(1).toLowerCase()}
                </Text>
                <Text style={styles.amountPaid}>{getPaymentMethodText(paymentMethod.payment_method_type)}</Text>
              </View>
            )}
          </View>
          <View
            style={{
              position: "absolute",
              left: 0,
              bottom: -10,
              width: "100%",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {[...Array(10)].map(() => {
                return (
                  <View
                    style={{
                      borderRadius: 50,
                      backgroundColor: "#282828",
                      padding: 10,
                    }}
                  />
                );
              })}
            </View>
          </View>
        </View>
        {status === "PENDING" || status === "RECEIVED" ? (
          <View style={{ marginTop: 50, padding: 20, backgroundColor: "white" }}>
            <Text style={styles.kocekAmountTitle}>
              {t("PERSONAL_DETAILS").charAt(0) + t("PERSONAL_DETAILS").substring(1).toLowerCase()}
            </Text>
            <View style={styles.personalDetails}>
              <View style={{ marginBottom: 10 }}>
                <Text style={styles.detailsTitle}>{t("NAME_AND_PHONE")}</Text>
                <Text style={styles.details1}>{userDetails?.client_name || "-"}</Text>
                <Text style={styles.details2}>
                  {userDetails?.client_phone_prefix && userDetails?.client_phone
                    ? `(${userDetails?.client_phone_prefix}) ${userDetails?.client_phone}`
                    : "-"}
                </Text>
              </View>
              <View>
                <Text style={styles.detailsTitle}>{t("EMAIL")}</Text>
                <Text style={styles.details1}>{userDetails?.client_email || "-"}</Text>
              </View>
            </View>
            <Text style={[styles.kocekAmountTitle, { marginTop: 15 }]}>
              {t("BUSINESS_DETAILS").charAt(0) + t("BUSINESS_DETAILS").substring(1).toLowerCase()}
            </Text>
            <View>
              <View style={{ marginBottom: 10 }}>
                <Text style={styles.detailsTitle}>{t("BUSINESS_NAME")}</Text>
                <Text style={styles.details1}>{userDetails?.client_company_name || "-"}</Text>
              </View>
              <View>
                <Text style={styles.detailsTitle}>{t("BUSINESS_INDUSTRY")}</Text>
                <Text style={styles.details1}>{userDetails?.client_company_industry || "-"}</Text>
              </View>
            </View>
          </View>
        ) : null}
        {status === "RECEIVED" ? (
          <View style={{ marginTop: 50, padding: 20, backgroundColor: "white" }}>
            <Text style={styles.details1}>{t("WHATS_NEXT")}?</Text>
            <Text style={[styles.details2, { marginTop: 10 }]}>{t("ORDER_PROCESSED")}</Text>
            <View style={{ marginTop: 15 }}>{t("ORDER_INSTRUCTION_PDF")()}</View>
          </View>
        ) : null}
        <View style={{ marginTop: 30, marginBottom: 20 }}>{t("COPYRIGHT_PDF")()}</View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#282828",
  },
  image: {
    width: 68,
    height: "auto",
    marginBottom: 10,
  },
  receiptAmountContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  receiptAmount: {
    fontWeight: "900",
    fontSize: 24,
  },
  chip: {
    borderRadius: 20,
    padding: 8,
    display: "flex",
    flexDirection: "row",
    maxWidth: 120,
    backgroundColor: "#E9FFF9",
    alignItems: "center",
  },
  chipText: {
    textAlign: "center",
    fontSize: 10,
    fontWeight: "500",
    color: "#39B55D",
    marginBottom: 0,
  },
  orderContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#CCCCCC",
    borderBottomStyle: "dashed",
  },
  orderDetailsNumber: {
    color: "#909090",
    fontSize: 10,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  orderName: {
    color: "#606060",
    fontSize: 14,
  },
  remark: {
    color: "#606060",
    fontSize: 12,
    marginLeft: 10,
  },
  kocekAmountTitle: {
    color: "#606060",
    fontSize: 12,
  },
  kocekAmount: {
    color: "#606060",
    fontSize: 12,
    fontWeight: "500",
  },
  subtotal: {
    fontSize: 12,
    color: "#909090",
    marginLeft: 5,
  },
  amountPaid: {
    fontSize: 14,
    color: "#303030",
  },
  personalDetails: {
    borderBottomColor: "#CCCCCC",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingBottom: 20,
  },
  detailsTitle: {
    color: "#909090",
    fontSize: 10,
    marginTop: 10,
  },
  details1: {
    color: "#303030",
    fontSize: 14,
    marginTop: 5,
  },
  details2: {
    color: "#606060",
    fontSize: 12,
    marginTop: 3,
  },
});

export default ReceiptDoc;
