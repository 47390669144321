import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import { jompaypng } from "../../../../images";

function JomPayDoc({ amount }) {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={jompaypng} style={styles.image} />
          <Text style={styles.jompay_title}>{t("JOMPAY_KTM_TITLE")}</Text>
          <Text style={styles.jompay_subtitle}>{t("JOMPAY_KTM_SUBTITLE")}</Text>
          <View style={styles.instruction}>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>1.</Text>
              <Text style={styles.list}>{t("JOMPAY_LIST_1")}</Text>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>2.</Text>
              <Text style={styles.list}>{t("JOMPAY_LIST_2")}</Text>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>3.</Text>
              <Text style={styles.list}>{t("JOMPAY_LIST_3")}</Text>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>4.</Text>
              <Text style={styles.list}>{t("JOMPAY_LIST_4")}</Text>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>5.</Text>
              <View>
                <Text style={styles.list}>{t("JOMPAY_LIST_5")}</Text>
                <Text style={styles.highlightedText}>631796</Text>
              </View>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>6.</Text>
              <View>
                <Text style={styles.list}>{t("JOMPAY_LIST_6")}</Text>
                <Text style={styles.highlightedText}>101306202301</Text>
              </View>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>7.</Text>
              <View>
                <Text style={styles.list}>{t("JOMPAY_LIST_7")}</Text>
                <Text style={styles.highlightedText}>{`RM${amount}`}</Text>
              </View>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>8.</Text>
              <Text style={styles.list}>{t("JOMPAY_LIST_8")}</Text>
            </View>
            <View style={styles.list_container}>
              <Text style={{ ...styles.list, marginHorizontal: 8 }}>9.</Text>
              <Text style={{ ...styles.list, flex: 1 }}>{t("JOMPAY_LIST_9")}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 16,
  },
  image: {
    width: 40,
    height: 40,
  },
  jompay_title: {
    fontWeight: "500",
    fontSize: 14,
    color: "#303030",
    marginTop: 16,
    marginBottom: 0,
  },
  jompay_subtitle: {
    fontSize: 12,
    color: "#606060",
    marginTop: 5,
    marginBottom: 0,
  },
  instruction: {
    marginTop: 16,
  },
  list_container: {
    flexDirection: "row",
    marginBottom: 8,
    paddingRight: 8,
  },
  list: {
    fontSize: 14,
    color: "#303030",
    marginBottom: 8,
    paddingLeft: 8,
  },
  highlightedText: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginTop: 4,
    backgroundColor: "#dddddd",
    alignSelf: "flex-start",
    marginLeft: 8,
  },
});

export default JomPayDoc;
