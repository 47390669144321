/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import BottomSheetModal from "../../components/BottomSheet";
import TwoButtonFooter from "../../components/twoButtonFooter";
import API from "../../configs/API";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import Axios from "../../configs/serviceProvider/Axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
import ProductContainer from "./productContainer";

function StockList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const { confirmedOrderDetails, setConfirmedOrderDetails, selectedLocation, selectedLanguage } =
    useContext(CoinContext);
  const [totalAmount, setTotalAmount] = useState(0);
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [errorFetched, setErrorFetched] = useState(false);
  const dataFetched = useRef(false);

  const loadProduct = async () => {
    setErrorFetched(false);
    setIsLoading(true);
    try {
      const url = `${API.GET_PRODUCT_LIST}?merchant_id=${selectedLocation.details.id}`;
      await Axios.get(url).then((result) => {
        if (result.status === 200 && result.data) {
          const { products } = result.data;
          const arr = [...products];
          if (products.length > 0) {
            for (const i in products) {
              const index = confirmedOrderDetails.findIndex((item) => item.product_id === products[i].product_id);
              if (confirmedOrderDetails.length > 0) {
                if (index > -1) {
                  arr[i] = {
                    ...arr[i],
                    quantity: confirmedOrderDetails[index].quantity,
                  };
                } else {
                  arr[i] = { ...arr[i], quantity: 0 };
                }
              } else {
                arr[i] = { ...arr[i], quantity: 0 };
              }
            }

            if (confirmedOrderDetails && confirmedOrderDetails.length > 0) {
              let total = 0;
              for (const i of confirmedOrderDetails) {
                total += i.totalAmount;
              }
              setTotalAmount(total);
            }
            setProductList(arr);
            setOrderDetails(confirmedOrderDetails);
            setErrorFetched(false);
          } else {
            setErrorFetched(true);
          }
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorFetched(true);
    }
  };

  useEffect(() => {
    if (dataFetched.current === false) {
      loadProduct();
    }
    return () => {
      dataFetched.current = true;
    };
  }, []);

  useEffect(() => {
    if (productList && productList.length > 0 && orderDetails && orderDetails.length > 0) {
      let total = 0;
      for (const x of orderDetails) {
        total += x.quantity * x.product_price;
      }
      setTotalAmount(total);
    } else {
      setTotalAmount(0);
    }
  }, [orderDetails]);

  const subtract = (product) => {
    if (orderDetails.some((item) => item.product_id === product.product_id)) {
      if (orderDetails.find((item) => item.product_id === product.product_id).quantity > 1) {
        const arr = [...orderDetails];
        const index = orderDetails.findIndex((item) => item.product_id === product.product_id);
        arr[index] = {
          ...arr[index],
          quantity: arr[index].quantity - 1,
          totalAmount: arr[index].totalAmount - product.product_price,
        };
        setOrderDetails(arr);
      } else {
        const arr = [...orderDetails];
        const index = orderDetails.findIndex((item) => item.product_id === product.product_id);
        arr.splice(index, 1);
        setOrderDetails(arr);
      }
      const productArr = [...productList];
      const i = productList.findIndex((item) => item.product_id === product.product_id);
      productArr[i] = {
        ...productArr[i],
        quantity: productArr[i].quantity - 1,
      };
      setProductList(productArr);
    }
  };

  const add = (product) => {
    const productArr = [...productList];
    const i = productList.findIndex((item) => item.product_id === product.product_id);
    if (orderDetails.some((item) => item.product_id === product.product_id)) {
      const arr = [...orderDetails];
      const index = orderDetails.findIndex((item) => item.product_id === product.product_id);
      if (arr[index].quantity < productArr[i].stocks) {
        arr[index] = {
          ...arr[index],
          quantity: arr[index].quantity + 1,
          totalAmount: arr[index].totalAmount + product.product_price,
        };
        setOrderDetails(arr);
      }
    } else {
      setOrderDetails([...orderDetails, { ...product, quantity: 1, totalAmount: product.product_price }]);
    }
    if (productArr[i].quantity < productArr[i].stocks) {
      productArr[i] = {
        ...productArr[i],
        quantity: productArr[i].quantity + 1,
      };
      setProductList(productArr);
    }
  };

  const onChangeQuantity = (number, product) => {
    const arr = [...orderDetails];
    const productArr = [...productList];
    const index = orderDetails.findIndex((item) => item.product_id === product.product_id);
    const i = productList.findIndex((item) => item.product_id === product.product_id);
    if (parseInt(number) === 0) {
      if (index > -1) {
        arr.splice(index, 1);
        setOrderDetails(arr);
      }
      productArr[i] = { ...productArr[i], quantity: 0 };
      setProductList(productArr);
    } else if (parseInt(number) > 0) {
      if (parseInt(number) > product.stocks) {
        productArr[i] = { ...productArr[i], quantity: product.stocks };
        setProductList(productArr);
        if (index > -1) {
          arr[index] = {
            ...arr[index],
            quantity: product.stocks,
            totalAmount: product.stocks * product.product_price,
          };
          setOrderDetails(arr);
        } else {
          setOrderDetails([
            ...orderDetails,
            {
              ...product,
              quantity: product.stocks,
              totalAmount: product.stocks * product.product_price,
            },
          ]);
        }
      } else {
        productArr[i] = { ...productArr[i], quantity: parseInt(number) };
        setProductList(productArr);
        if (index > -1) {
          arr[index] = {
            ...arr[index],
            quantity: parseInt(number),
            totalAmount: parseInt(number) * product.product_price,
          };
          setOrderDetails(arr);
        } else {
          setOrderDetails([
            ...orderDetails,
            {
              ...product,
              quantity: number,
              totalAmount: number * product.product_price,
            },
          ]);
        }
      }
    } else {
      if (index > -1) {
        arr.splice(index, 1);
        setOrderDetails(arr);
      }
      productArr[i] = { ...productArr[i], quantity: 0 };
      setProductList(productArr);
    }
  };

  const openModal = () => setOpenBottomSheet(true);
  const closeModal = () => setOpenBottomSheet(false);

  const onClickConfirm = () => {
    closeModal();
    setConfirmedOrderDetails(orderDetails);
    navigate("/login");
  };

  const rightButton = (title, disabled) => (
    <div className="d-flex h-100 align-items-center justify-content-center px-1">
      <p style={disabled ? { color: "#30303080" } : null} className="place-order-button-text mb-0">
        {title}
      </p>
      <i
        style={disabled ? { color: "#30303080" } : null}
        className="place-order-button-text fa-solid fa-arrow-right-long ms-3"
      />
    </div>
  );

  const leftButton = (total_amount, buttonOnClick, icon) => (
    <>
      <div className="row">
        <div className="col-10 col-sm-11 p-0 pe-1">
          <span className="total-title ">{t("SUBTOTAL")}</span>
        </div>
        <div className="col-2 col-sm-1 ps-1">
          <i className="visually-hidden fa-solid fa-chevron-up fa-2xs" />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-10 col-sm-11 p-0 pe-1">
          <span className="subtotal-amount">{`RM${total_amount.toFixed(2)}`}</span>
        </div>
        <div className="col-2 col-sm-1 ps-1 text-center">
          <i type="button" onClick={buttonOnClick} className={`fa-solid ${icon} fa-2xs`} />
        </div>
      </div>
    </>
  );
  return (
    <>
      <BottomSheetModal className="stock-bottom-sheet" openModal={openBottomSheet} onDismiss={closeModal}>
        <div className="px-2 order-details-container border-bottom">
          <div className="d-flex align-items-center justify-content-center position-relative mt-2 pb-3">
            <span className="text-center">{t("ORDER_DETAILS")}</span>
            <i
              type="button"
              onClick={closeModal}
              className="fa-solid fa-xmark close-bottom-sheet"
              style={{ color: "#000000" }}
            />
          </div>
        </div>
        <div className="px-2 mt-3">
          {productList && productList.length > 0 && orderDetails && orderDetails.length > 0 ? (
            <>
              {orderDetails.map((item, index) => {
                return (
                  <div key={index} className="row mx-2 border-bottom py-2 mb-2">
                    <div className="col-2 text-center">
                      <span className="order-quantity border rounded text-center">{`x${item.quantity}`}</span>
                    </div>
                    <div className="col-7">
                      <div className="d-flex align-items-center">
                        <p className="coin-name">{item.product_name}</p>
                        {item.product_remark ? (
                          <p
                            className={`${
                              item.product_remark === "Old" ? "order-coin-status-old" : "order-coin-status-new"
                            } rounded justify-content-center d-flex align-items-center`}
                          >
                            {item.product_remark === "Old" ? t("OLD_COIN") : t("NEW_COIN")}
                          </p>
                        ) : null}
                      </div>
                      <p className="coin-min">{`RM${item.product_price}.00/${t("PACK")}`}</p>
                    </div>
                    <div className="col-3 text-end pe-0">
                      <p className="total-coin-amount">{`RM${item.totalAmount.toFixed(2)}`}</p>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex mx-2 total-container justify-content-between">
                <p className="total-items">{`${orderDetails.length} ${
                  orderDetails.length <= 1 && selectedLanguage === "en" ? t("ITEM").replace("s", "") : t("ITEM")
                }`}</p>
                <div className="text-end">
                  <p className="total-amount">{`RM${totalAmount.toFixed(2)}`}</p>
                  <p className="service-fee">*{t("SERVICE_FEE")}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="empty-order-container text-center">{t("NO_RECORD_IN_ORDER_DETAILS")}</div>
          )}
          <TwoButtonFooter
            renderLeftButton={leftButton(totalAmount, closeModal, "fa-chevron-down")}
            onClickRightButton={onClickConfirm}
            renderRightButton={rightButton(t("CONFIRM"), orderDetails.length === 0)}
            rightButtonCustomStyle={{ backgroundColor: "#282828" }}
            leftButtonCustomStyle={{ backgroundColor: "white" }}
            fullWidth
            disabledRightButton={orderDetails?.length === 0 || productList?.length === 0}
            nonSticky
            rightDisabledStyle={{ backgroundColor: "#F5F5F5" }}
          />
        </div>
      </BottomSheetModal>
      <MainLayout hasFooter={false} title={selectedLocation.details.store_name} backButton>
        <div className="container mb-2 h-100">
          <div className="pt-2">
            <div className="text-center">
              <p className="main-title">{t("PRODUCT_LISTING_TITLE")}</p>
            </div>
          </div>
          {productList && productList.length > 0 ? (
            <>
              <ProductContainer
                productList={productList}
                add={add}
                subtract={subtract}
                onChangeQuantity={onChangeQuantity}
              />
              <TwoButtonFooter
                renderLeftButton={leftButton(totalAmount, openModal, "fa-chevron-up")}
                onClickRightButton={openModal}
                renderRightButton={rightButton(t("PLACE_ORDER"))}
                rightButtonCustomStyle={{ backgroundColor: "#282828" }}
                leftButtonCustomStyle={{ backgroundColor: "white" }}
              />
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <div className="mt-4 row justify-content-center">
                    <div className="col-12 col-lg-6">
                      <div className="mt-4 px-2 d-flex justify-content-between">
                        <p className="subtitle">{t("COIN_DESCRIPTION")}</p>
                        <p className="subtitle">{t("QUANTITY")}</p>
                      </div>
                      <div className="text-center mt-5">
                        <LoadingSpinner color="#C4C4C4" borderWidth={2} />
                      </div>
                    </div>
                  </div>
                  <TwoButtonFooter
                    renderLeftButton={leftButton(totalAmount, openModal, "fa-chevron-up")}
                    onClickRightButton={openModal}
                    renderRightButton={rightButton(t("PLACE_ORDER"))}
                    rightButtonCustomStyle={{ backgroundColor: "#282828" }}
                    leftButtonCustomStyle={{ backgroundColor: "white" }}
                  />
                </>
              ) : null}
              {errorFetched ? (
                <ErrorMessage
                  messageTitle={t("SOMETHING_WENT_WRONG")}
                  message={t("FETCH_FAIL")}
                  titleStyle={{
                    color: "#303030",
                    fontSize: 20,
                    fontWeight: "500",
                  }}
                  messageStyle={{
                    color: "#606060",
                    fontSize: 14,
                  }}
                  buttonOnClick={loadProduct}
                  footerText={t("RETRY")}
                />
              ) : null}
            </>
          )}
        </div>
      </MainLayout>
    </>
  );
}

export default StockList;
