import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Axios from "../../configs/serviceProvider/Axios";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import MainLayout from "../../layouts/MainLayout";
import API from "../../configs/API";
import LoadingSpinner from "../../components/LoadingSpinner";
import { secureCodeImg } from "../../images";
import "./style.css";

function insertCode() {
  const { userToken, selectedLocation, setKocekInTransaction } = useContext(CoinContext);
  const { t } = useTranslation();
  const [secureCode, setSecureCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      setSecureCode((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });

      if (index < secureCode.length - 1) {
        inputRefs.current[index + 1].focus();
      } else if (index === secureCode.length - 1) {
        inputRefs.current[index].blur();
      }
    }
  };

  // const handleInputPaste = (index, event) => {
  //   const { value } = event.target;
  //   const otpArray = Array.from(value.slice(0, 6)); // Extract the first 6 characters
  //   const newValues = [...otpValues];

  //   otpArray.forEach((digit, i) => {
  //     if (/^\d*$/.test(digit)) {
  //       newValues[index + i] = digit;
  //     }
  //   });

  //   setOTPValues(newValues);
  // };

  const handleInputKeyUp = (index, event) => {
    if (event.key === "Backspace" && !secureCode[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const verifySecuredCode = async () => {
    try {
      const url = `${API.KOCEK_IN_TRANSACTION_CODE}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      };
      const body = {
        transactionCode: secureCode.join(""),
      };

      await Axios.post(url, body, { headers }).then((response) => {
        if (response.status === 200) {
          setLoading(true);
          setTimeout(() => {
            setLoading(false);
            setKocekInTransaction(response.data.findTransaction);
            navigate("/transaction-summary");
          }, 3000);
        }
      });
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  useEffect(() => {
    if (secureCode.every((value) => value !== "")) {
      verifySecuredCode();
    }
  }, [secureCode]);

  return (
    <MainLayout hasFooter={false} backButton title={selectedLocation?.details?.store_name || ""}>
      <div className="main container">
        <div className="iconContainer mt-4 mb-4">
          <img src={secureCodeImg} style={{ minWidth: 94 }} className="App-logo" alt="logo" width="30%" height="auto" />
        </div>
        <div className="text-center">
          <h5>{t("INSERT_CODE")}</h5>
          <span className="font-14"> {t("KEY_IN_CODE")} </span>
          <div className="inputs d-flex flex-row justify-content-center mt-2">
            {secureCode.map((value, index) => (
              <input
                className="m-2 text-center form-control rounded"
                key={index}
                inputMode="numeric"
                value={value}
                maxLength={1}
                onChange={(e) => handleInputChange(index, e)}
                // onPaste={(e) => handleInputPaste(index, e)}
                onKeyUp={(e) => handleInputKeyUp(index, e)}
                ref={(ref) => (inputRefs.current[index] = ref)}
              />
            ))}
          </div>
          {loading && (
            <div className="loading">
              <LoadingSpinner color="#909090" borderWidth={2} />{" "}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default insertCode;
