import axios from "axios";
import { toast } from "react-toastify";
import { API_ID, API_KEY, PRTNRID } from "../ENV";
import API from "../API";

const Axios = axios.create({
  headers: {
    Accept: "application/json,application/x-www-form-urlencoded,text/plain,*/*",
    "Content-Type": "application/json;charset=utf-8",
  },
});

Axios.interceptors.request.use(async (config) => {
  const { headers } = config;
  try {
    const token = await axios.post(
      API.GENERATE_TOKEN,
      {
        apiId: API_ID,
        apiKey: API_KEY,
      },
      {
        headers: {
          ...headers,
          prtnrId: PRTNRID,
        },
      }
    );
    config.headers = {
      Authorization: `${token.data.token_type} ${token.data.access_token}`,
      ...headers,
    };
  } catch {}
  return config;
});

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const stateCode = error.response.status;
    if (stateCode === 401) {
      let message = error.response.data.message || error.response.data.error || "Auth Failed";
      message = `error: ${message}`;
      toast.error(message);
    }
    return Promise.reject(error);
  }
);

export default Axios;
