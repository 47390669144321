import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLayout from "../../../layouts/MainLayout";
import "./style.css";
import { isValidIDNumber } from "../../../utils";
import Axios from "../../../configs/serviceProvider/Axios";
import API from "../../../configs/API";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [ic, setIC] = useState("");
  const [icError, setIcError] = useState(null);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setUserToken, setuserDetails } = useContext(CoinContext);

  const handleIcChange = (event) => {
    const { value } = event.target;
    setIC(value);
    if (value && !isValidIDNumber(value)) setIcError(t("kocekIn.NO_SPECIAL_CHARACTERS"));
    else setIcError(null);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const body = {
        ic,
        password,
      };
      const response = await Axios.post(API.KOCEK_IN_LOGIN, body);

      if (response.data && response.data.token) {
        setUserToken(response.data.token);
        // navigate("/kocek-in/coin-sorting");
        navigate("/kocek-in/coin-sorting", { state: { ic } });
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };
  return (
    <MainLayout
      hasFooter={false}
      backButton
      title={t("kocekIn.SIGN_IN")[0] + t("kocekIn.SIGN_IN").substring(1).toLowerCase()}
    >
      <div className="main container">
        <div className="text-center mt-4">
          <h5>{t("kocekIn.WELCOME_BACK_TO_KOCEK")}</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-outline mt-4 mb-4 text-start">
              <label htmlFor="nric">{t("kocekIn.NRIC")}</label>
              <input
                value={ic}
                onChange={handleIcChange}
                id="nric"
                className={`form-control mt-2 ${icError ? "border-danger error" : null}`}
                placeholder="123456789012"
              />
              {icError ? <div className="signupTitle1 text-danger mt-2">{icError}</div> : null}
            </div>
            <div className="form-outline mb-4 text-start">
              <label htmlFor="password">{t("kocekIn.PASSWORD")}</label>
              <div className="input-group mt-2">
                <input
                  value={password}
                  onChange={handlePasswordChange}
                  type={isVisible ? null : "password"}
                  id="password"
                  className="border-end-0 form-control"
                  style={{ flex: 1 }}
                />
                <span className="input-group-append">
                  <div
                    className="form-control border-start-0 rounded-0 rounded-end"
                    type="button"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    <i
                      className={`fa-solid ${isVisible ? "fa-eye" : "fa-eye-slash"} fa-sm`}
                      style={{ color: "#788494" }}
                    />
                  </div>
                </span>
              </div>
            </div>
            <button
              className={`cstmbtn mb-4 ${ic && password && !icError ? "enabled" : "disabled"}`}
              type="submit"
              disabled={!ic || !password || icError || isLoading}
            >
              {isLoading ? <LoadingSpinner color="#ffffff" borderWidth={2} /> : t("kocekIn.SIGN_IN")}
            </button>
          </form>
          <div className="kocek-sign-up">
            {t("kocekIn.NOT_KOCEK_MEMBER")}?{" "}
            <span className="create-account" onClick={() => navigate("/kocek-in/signup")}>
              {t("kocekIn.CREATE_AN_ACCOUNT")}
            </span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Login;
