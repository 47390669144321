import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

function BottomSheetModal(props) {
  const {
    children,
    openModal,
    onDismiss,
    fullScreen,
    onSpringStart,
    maxHeightRatio,
    minHeightRatio,
    header,
    className,
    blocking,
  } = props;
  return (
    <BottomSheet
      open={openModal}
      onDismiss={onDismiss}
      blocking={!blocking}
      defaultSnap={({ snapPoints, lastSnap }) => lastSnap ?? snapPoints[1]}
      snapPoints={({ minHeight, maxHeight }) =>
        fullScreen
          ? [maxHeight, maxHeight]
          : minHeightRatio || maxHeightRatio
          ? [
              minHeightRatio ? maxHeight * minHeightRatio : minHeight,
              maxHeightRatio ? maxHeight * maxHeightRatio : minHeight,
            ]
          : [minHeight, minHeight]
      }
      onSpringStart={onSpringStart}
      header={header}
      className={className}
      scrollLocking={blocking}
    >
      {children}
    </BottomSheet>
  );
}

export default BottomSheetModal;
