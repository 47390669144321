import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../components/HorizontalSeparator";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

function BusinessDetailsContainer() {
  const { businessDetails } = useContext(CoinContext);
  const { t } = useTranslation();
  return (
    <div className="checkout-container mb-2">
      <div className="d-flex justify-content-between align-items-center">
        <p className="title mx-0">{t("BUSINESS_DETAILS").toUpperCase()}</p>
        {/* Uncomment if decide to allow edit business details */}
        {/* <div
          role="button"
          tabIndex={0}
          onClick={openModal}
          onKeyDown={openModal}
        >
          
          <p
            className="title mx-0"
            style={{ color: "#3FBAFF", fontWeight: "600" }}
          >
            {t("EDIT")}
          </p>
        </div> */}
      </div>

      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="details-container">
        <div>
          <p className="title2">{t("BUSINESS_NAME")}</p>
          <p className="subtitle">{businessDetails?.name || "-"}</p>
        </div>
        <div>
          <p className="title2">{t("BUSINESS_INDUSTRY")}</p>
          <p className="subtitle mb-0">{businessDetails?.industry || "-"}</p>
        </div>
      </div>
    </div>
  );
}

export default BusinessDetailsContainer;
