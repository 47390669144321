/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../layouts/MainLayout";
import "./style.css";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import TwoButtonFooter from "../../components/twoButtonFooter";
import BookingDetailsContainer from "./components/BookingDetailsContainer";
import CoinContainer from "./components/CoinContainer";
import PaymentMethodContainer from "./components/PaymentMethodContainer";
import PersonalDetailsContainer from "./components/PersonalDetailsContainer";
import BusinessDetailsContainer from "./components/BusinessDetailsContainer";
import PaymentDetailsContainer from "./components/PaymentDetailsContainer";
import TNCContainer from "./components/TNCContainer";
import BottomSheetModal from "../../components/BottomSheet";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import EditProducts from "./components/EditProductsContainer";
import BottomSheetHeader from "./components/BottomSheetHeader";
import BusinessContainer from "../business/businessContainer";
import PaymentGatewayContainer from "./components/PaymentGatewayContainer";
import Footer from "../../components/Footer";

function CheckoutPage() {
  const {
    confirmedOrderDetails,
    setConfirmedOrderDetails,
    selectedPaymentMethod,
    selectedOnlineBankingMethod,
    businessDetails,
    setBusinessDetails,
    selectedLocation,
    userDetails,
    userToken,
  } = useContext(CoinContext);
  const paymentRef = useRef(null);
  const tncRef = useRef(null);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();
  const from = location?.state?.from || "";
  const [totalAmount, setTotalAmount] = useState(0);
  const [editCartTotalAmount, setEditCartTotalAmount] = useState(0);
  const [serviceTax, setServiceTax] = useState(0);
  const [totalInclusiveTax, setTotalInclusiveTax] = useState(0);
  const [checked, setChecked] = useState(false);
  const [openCoinBottomSheet, setOpenCoinBottomSheet] = useState(false);
  const [openBusinessBottomSheet, setOpenBusinessBottomSheet] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(businessDetails?.industry || null);
  const [businessName, setBusinessName] = useState(businessDetails?.name || "");
  const [emphasisPaymentMethod, setEmphasisPaymentMethod] = useState(false);
  const [emphasisTNC, setEmphasisTNC] = useState(false);

  const loadData = () => {
    let total = 0;
    const arr = [...confirmedOrderDetails];
    if (arr.length > 0) {
      for (const i of arr) {
        total += i.totalAmount;
        i.showEditQuantity = false;
      }
    }
    setConfirmedOrderDetails(arr);
    calculateAndSetServiceTax(total);
    setTotalAmount(total);
    setTotalInclusiveTax(total + serviceTax);
  };

  const getClientServiceTax = () => {
    if (userDetails?.client) {
      return userDetails.client.client_service_fee > 0 ? userDetails.client.client_service_fee / 100 : 0;
    }
    return 0;
  };

  const clientServiceTax = getClientServiceTax();

  const calculateAndSetServiceTax = (total) => {
    const taxAmount = clientServiceTax > 0 ? total * clientServiceTax : clientServiceTax;
    setServiceTax(taxAmount);
  };

  const fetchProductData = async () => {
    try {
      const url = `${API.GET_PRODUCT_LIST}?merchant_id=${selectedLocation.details.id}`;
      await Axios.get(url).then((result) => {
        if (result.status === 200 && result.data) {
          const { products } = result.data;
          const arr = [...products];
          for (const i in products) {
            const index = confirmedOrderDetails.findIndex((item) => item.product_id === products[i].product_id);
            if (confirmedOrderDetails.length > 0) {
              if (index > -1) {
                arr[i] = {
                  ...arr[i],
                  quantity: confirmedOrderDetails[index].quantity,
                  totalAmount: confirmedOrderDetails[index].totalAmount,
                  showEditQuantity: confirmedOrderDetails[index].showEditQuantity,
                };
              } else {
                arr[i] = {
                  ...arr[i],
                  quantity: 0,
                  totalAmount: 0,
                  showEditQuantity: false,
                };
              }
            } else {
              arr[i] = {
                ...arr[i],
                quantity: 0,
                totalAmount: 0,
                showEditQuantity: false,
              };
            }
          }
          let total = 0;
          for (const i of confirmedOrderDetails) {
            total += i.totalAmount;
          }
          setEditCartTotalAmount(total);
          setProductList(arr);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const calTotalAmount = () => {
    let total = 0;
    if (confirmedOrderDetails.length > 0) {
      for (const i of confirmedOrderDetails) {
        total += i.totalAmount;
      }
    }

    calculateAndSetServiceTax(total);
    setTotalAmount(total);
    setTotalInclusiveTax(total + serviceTax);
  };

  const calTotalCartAmount = () => {
    let total = 0;
    if (productList.length > 0) {
      for (const i of productList) {
        total += i.totalAmount;
      }
    }
    setEditCartTotalAmount(total);
  };

  useEffect(() => {
    if (!confirmedOrderDetails || confirmedOrderDetails.length < 1 || !selectedLocation) return navigation("/");

    if (!userDetails) return navigation("/login");
    loadData();
  }, []);

  useEffect(() => {
    calTotalAmount();
    if (openCoinBottomSheet) {
      closeAllModal();
      toast.success("Changes saved");
    }
  }, [confirmedOrderDetails]);

  useEffect(() => {
    calTotalCartAmount();
  }, [productList]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      setEmphasisPaymentMethod(false);
    }

    if (checked) {
      setEmphasisTNC(false);
    }
  }, [selectedPaymentMethod, checked]);

  const leftButton = (total_amount) => (
    <div className="row">
      <span className="total-title">{t("TOTAL_PAYMENT")}</span>
      <span className="subtotal-amount">{`RM${total_amount.toFixed(2)}`}</span>
    </div>
  );

  const rightButton = ({ title, disabled, hideArrowButton }) => (
    <div className="d-flex align-items-center justify-content-center">
      <p style={disabled ? { color: "#30303080" } : null} className="place-order-button-text mb-0">
        {title}
      </p>
      {hideArrowButton ? null : (
        <i
          style={disabled ? { color: "#30303080" } : null}
          className="place-order-button-text fa-solid fa-arrow-right-long ms-3"
        />
      )}
    </div>
  );

  const subtract = (product) => {
    if (confirmedOrderDetails.some((item) => item.product_id === product.product_id)) {
      const arr = [...confirmedOrderDetails];
      const index = confirmedOrderDetails.findIndex((item) => item.product_id === product.product_id);
      arr[index] = {
        ...arr[index],
        quantity: arr[index].quantity - 1,
        totalAmount: arr[index].totalAmount - product.product_price,
      };
      const filteredArr = arr.filter((item) => item.quantity > 0);
      setConfirmedOrderDetails(filteredArr);
    }
  };
  const add = (product) => {
    if (confirmedOrderDetails.some((item) => item.product_id === product.product_id)) {
      const arr = [...confirmedOrderDetails];
      const index = confirmedOrderDetails.findIndex((item) => item.product_id === product.product_id);
      if (arr[index].quantity < arr[index].stocks) {
        arr[index] = {
          ...arr[index],
          quantity: arr[index].quantity + 1,
          totalAmount: arr[index].totalAmount + product.product_price,
        };
        setConfirmedOrderDetails(arr);
      }
    } else {
      setConfirmedOrderDetails([
        ...confirmedOrderDetails,
        { ...product, quantity: 1, totalAmount: product.product_price },
      ]);
    }
  };

  const onChangeQuantity = (number, product) => {
    const arr = [...productList];
    const index = productList.findIndex((item) => item.product_id === product.product_id);
    if (parseInt(number) > 0) {
      if (parseInt(number) > product.stocks) {
        arr[index] = {
          ...arr[index],
          quantity: product.stocks,
          totalAmount: product.stocks * product.product_price,
        };
      } else {
        arr[index] = {
          ...arr[index],
          quantity: number,
          totalAmount: product.product_price * number,
        };
      }
    } else {
      arr[index] = {
        ...arr[index],
        quantity: 0,
        totalAmount: 0,
      };
    }
    setProductList(arr);
  };

  const addProductToCart = (product) => {
    const arr = [...productList];
    const index = productList.findIndex((item) => item.product_id === product.product_id);
    if (arr[index].quantity < arr[index].stocks) {
      arr[index] = {
        ...arr[index],
        quantity: arr[index].quantity + 1,
        totalAmount: arr[index].totalAmount + product.product_price,
      };
      setProductList(arr);
    }
  };

  const subtractProductFromCart = (product) => {
    if (productList.find((item) => item.product_id === product.product_id).quantity > 0) {
      const arr = [...productList];
      const index = productList.findIndex((item) => item.product_id === product.product_id);
      arr[index] = {
        ...arr[index],
        quantity: arr[index].quantity - 1,
        totalAmount: arr[index].totalAmount - product.product_price,
      };
      setProductList(arr);
    }
  };

  const saveOrderDetails = () => {
    const productArr = [...productList];
    const filteredArr = productArr.filter((item) => item.quantity > 0);
    setConfirmedOrderDetails(filteredArr);
  };

  const closeAllModal = () => {
    setOpenCoinBottomSheet(false);
    setOpenBusinessBottomSheet(false);
  };

  const openCoinModal = () => setOpenCoinBottomSheet(true);

  // Uncomment if decide to allow edit business details
  // const openBusinessModal = () => {
  //   setBusinessName(businessDetails?.name || "");
  //   setSelectedIndustry(businessDetails?.industry || null);
  //   setOpenBusinessBottomSheet(true);
  // };

  const onClickNavigate = () => {
    navigation("/checkout/payment-method");
  };

  const saveBusinessDetails = () => {
    setBusinessDetails({
      ...businessDetails,
      name: businessName,
      industry: selectedIndustry,
    });
    setOpenBusinessBottomSheet(false);
  };

  const ManualPaymentNavigation = async () => {
    if (!selectedPaymentMethod || !checked) {
      if (!selectedPaymentMethod) {
        setEmphasisPaymentMethod(true);
        paymentRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      if (!checked) {
        setEmphasisTNC(true);
        if (selectedPaymentMethod) {
          tncRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    } else if (selectedPaymentMethod.key === "Online Banking") {
      const headers = {
        "Content-Type": "application/json",
        client: userToken,
        user: "user",
      };

      const body = {
        orderType: "Mobile",
        orderDetails: confirmedOrderDetails,
        paymentMethod: selectedPaymentMethod,
        businessDetails,
        locationDetails: selectedLocation.details,
        bankDetails: selectedOnlineBankingMethod,
      };

      await Axios.post(API.ADD_PAYMENT_DETAILS, body, { headers }).then((response) => {
        if (response.data) {
          document.getElementById("paygatewayform").action = response.data.action;
          document.getElementById("payment_method").value = response.data.payment_method;
          document.getElementById("bank_id").value = response.data.bank_id;

          document.getElementById("paygatewayform").submit();
        }
      });
    } else if (selectedPaymentMethod.key === "Kocek Teller Machine - CIMB CDM Bank") {
      navigation("/checkout/cimb-cdm", {
        state: { totalAmount: totalInclusiveTax },
      });
    } else if (selectedPaymentMethod.key === "Kocek Teller Machine - ATM JomPAY") {
      navigation("/checkout/jompay", {
        state: { totalAmount: totalInclusiveTax },
      });
    }
  };

  return (
    <>
      <BottomSheetModal
        openModal={openCoinBottomSheet || openBusinessBottomSheet}
        onDismiss={closeAllModal}
        fullScreen={openCoinBottomSheet || openBusinessBottomSheet}
        onSpringStart={openCoinBottomSheet ? fetchProductData : null}
        className={openCoinBottomSheet ? "coin-bottom-sheet" : openBusinessBottomSheet ? "business-bottom-sheet" : null}
        blocking={openCoinBottomSheet}
      >
        <BottomSheetHeader
          closeModal={closeAllModal}
          title={openCoinBottomSheet ? t("COIN_ORDER") : openBusinessBottomSheet ? t("BUSINESS_DETAILS") : null}
        />
        {openCoinBottomSheet ? (
          <EditProducts
            productList={productList}
            addProductToCart={addProductToCart}
            onChangeQuantity={onChangeQuantity}
            subtractProductFromCart={subtractProductFromCart}
            saveOrderDetails={saveOrderDetails}
            leftButton={leftButton(editCartTotalAmount)}
            rightButton={rightButton({
              title: t("SAVE_CHANGES"),
              disabled: false,
              hideArrowButton: true,
            })}
          />
        ) : null}
        {openBusinessBottomSheet ? (
          <div style={{ marginLeft: 16, marginRight: 16, height: "100%" }}>
            <BusinessContainer
              businessName={businessName}
              setBusinessName={setBusinessName}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={setSelectedIndustry}
            />
            <footer className="text-center text-lg-start mt-auto fixed-bottom row justify-content-center">
              <div className="col-12 col-lg-6">
                <Footer footerText={t("SAVE_CHANGES")} buttonOnClick={saveBusinessDetails} />
              </div>
            </footer>
          </div>
        ) : null}
      </BottomSheetModal>
      <MainLayout hasFooter={false} title={t("CHECKOUT")} backButton={from !== "receipt"} backgroundColor="#F5F5F5">
        <div className="mb-2 h-100 p-0">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 p-0">
              <BookingDetailsContainer />
              <CoinContainer totalAmount={totalAmount} add={add} subtract={subtract} openModal={openCoinModal} />
              <PaymentMethodContainer
                ref={paymentRef}
                onClick={onClickNavigate}
                emphasisPaymentMethod={emphasisPaymentMethod}
              />
              <PersonalDetailsContainer />
              <BusinessDetailsContainer />
              <PaymentDetailsContainer
                totalAmount={totalAmount}
                serviceTax={serviceTax}
                totalInclusiveTax={totalInclusiveTax}
              />
              <TNCContainer checked={checked} setChecked={setChecked} emphasisTNC={emphasisTNC} ref={tncRef} />
            </div>
          </div>
        </div>
        <TwoButtonFooter
          renderLeftButton={leftButton(totalInclusiveTax)}
          onClickRightButton={ManualPaymentNavigation}
          renderRightButton={rightButton({
            title: t("PAY_NOW"),
            disabled: !confirmedOrderDetails || confirmedOrderDetails?.length < 1,
            hideArrowButton: false,
          })}
          rightButtonCustomStyle={{ backgroundColor: "#282828" }}
          leftButtonCustomStyle={{ backgroundColor: "white" }}
          disabledRightButton={!confirmedOrderDetails || confirmedOrderDetails?.length < 1}
          rightDisabledStyle={{ backgroundColor: "#F5F5F5" }}
        />
        <PaymentGatewayContainer />
      </MainLayout>
    </>
  );
}

export default CheckoutPage;
