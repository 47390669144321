/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

function TwoButtonFooter(props) {
  const {
    onClickLeftButton,
    onClickRightButton,
    renderLeftButton,
    renderRightButton,
    rightButtonCustomStyle,
    leftButtonCustomStyle,
    fullWidth,
    disabledRightButton,
    disabledLeftButton,
    leftDisabledStyle,
    rightDisabledStyle,
    nonSticky,
  } = props;
  return (
    <>
      <footer className={`mt-auto fixed-footer ${nonSticky ? null : "fixed-bottom"}`}>
        <div className="row justify-content-center">
          <button
            onClick={onClickLeftButton}
            disabled={disabledLeftButton}
            className={`col-6 ${fullWidth ? null : "col-lg-3"} border border-end-0 py-2 text-end`}
            style={disabledLeftButton ? { ...leftButtonCustomStyle, ...leftDisabledStyle } : leftButtonCustomStyle}
          >
            {renderLeftButton}
          </button>
          <button
            type="button"
            onClick={onClickRightButton}
            disabled={disabledRightButton}
            className={`col-6 ${fullWidth ? null : "col-lg-3"} border-0 text-center py-2`}
            style={disabledRightButton ? { ...rightButtonCustomStyle, ...rightDisabledStyle } : rightButtonCustomStyle}
          >
            {renderRightButton}
          </button>
        </div>
      </footer>
    </>
  );
}

export default TwoButtonFooter;
