import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

function Toast() {
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 600px)").matches);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const defaultConfig = {
    style: isMobile ? null : { width: "80%" },
    closeButton: false,
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_CENTER,
    theme: "dark",
    hideProgressBar: true,
  };
  return <ToastContainer {...defaultConfig} />;
}

export default Toast;
