import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import BusinessContainer from "./businessContainer";

function BusinessDetails() {
  const { selectedLocation, setBusinessDetails, selectedLanguage } = useContext(CoinContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [businessName, setBusinessName] = useState("");
  const [openIndustryBottomSheet, setOpenIndustryBottomSheet] = useState(false);

  const buttonOnNavigate = () => {
    setBusinessDetails({
      name: businessName,
      industry:
        selectedLanguage === "en" ? selectedIndustry.business_sector_name_en : selectedIndustry.business_sector_name_bm,
    });
    navigate("/checkout");
  };

  const skipNavigate = () => {
    setBusinessDetails({});
    navigate("/checkout");
  };

  return (
    <MainLayout
      hasFooter
      twoButton={!businessName || !selectedIndustry}
      backButton
      title={selectedLocation?.details?.store_name}
      footerText={t("CONTINUE")}
      bottomButtonText={t("SKIP_THIS_STEP")}
      topButtonText={t("CONTINUE")}
      topButtonIcon={<i className="fa-solid fa-arrow-right-long fa-sm ms-3" />}
      topButtonDisabled={!businessName || !selectedIndustry}
      disabledTopStyle={{
        backgroundColor: "#F5F5F5",
        borderColor: "#F5F5F5",
        color: "#30303080",
        width: "100%",
      }}
      topButtonStyle={{
        border: "none",
      }}
      topButtonOnClick={buttonOnNavigate}
      footerButtonOnClick={buttonOnNavigate}
      bottomButtonOnClick={skipNavigate}
    >
      <div className="container mb-2 h-100">
        <div className="row justify-content-center pt-2">
          <div className="col-12 col-lg-6">
            <div className="text-center">
              <p className="main-title">{t("BUSINESS_TITLE")}</p>
            </div>
            <p className="subtitle text-lg-center">{t("BUSINESS_DETAILS_TITLE2")}</p>
          </div>
        </div>

        <BusinessContainer
          selectedIndustry={selectedIndustry}
          setSelectedIndustry={setSelectedIndustry}
          businessName={businessName}
          setBusinessName={setBusinessName}
          openIndustryBottomSheet={openIndustryBottomSheet}
          setOpenIndustryBottomSheet={setOpenIndustryBottomSheet}
        />
      </div>
    </MainLayout>
  );
}

export default BusinessDetails;
