import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../components/HorizontalSeparator";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

function BookingDetailsContainer() {
  const { t } = useTranslation();
  const { selectedLocation } = useContext(CoinContext);

  return (
    <div className="checkout-container mb-2">
      <p className="title">{t("BOOKING_DETAILS")}</p>
      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="d-flex justify-content-start details-container">
        <i className="fa-solid fa-location-dot" style={{ color: "#606060" }} />
        <div className="booth-container">
          <p className="title2">{t("BOOTH_LOCATION")}</p>
          <p className="location-name">{selectedLocation?.details.store_name}</p>
          <p className="content">{selectedLocation?.details.address}</p>
        </div>
      </div>
    </div>
  );
}

export default BookingDetailsContainer;
