import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ImageModal from "../../components/Modal/ImageModal";

function ProductContainer(props) {
  const { t } = useTranslation();
  const { productList, subtract, add, onChangeQuantity } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const openImageModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };
  return (
    <>
      <ImageModal openModal={openModal} closeModal={() => setOpenModal(false)} image={selectedImage} />
      <div className="mt-4 row justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="mt-4 px-2 d-flex justify-content-between">
            <p className="subtitle">{t("COIN_DESCRIPTION")}</p>
            <p className="subtitle">{t("QUANTITY")}</p>
          </div>
          {productList.map((item, index) => {
            return (
              <div
                key={index}
                className={`text-start row py-3 px-2 ${index % 2 === 0 ? "bg-light" : "bg-white"} ${
                  item.stocks <= 0 ? "out-of-stock-container" : null
                }`}
              >
                <div className="col-3 col-md-2 position-relative">
                  {item.stocks <= 0 ? (
                    <div className="position-absolute top-0 start-0 out-of-stock-div">
                      <p className="out-of-stock">OUT OF STOCK</p>
                    </div>
                  ) : null}
                  <img
                    className="w-100"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => openImageModal(item.product_image)}
                    src={item.product_image}
                    alt="coin_image"
                    onClick={() => openImageModal(item.product_image)}
                  />
                </div>
                <div className="col-5">
                  <p className="coin-name">{item.product_name}</p>
                  <p className="coin-min">
                    {`RM${Number(item.product_price).toFixed(2)}/${item.product_price < 1 ? t("pcs") : t("PACK")}`}
                  </p>
                  {item.product_remark ? (
                    <p
                      className={`${
                        item.product_remark === "Old" ? "order-coin-status-old" : "order-coin-status-new"
                      } rounded justify-content-center d-flex align-items-center`}
                      style={{ marginLeft: 0 }}
                    >
                      {item.product_remark === "Old" ? t("OLD_COIN") : t("NEW_COIN")}
                    </p>
                  ) : (
                    <p hidden="hidden" />
                  )}
                </div>
                <div className="quantity-column col-4 col-md-5">
                  <div className="d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      onClick={() => subtract(item)}
                      disabled={item.quantity === 0 || item.stocks === 0}
                      className={`${
                        item.quantity === 0 ? "text-muted" : "add-delete-symbol"
                      } border border-1 rounded-start add-delete-quantity`}
                    >
                      -
                    </button>
                    <input
                      value={item.quantity}
                      disabled={item.stocks === 0}
                      className={`${
                        item.quantity > 0 ? null : "text-muted"
                      } border border-1 border-start-0 border-end-0 quantity text-center`}
                      onChange={(e) => onChangeQuantity(e.target.value, item)}
                    />
                    <button
                      onClick={() => add(item)}
                      type="button"
                      disabled={item.quantity === item.stocks || item.stocks === 0}
                      className={`${
                        item.quantity === item.stocks ? "text-muted" : "add-delete-symbol"
                      } border border-1 rounded-end add-delete-quantity`}
                    >
                      +
                    </button>
                  </div>
                  {item.quantity === item.stocks && item.stocks > 0 ? (
                    <div>
                      <span className="limit-message">{t("REACH_LIMIT")}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ProductContainer;
