import React from "react";

function LoadingSpinner(props) {
  const { color, borderWidth } = props;
  return (
    <div className="spinner-border" style={{ color: color || "black", borderWidth }} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default LoadingSpinner;
