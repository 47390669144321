import React from "react";
import Modal from "react-bootstrap/Modal";

function CenteredModal({ children, openModal, closeModal }) {
  return (
    <Modal show={openModal} onHide={closeModal} centered aria-labelledby="contained-modal-title-vcenter">
      {children}
    </Modal>
  );
}

export default CenteredModal;
