import React from "react";
import { Link, Text, View } from "@react-pdf/renderer";

export default {
  LANGUAGE: "Language",
  KOCEK_OUT_ONLINE_ORDER: "KOCEK Online Order",
  INSERT_KOCEK_OUT_AMOUNT: "Insert KOCEK Out amount",
  SUBJECT_TO_STOCK_AVAILABILITY: "Subject to stock availability",
  PAYMENT_AMOUNT: "Payment amount",
  SERVICE_FEE_CALCULATOR: "Service fee calculator",
  SERVICE_FEE_CALCULATOR_TITLE: "Calculate your payment amount with our rate",
  TERMS_AND_CONDITIONS: "Terms & conditions",
  COIN_WITHDRAWAL_BOOKING: "Coin Withdrawal Booking",
  MERCHANT_SUBTITLE: "...because every sen counts",
  MERCHANT_TITLE: "STEP 1: SELECT KIOSK LOCATION",
  NUMBER_OF_KIOSK: (number) => {
    return `${number} kiosk locations nationwide`;
  },
  STATES: "States",
  DISTANCE: "Distance",
  OPENING_HOUR: "Opening hour",
  FILTER: "Filter",
  FILTER_BY: "Filter by",
  SEARCH_STATE: "Search state",
  NEXT: "NEXT",
  PREVIOUS: "PREVIOUS",
  OPEN: "Open",
  CLOSE: "Closed",
  PLACE_ORDER: "PLACE ORDER",
  CONFIRM: "CONFIRM",
  SUBTOTAL: "Subtotal",
  ORDER_DETAILS: "Order Details",
  PRODUCT_LISTING_TITLE: "STEP 2: SELECT COINS TO ORDER",
  BUSINESS_TITLE: "STEP 3: BUSINESS DETAILS",
  OPTIONAL: "Optional",
  NO_RECORD_IN_ORDER_DETAILS: "No record. Start by filling in your order.",
  REACH_LIMIT: "Limit reached",
  SERVICE_FEE: "Subject to service fee",
  COIN_DESCRIPTION: "List of coins",
  QUANTITY: "Pack quantity",
  PACK: "pack",
  OLD_COIN: "1st & 2nd series",
  NEW_COIN: "3rd series",
  ITEM: "items",
  SOMETHING_WENT_WRONG: "Something went wrong",
  FETCH_FAIL: "Sorry, we can’t process your request at the moment. Please try again later or click ‘Retry’.",
  RETRY: "RETRY",
  ENTER_EMAIL: "Enter your email address",
  LOGIN_TITLE: "Let’s connect to get you updated with your order",
  EMAIL_PLACEHOLDER: "example@email.com",
  CONTINUE: "CONTINUE",
  SIGN_UP: "Create an account",
  SIGN_UP_TITLE: "You are one step closer to completing your order!",
  SIGN_UP_TERM_AND_CONDITION: "By continuing, you agree with our",
  SIGN_UP_TERM_AND_CONDITION_1: "Terms & Conditions.",
  SIGN_UP_TERM_AND_CONDITION_2: "",
  NAME: "Name",
  EMAIL_VALIDATION: "Please enter a valid email address",
  PHONE_NUMBER_VALIDATION: "Please enter a valid phone number",
  PHONE_NUMBER: "Phone Number",
  CHECK_EMAIL: "Check your email",
  CONTINUE_AS_GUEST: "Continue as guest",
  ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  CONTINUE_WITH_EMAIL: "Continue with email",
  VERIFICATION_TITLE: "Please key-in the 6-digit code sent to your email for verification",
  RESEND_VERIFICATION_CODE: (number) => {
    return `Resend verification code in ${number} seconds`;
  },
  NO_VERIFICATION_CODE: "Didn’t receive your code? ",
  BUSINESS_DETAILS_TITLE2: "Using coins for your business? Do share the nature of your business with us",
  CHECKOUT: "Checkout",
  BOOKING_DETAILS: "COLLECTION INFORMATION",
  BOOTH_LOCATION: "Kiosk location",
  COIN_ORDER_SUMMARY: "ORDER SUMMARY",
  AMOUNT: "Amount",
  TOTAL_PAYMENT: "Amount due",
  TOTAL_PAYMENT_PAID: "Amount paid",
  PAY_NOW: "PAY NOW",
  PAYMENT_METHOD: "PAYMENT METHOD",
  SELECT_PAYMENT_METHOD: "Please select payment method",
  PERSONAL_DETAILS: "PERSONAL DETAILS",
  EDIT: "EDIT",
  NAME_AND_PHONE: "Name & Phone number",
  EMAIL: "Email address",
  BUSINESS_DETAILS: "Business Details",
  BUSINESS_DETAILS_TITLE: "Withdrawing coins for your business use? Tell us more!",
  BUSINESS_NAME: "Business name",
  BUSINESS_NAME_PLACEHOLDER: "Enter business name",
  BUSINESS_INDUSTRY: "Business sector",
  BUSINESS_INDUSTRY_PLACEHOLDER: "Select business sector",
  SKIP_THIS_STEP: "Skip this step",
  PAYMENT_DETAILS: "PAYMENT DETAILS",
  TOTAL_WITHDRAWAL_AMOUNT: "KOCEK Out amount",
  SERVICE_FEE_PERCENT: "Service fee",
  ADD_MORE_ITEMS: "Add more items",
  PICKUP_AMOUNT: "Pickup amount",
  SST: "Inclusive 6% SST",
  AGREE_DETAILS: "I have read and agreed with the amount and location of order collection.",
  AGREE_TNC: ({ openModal }) => (
    <p className="subtitle mb-0">
      By clicking the ‘Pay now’ button below, you agree with our{" "}
      <button
        type="button"
        style={{
          color: "#3FBAFF",
          border: "none",
          backgroundColor: "transparent",
        }}
        onClick={openModal}
      >
        Terms & Conditions.
      </button>
    </p>
  ),
  SAVE_CHANGES: "SAVE CHANGES",
  COIN_ORDER: "Coin Order",
  TERMS_AND_CONDITION: "Terms & Conditions",
  CURRENCY: "Currency",
  CURRENCY_CONTENT: "The exchange service is only available for Malaysian currency only.",
  MINIMUM_AMOUNT: "Minimum amount",
  MINIMUM_AMOUNT_CONTENT: "The coins prepared by KOCEK will be pre-packed with a minimum amount of RM50.00 per pack",
  COIN_SPECIFICATION: "Coin Specifications",
  COIN_SPECIFICATION_CONTENT:
    "In line with the Bank Negara Malaysia’s Guideline on Quality Standards for Malaysian Currency, the exchange service is only available for Malaysian currency only and only for currency and coins  that are in good condition and free from defects.",
  MINIMUM_EXCHANGE: "Minimum exchange",
  MINIMUM_EXCHANGE_CONTENT:
    "The minimum amount for coin exchange for each individual shall be RM50.00 per transaction.",
  INFORMATION_SHARING: "Information Sharing",
  INFORMATION_SHARING_CONTENT: ({ color }) => (
    <p className="content">
      The minimum amount for coin exchange for each individual shall be RM100.00 per transaction and a maximum of{" "}
      <span style={{ color }}>RM1,000.00</span> per transaction, per day.
    </p>
  ),
  TNC_SERVICE_FEE: "Service Fee",
  TNC_SERVICE_FEE_CONTENT:
    "A service fee of 5% from the total amount of coins transacted will be charged to the customer.",
  ORDER_COLLECTION: "Order Collection",
  ORDER_COLLECTION_LOCATION: "Order collection location",
  ORDER_COLLECTION_CONTENT:
    "For verification purposes, customers or their representatives are required to present the payment receipt to be checked and confirmed by a KOCEK employee before the coins can be collected.",
  COLLECTION_OF_ORDER: "Collection of Order",
  COLLECTION_OF_ORDER_CONTENT:
    "Any orders that remain uncollected after a period of Seven (7) days from the transaction date shall be considered null and void, and the payment made for such uncollected coins shall be refunded to the Customer’s bank account.",
  EXEMPTION: "Exemptions",
  EXEMPTION_CONTENT:
    "KOCEK shall not be responsible for any loss or inconvenience caused by the provision of incorrect or inaccurate information by the customer during the coin withdrawal or coin deposit process. It is the customer's responsibility to ensure that all information provided, including but not limited to personal details and transaction instruction is accurate.",
  INSPECTION: "Inspection",
  INSPECTION_CONTENT:
    "The customer shall have the right to inspect the coins and verify the amount of coins exchanged at the exchange booth.",
  CONFIDENTIALITY: "Confidentiality",
  CONFIDENTIALITY_CONTENT:
    "The personal information provided by customers, including full name, email address, and phone number, will be used solely for the purpose of processing orders and providing updates. We will not share, sell, or disclose customer information to third parties, except as required by law or with the customer's explicit consent.",
  FORCE_MAJUERE: "Force Majuere",
  FORCE_MAJUERE_CONTENT:
    "KOCEK shall not be liable for any loss, damage, or inconvenience caused due to circumstances beyond our control, including but not limited to natural disasters, technical failures, or any other force majeure events.",
  UPDATE_AND_MODIFICATION: "Update & Modifications",
  UPDATE_AND_MODIFICATION_CONTENT:
    "The terms and conditions outlined herein are subject to updates, modifications, or revisions at the discretion of KOCEK. Customers will be notified of any changes via email, website notifications, or other appropriate means. It is the responsibility of the customer to review the updated terms and conditions periodically. Continued usage of KOCEK’s coin withdrawal and coin deposit services after the issuance of updated terms and conditions implies the customer's acceptance of the revised terms.",
  ORDER_VERIFICATION: "Order Verification",
  ORDER_VERIFICATION_CONTENT: () => (
    <ol style={{ paddingLeft: 16, marginBottom: 0 }}>
      <li className="content">
        Customers or their representatives are required to present the receipt provided for coins transacted to our
        KOCEK employees for verification purposes.
      </li>
      <li className="content">
        Any issues arising in regards to the condition of the coins or amount of coins exchanged will be addressed on
        the spot at the booth.
      </li>
    </ol>
  ),
  PERSONAL_INFORMATION: "Personal Information",
  PERSONAL_INFORMATION_CONTENT: () => (
    <>
      <p className="content">
        The personal information provided by customers, including full name, email address, and phone number, will be
        used solely for the purpose of processing orders and providing updates.
      </p>
      <p className="mt-3 content">
        We will not share, sell, or disclose customer information to third parties, except as required by law or with
        the customer's explicit consent.
      </p>
    </>
  ),
  INFORMATION_COLLECTION_AND_USE: "Information Collection and Use",
  INFORMATION_COLLECTION_AND_USE_CONTENT:
    "The personal information provided by customers, including full name, email address, and phone number, will be used solely for the purpose of processing orders and providing updates. We will not share, sell, or disclose customer information to third parties, except as required by law or with the customer's explicit consent.",
  CUSTOMER_ACKNOWLEDGEMENT: "Customer acknowledgement",
  CUSTOMER_ACKNOWLEDGEMENT_CONTENT:
    "The customer shall have the right to inspect the coins and verify the amount of coins exchanged at the exchange booth. Any issues arising in regards to the condition of the coins or amount of coins exchanged will be addressed on the spot at the booth. Otherwise, the customer acknowledges that the amount of coins exchanged is correct and the coins are in good condition.",
  LIMITATION_OF_LIABILITY: "Limitation of Liability",
  LIMITATION_OF_LIABILITY_CONTENT:
    "KOCEK shall not be liable for any loss, damage, or inconvenience caused due to circumstances beyond our control, including but not limited to natural disasters, technical failures, or any other force majeure events.",
  UNCOLLECTED_ORDERS: "Uncollected orders",
  UNCOLLECTED_ORDERS_CONTENT:
    "Any coins that remain uncollected after a period of Seven (7) days from the transaction date shall be considered null and void, and the payment made for such uncollected coins shall be refunded to the Customer’s bank account.",
  BACK_TO_HOME_PAGE: "Back to KOCEK Out Online Order",
  PAYMENT_METHOD_TITLE: "Payment Methods",
  ONLINE_BANKING: "Online Banking",
  CREDIT_CARD: "Credit/ Debit Card",
  AMERICAN_EXPRESS: "American Express",
  ONLINE_PAYMENT: "Online payments",
  MANUAL_PAYMENT: "Manual payment",
  KOCEK_TELLER_MACHINE: "KOCEK Teller Machine",
  PAYMENT_INSTRUCTION: "Payment intruction will be provided on the next page",
  CIMB: "Pay in cash at CDM machine",
  CIMB_PS: "(at CIMB branches only)",
  JOMPAY: "Kocek Teller Machine - ATM JomPAY",
  JOMPAY_TITLE: "Pay at ATM machine with JomPAY",
  JOMPAY_PS: "(same bank as your ATM bank card)",
  CIMB_KTM_TITLE: "KOCEK Teller Machine - CIMB CDM Bank",
  CIMB_KTM_SUBTITLE: "Follow the instructions below to deposit in cash at the CDM machine",
  CIMB_INSTRUCTIONS: (amount) => (
    <ol className="list-container">
      <li className="list">Go to the nearest CIMB CDM machine. Make sure the machine has a receipt.</li>
      <li className="list">Select bank/ credit card account.</li>
      <li className="list">
        Enter the following account number: <div className="highlighted-text">99001 0000 00102</div>
      </li>
      <li className="list">
        Make sure the account holder name is <span className="fst-italic">KOCEK</span> before starting the payment.
      </li>
      <li className="list">Insert money into the CDM machine.</li>
      <li className="list">
        Make sure the amount shown on the screen is: <div className="highlighted-text">{`RM${amount}`}</div>
      </li>
      <li className="list">
        Select <span className="fst-italic">Accept</span> if the amount is sufficient.
      </li>
      <li className="list">
        Take a photo of the receipt along with order number and send a WhatsApp to KOCEK Hotline for confirmation:{" "}
        <div className="highlighted-text">(+60)11 56655743</div>
      </li>
      <li className="list">
        An official receipt will be sent to your email/ phone number (WhatsApp) once payment is confirmed.{" "}
      </li>
    </ol>
  ),
  JOMPAY_KTM_TITLE: "Manual payment - ATM JomPAY",
  JOMPAY_KTM_SUBTITLE: "Follow the instructions below to make payment at the ATM machine using JomPAY",
  JOMPAY_LIST_1: "Go to the same ATM Bank machine as your ATM card",
  JOMPAY_LIST_2: "Insert your ATM card and PIN",
  JOMPAY_LIST_3: "Select Bill payment",
  JOMPAY_LIST_4: "Select JomPAY",
  JOMPAY_LIST_5: "Enter the following biller code:",
  JOMPAY_LIST_6: "Insert REF-1:",
  JOMPAY_LIST_7: "Insert payment amount:",
  JOMPAY_LIST_8: "Print and keep payment receipt for reference",
  JOMPAY_LIST_9: "An official receipt will be sent to your email/ phone number (WhatsApp) once payment is confirmed.",
  JOMPAY_INSTRUCTIONS: (amount) => (
    <ol className="list-container">
      <li className="list">Go to the same ATM Bank machine as your ATM card</li>
      <li className="list">Insert your ATM card and PIN</li>
      <li className="list">Select Bill payment</li>
      <li className="list">Select JomPAY</li>
      <li className="list">
        Enter the following biller code:
        <div className="highlighted-text">631796</div>
      </li>
      <li className="list">
        Insert REF-1:
        <div className="highlighted-text">101306202301</div>
      </li>
      <li className="list">
        Insert payment amount:
        <div className="highlighted-text">{`RM${amount}`}</div>
      </li>
      <li className="list">Print and keep payment receipt for reference</li>
      <li className="list">
        An official receipt will be sent to your email/ phone number (WhatsApp) once payment is confirmed.{" "}
      </li>
    </ol>
  ),
  PAYMENT_TITLE: "Payment",
  VIEW_ORDER: "VIEW ORDER",
  TRANSACTION_FAILED: "Oops! Transaction Failed!",
  TRANSACTION_FAILED_DESCRIPTION: "Worry not, your order is safe with us. Click the ‘Retry’ button below.",
  TRANSACTION_PENDING_DESCRIPTION: `Your payment is still pending. Complete your payment now so our team can prepare your order\n\nClick ‘Back to Payment Instruction’ to make payment.`,
  PENDING_BUTTON: "BACK TO PAYMENT INSTRUCTION",
  PENDING_PAYMENT: "PAYMENT PENDING",
  FAILED: "FAILED",
  RECEIVED: "RECEIVED",
  TAX_INVOICE_NO: "Tax invoice no.",
  TRANSACTION_ID: "Transaction ID",
  WHATS_NEXT: "What's next",
  ORDER_PROCESSED: "Your order is being processed and will be ready within 2 days.",
  ORDER_INSTRUCTION: () => (
    <ol className="order-instruction-list-container">
      <li className="details-1 ps-2">
        Please download payment receipt as a proof. We have also sent a copy via email.
      </li>
      <li className="details-1 ps-2">You will be notified via email when your order is ready for collection.</li>
      <li className="details-1 ps-2">Come to the stated KOCEK Kiosk location at the time allotted.</li>
      <li className="details-1 ps-2">Get your receipt ready and let’s KOCEK Out!</li>
    </ol>
  ),
  ORDER_INSTRUCTION_PDF: () => (
    <View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 4,
          marginTop: 5,
        }}
      >
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>1.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          Please download payment receipt as a proof. We have also sent a copy via email.
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 4,
          marginTop: 5,
        }}
      >
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>2.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          You will be notified via email when your order is ready for collection.
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 4,
          marginTop: 5,
        }}
      >
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>3.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>
          Come to the stated KOCEK Kiosk location at the time allotted.
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          marginBottom: 4,
          marginTop: 5,
        }}
      >
        <Text style={{ marginHorizontal: 8, fontSize: 14 }}>4.</Text>
        <Text style={{ color: "#303030", fontSize: 14, flex: 1 }}>Get your receipt ready and let’s KOCEK Out!</Text>
      </View>
    </View>
  ),
  COPYRIGHT: () => (
    <>
      <p className="copyright">KOCEK is powered by DAPAT VISTA (M) SDN BHD (200001005753 (508358-X))</p>
      <p className="copyright mt-0">
        For any inquiry, please contact us at{" "}
        <a href="mailto:help@mypay.com.my" style={{ color: "#3FBAFF", textDecorationLine: "underline" }}>
          help@mypay.com.my
        </a>{" "}
        | (+60) 3-56260033
      </p>
    </>
  ),
  COPYRIGHT_PDF: () => (
    <>
      <Text
        style={{
          color: "white",
          fontSize: 12,
          textAlign: "center",
          marginTop: 26,
          marginBottom: 10,
        }}
      >
        KOCEK is powered by DAPAT VISTA (M) SDN BHD (200001005753 (508358-X))
      </Text>
      <Text
        style={{
          color: "white",
          fontSize: 12,
          textAlign: "center",
          marginBottom: 10,
        }}
      >
        For any inquiry, please contact us at{" "}
        <Link src="mailto:help@mypay.com.my" style={{ color: "#3FBAFF", textDecorationLine: "underline" }}>
          help@mypay.com.my
        </Link>{" "}
        | (+60) 3-56260033
      </Text>
    </>
  ),
  DOWNLOAD_PDF_RECEIPT: "DOWNLOAD PDF RECEIPT",
  NOTE: "Note",
  DISTANCE_NOTE: "Approximate distances are shown as reference",
  kocekIn: {
    WELCOME_BACK_TO_KOCEK: "Welcome back to KOCEK!",
    NRIC: "NRIC or Passport number",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm password",
    SIGN_IN: "SIGN IN",
    CREATE_AN_ACCOUNT: "Create an account",
    NOT_KOCEK_MEMBER: "Not a KOCEK member yet",
    NO_SPECIAL_CHARACTERS: "Special characters are invalid eg. +-.!",
    IC_LENGTH: "Please enter a valid ic number",
    SORTING_COINS: "Sorting your coins",
    SORT_TITLE: "Thank you for using KOCEK. If you've given us your coins, relax while we sort them.",
    SORT_TITLE_2:
      "Once done, you'll get a 6-digit code from our staff. Click the 'Insert code' button to view your transaction summary.",
    INSERT_CODE: "INSERT CODE",
    PERSONAL_DETAILS: "PERSONAL DETAILS",
    PASSWORD_SETTING: "PASSWORD SETTING",
    FULL_NAME: "Full name",
    ENTER_FULL_NAME: "Enter full name",
    PHONE_NUMBER: "Phone number",
    EMAIL: "Email address",
    HOME_ADDRESS: "Home address",
    SET_PASSWORD: "SET PASSWORD",
    OCCUPATION_TYPE: "Occupation type",
    OCCUPATION_TYPE_PLACEHOLDER: "Select occupation",
    ALREADY_HAVE_AN_ACCOUNT: "Already have an account",
    MOBILE_NUMBER_UNSUITABLE: "Unsuitable mobile number",
    EMAIL_ADDRESS_UNSUITABLE: "Unsuitable email address",
    PASSWORD_UNSUITABLE: "Unsuitable password",
    PASSWORD_DO_NOT_MATCH: "Password do not match",
    MOBILE_NUMBER_EXISTS: "Mobile number already registered",
    EMAIL_ADDRESS_EXISTS: "Email address already registered",
    ID_NUMBER_EXISTS: "ID number already registered",
    PASSWORD_RULE: "Your password must be at least 8 characters long and include at least one number and one symbol.",
    VERIFY_ACCOUNT: "VERIFY ACCOUNT",
    VERIFICATION_TITLE: (email) => (
      <div className="title2 mt-3 text-muted">
        Please key-in the 6-digit code sent to your email {email} for verification
      </div>
    ),
    ACCOUNT_VERIFIED: "You account has been verified",
    ACCOUNT_VERIFIED_TITLE:
      "Thank you for registering your account with KOCEK. Please click the 'Convert coins' button to proceed.",
    CONVERT_COINS: "CONVERT COINS",
    BACK_TO_HOME_PAGE: "Back to homepage",
  },
  KIOSK_OPEN: "Kiosk is currently open",
  STEP_TWO_TITLE: "STEP 2: CHOOSE SERVICES",
  CONVERT_COINS: "Convert coins - KOCEK In",
  BUY_COINS: "Buy coins - KOCEK Out",
  WHAT_FEE: "What fee will I be charged for?",
  SERVICE_FEE_DETAILS: "Service fee details",
  YOUR_SIMPLE_SOLUTION: "Your simple solution to convert unused coins into digital cash value.",
  A_CONVENIENT_SOLUTION:
    "A convenient solution to your needs for your daily business transactions. We offer a hassle-free service to effortlessly buy coins.",
  FREE_OF_CHARGE: "Free of charge",
  CALCULATE_FEE: "Calculate Fee",
  INSERT_CODE: "Insert code",
  KEY_IN_CODE: "Please key-in the 6-digit code provided by the retail staff to view the coin conversion summary",
  SUMMARY_TITLE: "STEP 3: COIN CONVERSION SUMMARY",
  SUMMARY_REVIEW: "Please review the summary of coins converted and service fee that will be charged",
  KOCEK_IN_AMOUNT: "KOCEK In amount",
  TOTAL_AMOUNT: "Total amount",
  AMOUNT_TO_BE_TRANSFERED: "Amount to be transferred",
  TERMS_AND_CONDITION_KOCEK_IN:
    "I agree with all terms and conditions of this transaction, including the fee charged and the payout method for my conversion amount converted.",
  TOTAL_KOCEK_IN_AMOUNT: "Total KOCEK In amount",
  VOUCHER_INCENTIVE_AMOUNT: "Voucher incentive amount",
  TOTAL_VOUCHER_AMOUNT: "Total voucher amount",
  VOUCHER_TO_BE_RECEIVED: "Voucher to be received",
  KIOSK_INFORMATION: "KIOSK INFORMATION",
  PAYOUT_DETAILS: "PAYOUT DETAILS",
  INFO_CONFIRM: "I confirm the above information is correct.",
  BANK_NAME: "Bank Name",
  ACCOUNT_NUMBER: "Account Number",
  ACCOUNT_HOLDER: "Account Holder's Name",
  SELECT_BANK: "Select bank",
  ENTER_ACCOUNT_NUMBER: "Enter account number",
  ENTER_ACCOUNT_HOLDER: "Enter account holder",
  BANK_DETAILS_WARNING:
    "KOCEK will not be held responsible in the event that you key in an inaccurate bank account number. Please check for accuracy!",
  BANK_DETAILS: "Bank details",
  ENTER_YOUR_ACCOUNT_NUMBER: "Enter your account number",
  ENTER_YOUR_ACCOUNT_HOLDER: "Enter your account holder",
  BANK_ACCOUNT_DETAILS: "Bank account details",
  ADD_BANK_ACCOUNT: "Add bank account",
  SAVE_BANK_ACCOUNT:
    "Save this bank account to make your next transaction easier and agree with our Terms & Conditions.",
  UPDATE: "UPDATE",
  SAVE: "SAVE",
  REMOVE_ACCOUNT: "Remove account",
};
