import React from "react";
import { useTranslation } from "react-i18next";

function OrderInstruction() {
  const { t } = useTranslation();
  return (
    <div className="order-instruction">
      <p className="details-1">{t("WHATS_NEXT")}?</p>
      <p className="details-2">{t("ORDER_PROCESSED")}</p>
      <div className="mt-3">{t("ORDER_INSTRUCTION")()}</div>
    </div>
  );
}

export default OrderInstruction;
