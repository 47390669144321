import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MainLayout from "../../../layouts/MainLayout";
import { downloadWhite } from "../../../images";
// import PaymentStatusContainer from "./components/paymentStatusContainer";
import "./style.css";
import PaymentReceiptContainer from "./components/paymentReceiptContainer";
import UserDetailsContainer from "./components/userDetailsContainer";
import OrderInstruction from "./components/orderInstruction";
// import Axios from "../../../configs/serviceProvider/Axios";
// import API from "../../../configs/API";
import ReceiptDoc from "./components/receiptPDF";

function Receipt() {
  const location = useLocation();

  // const { id } = useParams();
  const [status, setStatus] = useState(null);
  // const navigate = useNavigate();
  // const navigateBack = () => navigate(-1);
  // const navigateBack = () => navigate("/", { state: { from: "receipt" } });
  // const navigateBackToCheckout = () => navigate("/transaction-summary", { state: { from: "receipt" } });
  const { t } = useTranslation();
  const receiptDetails = location.state;

  useEffect(() => {
    setStatus("COIN RECEIVED");
  }, []);

  // console.log(receiptDetails);
  // console.log(receiptDetails.receipt?.receipt_no);

  const renderDownloadPDFLink = () => {
    return (
      <PDFDownloadLink
        document={<ReceiptDoc receipt={receiptDetails} />}
        fileName={`${receiptDetails.receipt?.receipt_no || "KOCEK-coin-received"}.pdf`}
      >
        <img src={downloadWhite} alt="download-icon" />
      </PDFDownloadLink>
    );
  };

  // const renderPaymentStatusContainer = () => {
  //   // console.log(status);
  //   return (
  //     <PaymentStatusContainer
  //       icon={
  //         status === "COIN RECEIVED" ? <img src={pending} alt="pending-icon" /> : <img src={error} alt="error-icon" />
  //       }
  //       title={status === "COIN RECEIVED" ? null : t("TRANSACTION_FAILED")}
  //       description={
  //         status === "COIN RECEIVED" ? t("TRANSACTION_PENDING_DESCRIPTION") : t("TRANSACTION_FAILED_DESCRIPTION")
  //       }
  //       buttonText={status === "COIN RECEIVED" ? t("PENDING_BUTTON") : t("RETRY")}
  //       buttonOnClick={status === "COIN RECEIVED" ? navigateBack : navigateBackToCheckout}
  //     />
  //   );
  // };

  const renderPaymentReceiptContainer = () => {
    return <PaymentReceiptContainer receipt={receiptDetails} />;
  };

  const renderUserDetailsContainer = () => {
    return <UserDetailsContainer client={receiptDetails} />;
  };

  const renderOrderInstruction = () => {
    return <OrderInstruction />;
  };

  return (
    <MainLayout
      backtoMain
      navbarRightButton={renderDownloadPDFLink()}
      backgroundColor="#282828"
      navbarBackgroundColor="#282828"
      backButtonColor="#FFFFFF"
      containerStyle={{ marginBottom: 0 }}
    >
      <div className="container h-100 mb-0">
        <div className="row h-100 justify-content-center">
          <div className="col-12 col-lg-6 d-flex flex-column">
            <div className="receipt-container">
              {status === "COIN RECEIVED" ? renderPaymentReceiptContainer() : null}
              {status === "COIN RECEIVED" ? renderUserDetailsContainer() : null}
              {renderOrderInstruction()}
              <PDFDownloadLink
                document={<ReceiptDoc receipt={receiptDetails} />}
                fileName={`${receiptDetails.receipt?.receipt_no}.pdf`}
                style={{ textDecoration: "none" }}
              >
                <div className="download-receipt-button">{t("DOWNLOAD_PDF_RECEIPT")}</div>
              </PDFDownloadLink>
            </div>
            <div>{t("COPYRIGHT")()}</div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Receipt;
