import React, { useState, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CoinContext } from "../../../configs/context/CoinContextProvider";
import BottomSheetModal from "../../../components/BottomSheet";
import API from "../../../configs/API";
import Axios from "../../../configs/serviceProvider/Axios";
import MainLayout from "../../../layouts/MainLayout";
import "./style.css";
import TermsAndCondition from "../../../components/TermsAndCondition";

function Signup() {
  const navigate = useNavigate();
  const { setUserToken } = useContext(CoinContext);
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    phonePrefix: "+60",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "phone") {
      const hasPrefix = value.startsWith("+60");

      // Remove hyphens from the phone value
      const phoneValueWithoutHyphens = value.replace(/-/g, "");

      // If the phone value contains the "+60" prefix, keep it; otherwise, add the "+60" prefix
      const phoneValueWithFixedPrefix = hasPrefix ? phoneValueWithoutHyphens : `+60${phoneValueWithoutHyphens}`;

      setInputValue({ ...inputValue, [name]: phoneValueWithFixedPrefix });

      // Update formData with the phone field (also without hyphens and with the correct prefix)
      setFormData({ ...formData, [name]: phoneValueWithFixedPrefix.slice(3) });
    } else {
      setInputValue({ ...inputValue, [name]: event.target.value });

      // Update formData with the name or email field
      setFormData((prevData) => ({
        ...prevData,
        [name]: event.target.value,
      }));
    }

    // Check if the button should be enabled based on the input fields' values
    setIsButtonEnabled(formData.name.trim() !== "" && formData.email.trim() !== "" && formData.phone.trim() !== "");
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();

    try {
      const url = `${API.REGISTER_USER}`;
      const response = await Axios.put(url, formData);

      if (response.data) {
        setUserToken(response.data.token);
        navigate("/otp");
      } else {
        // setError('Invalid username or password');
      }
    } catch (error) {
      const err = error.response.data;
      toast.error(err.error);
    }
  };

  const openModal = () => setOpenBottomSheet(true);
  const closeModal = () => setOpenBottomSheet(false);

  return (
    <>
      <BottomSheetModal className="stock-bottom-sheet" openModal={openBottomSheet} onDismiss={closeModal}>
        <TermsAndCondition onClick={closeModal} />
      </BottomSheetModal>

      <MainLayout hasFooter={false} backButton title={t("SIGN_UP")}>
        <div className="main container">
          <div className="title2 mt-4 text-muted text-center">{t("SIGN_UP_TITLE")}</div>
          <form className="mb-4">
            <div className="form-outline mt-4 mb-4">
              <div className="mb-3">
                <label className="signupTitle form-label text-start">{t("NAME")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={inputValue.name}
                  onChange={handleInputChange}
                  placeholder="Enter full name"
                />
              </div>
              <div className="mb-3">
                <label className="signupTitle form-label text-start">{t("EMAIL")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={inputValue.email}
                  onChange={handleInputChange}
                  placeholder="example@email.com"
                />
                <span className="signupTitle1">{t("EMAIL_VALIDATION")}</span>
              </div>
              <div className="mb-3">
                <label className="signupTitle form-label text-start">{t("PHONE_NUMBER")}</label>
                <input
                  inputMode="numeric"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={inputValue.phone}
                  onChange={handleInputChange}
                  placeholder="+60"
                />
                <span className="signupTitle1">{t("PHONE_NUMBER_VALIDATION")}</span>
              </div>
              <div className="mt-4">
                <span className="titletnc">{t("SIGN_UP_TERM_AND_CONDITION")}</span>
                <span className="titletncColor" onClick={openModal}>
                  {" "}
                  {t("SIGN_UP_TERM_AND_CONDITION_1")}
                </span>
                <span className="titletnc"> {t("SIGN_UP_TERM_AND_CONDITION_2")}</span>
              </div>
            </div>
          </form>

          <button
            className={`cstmbtn mt-4 mb-4 ${isButtonEnabled ? "enabled" : "disabled"}`}
            onClick={handleButtonClick}
            disabled={!isButtonEnabled}
            type="submit"
          >
            {t("CONTINUE")}
          </button>
          <div className="text-center">
            <span className="title2 text-muted">{t("ALREADY_HAVE_AN_ACCOUNT")} </span>
            <span>
              <a className="guestText cursor-pointer" href="/login">
                {t("CONTINUE_WITH_EMAIL")}
              </a>
            </span>
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default Signup;
