import React, { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../components/HorizontalSeparator";
import { CoinContext } from "../../../configs/context/CoinContextProvider";

const PaymentMethodContainer = forwardRef((props, ref) => {
  const { onClick, emphasisPaymentMethod } = props;
  const { selectedPaymentMethod, selectedOnlineBankingMethod } = useContext(CoinContext);
  const { t } = useTranslation();
  return (
    <div ref={ref} className={`checkout-container ${emphasisPaymentMethod ? "emphasis" : null} mb-2`}>
      <p className="title">{t("PAYMENT_METHOD")}</p>
      <HorizontalSeparator borderWidth={1} borderColor="#f0f0f0" borderStyle="solid" />
      <div className="d-flex justify-content-between align-items-center details-container">
        <p
          onClick={onClick}
          onKeyDown={onClick}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={0}
          className={`${selectedPaymentMethod ? "selected-payment-title" : "payment-title"} mb-0`}
        >
          {selectedPaymentMethod
            ? selectedPaymentMethod.key === "Online Banking"
              ? `${selectedPaymentMethod.name} - ${selectedOnlineBankingMethod.bank_short}`
              : selectedPaymentMethod.name
            : t("SELECT_PAYMENT_METHOD")}
        </p>
        <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
          <i className="fa-solid fa-chevron-right fa-xs" style={{ color: "#282828" }} />
        </div>
      </div>
    </div>
  );
});

export default PaymentMethodContainer;
