import React from "react";

function BottomSheetHeader({ closeModal, title, rightCloseButton, containerStyle }) {
  return (
    <div style={containerStyle} className="d-flex align-items-center mt-2 position-relative">
      <i
        role="button"
        tabIndex={0}
        onClick={closeModal}
        onKeyDown={closeModal}
        className={`fa-solid fa-xmark ${rightCloseButton ? "right-close-sheet" : "left-close-sheet"}`}
        style={{ color: "#606060" }}
        aria-label="Close Modal"
      />
      <div className="mx-auto justify-content-center">
        <span className="text-center">{title}</span>
      </div>
    </div>
  );
}

export default BottomSheetHeader;
