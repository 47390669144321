import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/MainLayout";
import TncContentContainer from "./TnCContentContainer";

function TermsAndCondition() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateBackToHome = () => {
    navigate("/");
  };
  return (
    <MainLayout
      title={t("TERMS_AND_CONDITIONS")}
      hamburgerButton
      hasFooter
      footerText={t("BACK_TO_HOME_PAGE")}
      topButtonStyle={{
        color: "#3FBAFF",
        backgroundColor: "transparent",
      }}
      leftTopButtonIcon={<i className="fa-solid fa-arrow-left-long fa-sm me-3" />}
      footerButtonOnClick={navigateBackToHome}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <TncContentContainer />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default TermsAndCondition;
