import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import Radio from "../../components/Radio/Radio";
import "./style.css";
import HorizontalSeparator from "../../components/HorizontalSeparator";
import { CoinContext } from "../../configs/context/CoinContextProvider";
import Axios from "../../configs/serviceProvider/Axios";
import API from "../../configs/API";
import BottomSheetModal from "../../components/BottomSheet";
import SearchBar from "../../components/SearchBar";
import DropDownSelection from "../../components/DropDownSelection/DropDownSelection";
import { fpx } from "../../images/index";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorMessage from "../../components/ErrorMessage";
// import { fpx, visa, amex, mastercard,cimb, jompay } from "../../images/index";

function PaymentMethod() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    selectedOnlineBankingMethod,
    setSelectedOnlineBankingMethod,
  } = useContext(CoinContext);
  const [selectedMethod, setSelectedMethod] = useState(selectedPaymentMethod || null);
  const [selectedOnlineBanking, setSelectedOnlineBanking] = useState(selectedOnlineBankingMethod || null);
  const [onlineBankingMethod, setOnlineBankingMethod] = useState([]);
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [searchedData, setSearchedData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const paymentMethod = [
    {
      name: t("ONLINE_BANKING"),
      key: "Online Banking",
      method: "fpx",
      images: [fpx],
    },
    // {
    //   name: t("CREDIT_CARD"),
    //   key: "Credit/ Debit Card",
    //   method: "card",
    //   images: [visa, mastercard, amex],
    // },
  ];

  const ktm = [
    // {
    //   name: t("CIMB"),
    //   ps: t("CIMB_PS"),
    //   key: "Kocek Teller Machine - CIMB CDM Bank",
    //   image: cimb,
    // },
    // {
    //   title: t("JOMPAY_TITLE"),
    //   ps: t("JOMPAY_PS"),
    //   name: t("JOMPAY"),
    //   method: "jompay",
    //   key: "Kocek Teller Machine - ATM JomPAY",
    //   image: jompay,
    // },
  ];

  useEffect(() => {
    loadBankData();
  }, []);

  useEffect(() => {
    if (selectedOnlineBanking) {
      closeModal();
    }
  }, [selectedOnlineBanking]);

  useEffect(() => {
    if (searchedData) {
      const filteredArr = onlineBankingMethod.filter((item) =>
        item?.bank_short.toLowerCase().includes(searchedData.toLowerCase())
      );
      setFilteredData(filteredArr);
    } else {
      setFilteredData(onlineBankingMethod);
    }
  }, [searchedData]);

  const loadBankData = async () => {
    setIsLoading(true);
    await Axios.get(API.GET_BANK_LIST, {
      headers: { Authorization: null },
    }).then((result) => {
      if (result.status === 200 && result.data) {
        setOnlineBankingMethod(result.data);
        setFilteredData(result.data);
      }
    });
    setIsLoading(false);
  };

  const onSelectPaymentMethod = (item) => {
    if (item.key === "Online Banking" && !selectedOnlineBanking) {
      setSelectedOnlineBanking(onlineBankingMethod[0]);
    }
    setSelectedMethod(item);
  };

  const onSaveChanges = () => {
    setSelectedPaymentMethod(selectedMethod);
    setSelectedOnlineBankingMethod(selectedMethod.key === "Online Banking" ? selectedOnlineBanking : null);
    navigate(-1);
  };

  const closeModal = () => setOpenBottomSheet(false);
  const openModal = () => setOpenBottomSheet(true);

  const onChangeText = (value) => {
    setSearchedData(value);
  };

  const radioOnClick = (item) => {
    setSelectedOnlineBanking(item);
  };

  return (
    <>
      {openBottomSheet ? (
        <BottomSheetModal
          openModal={openBottomSheet}
          onDismiss={closeModal}
          maxHeightRatio={0.6}
          minHeightRatio={0.6}
          header={<SearchBar onChangeText={onChangeText} searchedData={searchedData} placeholder="Search bank list" />}
          className="industry-bottom-sheet"
        >
          <div className="dropdown-selection">
            {filteredData && filteredData.length > 0 ? (
              <div>
                {filteredData.map((item) => {
                  return (
                    <DropDownSelection
                      data={item}
                      name={item?.bank_short}
                      radioOnClick={radioOnClick}
                      checked={selectedOnlineBanking?.bank_short === item?.bank_short}
                      disabled={item?.status !== "A"}
                    />
                  );
                })}
              </div>
            ) : isLoading ? (
              <div className="text-center">
                <LoadingSpinner color="#C4C4C4" borderWidth={2} />
              </div>
            ) : (
              <ErrorMessage
                messageTitle={t("SOMETHING_WENT_WRONG")}
                message={t("FETCH_FAIL")}
                titleStyle={{
                  color: "#303030",
                  fontSize: 20,
                  fontWeight: "500",
                }}
                messageStyle={{
                  color: "#606060",
                  fontSize: 14,
                }}
                buttonOnClick={loadBankData}
                footerText={t("RETRY")}
              />
            )}
          </div>
        </BottomSheetModal>
      ) : null}
      <MainLayout
        hasFooter
        title={t("PAYMENT_METHOD_TITLE")}
        backButton
        backButtonOnClick={() => navigate(-1)}
        footerText={t("CONFIRM")}
        footerButtonOnClick={onSaveChanges}
        hideHeaderOverlay={openBottomSheet}
        hideFooterOverlay={openBottomSheet}
        openBottomSheet={openBottomSheet}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <p className="ktm">{t("ONLINE_PAYMENT")}</p>
              {paymentMethod.map((item, index) => {
                return (
                  <>
                    <div key={index} className="d-flex payment-method-container">
                      <Radio
                        onClick={() => onSelectPaymentMethod(item)}
                        checked={selectedMethod ? selectedMethod.key === item.key : false}
                        borderColor="#282828"
                        backgroundColor="#282828"
                      />
                      <div className="ms-2 w-100">
                        <p className="mb-1 payment-method">{item.name}</p>
                        <div className="d-flex">
                          {item.images.map((img) => {
                            return <img src={img} alt="online-banking" className="me-2" />;
                          })}
                        </div>
                        {selectedMethod?.key === "Online Banking" && item.key === "Online Banking" ? (
                          <div
                            role="button"
                            tabIndex={0}
                            onClick={openModal}
                            onKeyDown={openModal}
                            className="d-flex align-items-center justify-content-between form-control py-2 mt-2"
                          >
                            <input
                              type="text"
                              className="border-0 bg-white w-100 industry-dropdown"
                              disabled
                              value={selectedOnlineBanking?.bank_short}
                            />
                            <i className="fa-solid fa-chevron-down" style={{ color: "#909090" }} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <HorizontalSeparator borderWidth={1} borderColor="#f5f5f5" borderStyle="solid" />
                  </>
                );
              })}
              {ktm.length > 0 && (
                <>
                  <p className="ktm">{t("MANUAL_PAYMENT")}</p>
                  <p className="payment-instruction">{t("PAYMENT_INSTRUCTION")}</p>
                  {ktm.map((item, index) => (
                    <div key={index} className="d-flex payment-method-container">
                      <Radio
                        onClick={() => onSelectPaymentMethod(item)}
                        checked={selectedMethod ? selectedMethod.key === item.key : false}
                        borderColor="#282828"
                        backgroundColor="#282828"
                      />
                      <div className="ms-2 ">
                        <p className="mb-1 payment-method">{item.title}</p>
                        <p className="ktm-ps">{item.ps}</p>
                        <img src={item.image} alt="online-banking" className="me-2" />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}

export default PaymentMethod;
