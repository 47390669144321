import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainLayout from "../../../layouts/MainLayout";
import { verified } from "../../../images";
import "./style.css";

function AccountVerifiedScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleConvertCoins = () => {
    navigate("/kocek-in/coin-sorting");
  };

  const handleBackHome = () => {
    navigate("/");
  };

  return (
    <MainLayout hasFooter={false} backButton>
      <div className="main container">
        <div className="iconContainer mt-4 mb-4">
          <img src={verified} style={{ minWidth: 94 }} className="App-logo" alt="logo" width="30%" height="auto" />
        </div>
        <div className="text-center">
          <h5>{t("kocekIn.ACCOUNT_VERIFIED")}</h5>
          <div className="title2 mt-3 text-muted">{t("kocekIn.ACCOUNT_VERIFIED_TITLE")}</div>
        </div>
        <button className="cstmbtn mb-4 enabled mt-4" type="button" onClick={handleConvertCoins}>
          {t("kocekIn.CONVERT_COINS")}
        </button>
        <div className="backHomePage text-center" onClick={handleBackHome}>
          {t("kocekIn.BACK_TO_HOME_PAGE")}
        </div>
      </div>
    </MainLayout>
  );
}

export default AccountVerifiedScreen;
